import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";

export const usrAiSubs = async (crsId) => {
  const { id } = User();
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.UAISUBS,
        { usrId: id, crsId },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => res.data.subs)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const aiUsrAttempt = async (aiData, navigate) => {
  const { token } = User();
  const { course_id } = aiData;
  if (token) {
    return await axios
      .post(Server.UAIATMPT, aiData, {
        headers: { authorization: "Bearer " + token },
      })
      .then((val) => {
        if (aiData.attempt === "attempt") {
          return navigate("/ai/plr/" + course_id, { replace: true });
        } else {
          return false;
        }
      })
      .catch((err) => console.log(err));
  }
};

export const prelimQues = async (crsId, navigate) => {
  const { id, token } = User();
  if (token) {
    return await axios
      .post(
        Server.UPRELQUES,
        { usrId: id, crsId },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => {
        if (res.data.status === 2) {
          return navigate("/ai/plr/" + crsId, { replace: true });
        } else {
          return res.data.ques;
        }
      })
      .catch((err) => console.log(err));
  } else {
    return false;
  }
};

export const prelimQSave = async (qData) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(Server.UPRELQSAVE, qData, {
        headers: { authorization: "Bearer " + token },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const prelimReport = async (crsId) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.UPREREPORT,
        { usrId: id, crsId },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const todoSkip = async (todoId) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.TODOSKIP,
        { usrId: id, todoId },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        let val = localStorage.getItem("todoList");
        if (val) {
          val = JSON.parse(val);
          val = val.filter((i) => i.id !== todoId);
          localStorage.setItem("todoList", JSON.stringify(val));
          return res.data;
        }
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const upReadProg = async (tpcId, prog) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.UPREADPROG,
        { usrId: id, tpcId, prog },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const detailedReport = async (monFil) => {
  const { token, id, last_selected_course } = User();
  if (token) {
    return await axios
      .post(
        Server.DETEVALREP,
        { usrId: id, crsId: last_selected_course, monFil },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const givenTodo = async (tdIdArr) => {
  const { token } = User();
  if (tdIdArr.length > 1 && token) {
    return await axios
      .post(
        Server.TODOGIVEN,
        { tdIdArr },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};
