import React, { useLayoutEffect, useRef } from "react";
import "../Style/Charts.scss";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { calAccuracy, dateFormat, useForceUpdate } from "../Functions/utils";
import { useEffect } from "react";
import { User, usrSubs } from "../Functions/LocalSaveData";
import { useState } from "react";
import { crsMock, mockRep } from "../Functions/LocalSaveData";
import color from "../Style/colorPalette";

am4core.useTheme(am4themes_animated);
am4core.addLicense("ch-custom-attribution");

export const ProgresBar = ({ progress }) => {
  return (
    <div className="progCon">
      <div className="progBar" style={{ width: `${progress}%` }}>
        <span className="progTxt">{`${progress}%`}</span>
      </div>
    </div>
  );
};

export const VidProgBar = ({ progress }) => {
  return (
    <div className="progCon">
      <div className="progBar" style={{ width: `${progress}%` }}>
        <span className="progTxt ">{`${progress}%`}</span>
      </div>
    </div>
  );
};

export const SubProgChart = () => {
  const force = useForceUpdate();
  const [subsD, setSubsD] = useState([]);
  const r = useRef(true);
  useEffect(() => {
    const int = setInterval(() => {
      let subs = usrSubs();
      if (JSON.stringify(subsD) !== JSON.stringify(subs)) {
        r.current = true;
        setSubsD(subs, force());
      }
    }, 1000);
    return () => clearInterval(int);
  }, [force, subsD]);

  useLayoutEffect(() => {
    let subs = usrSubs();
    if (r.current) {
      let chart = am4core.create("chartdiv", am4charts.XYChart3D);
      // Add data
      subs.map(({ subject_name, subject_prepration }) =>
        chart.data.push({
          subject_name,
          subject_prepration:
            subject_prepration === null ? 0 : subject_prepration,
          color: chart.colors.next(),
        })
      );

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "subject_name";
      categoryAxis.numberFormatter.numberFormat = "#";
      categoryAxis.renderer.cellStartLocation = 0;
      categoryAxis.renderer.cellEndLocation = 0.9;
      categoryAxis.renderer.labels.template.rotation = 90;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Progress (%)";
      valueAxis.title.fontWeight = "bold";

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries3D());
      series.dataFields.valueY = "subject_prepration";
      series.dataFields.categoryX = "subject_name";
      series.name = "Exam Progress";
      series.columns.template.tooltipText = "{valueY}";
      series.columns.template.propertyFields.fill = "color";
      series.columns.template.column3D.stroke = am4core.color("#fff");
      series.columns.template.column3D.strokeOpacity = 0.2;
      chart.cursor = new am4charts.XYCursor();
      r.current = false;

      // return () => {
      //   chart.dispose();
      // };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="progChart" id="chartdiv" />;
};

export const DpacrWrSkPie = () => {
  const { mockResult } = mockRep();
  const { correct, wrong, unattempted } =
    mockResult === undefined ? [] : mockResult;
  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv1", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.legend = new am4charts.Legend();
    chart.data = [
      {
        a: "Correct",
        b: parseFloat(correct),
      },
      {
        a: "Incorrect",
        b: parseFloat(wrong),
      },
      {
        a: "Skipped",
        b: parseFloat(unattempted),
      },
    ];
    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "b";
    series.dataFields.category = "a";
    series.slices.template.cornerRadius = 5;
    series.colors.step = 10;
    series.ticks.template.disabled = true;
    series.alignLabels = false;
    series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    series.labels.template.radius = am4core.percent(-40);
    series.labels.template.fill = am4core.color("white");
  }, [correct, unattempted, wrong]);

  return <div className="pieChart" id="chartdiv1" />;
};

export const DpacrWrSkPie2 = () => {
  const { total_question, positive_mark } = crsMock();
  const { mockResult } = mockRep();
  let { score } = mockResult === undefined ? [] : mockResult;

  score = score ? (score < 0 ? 0 : score) : 0;

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv2", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.legend = new am4charts.Legend();
    chart.data = [
      {
        a: "Score Achieved",
        b: parseFloat(score),
      },
      {
        a: "Remaining Score",
        b:
          total_question *
          (positive_mark === "" || positive_mark === null
            ? 1
            : parseFloat(positive_mark)),
      },
    ];
    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "b";
    series.dataFields.category = "a";
    series.slices.template.cornerRadius = 5;
    series.colors.step = 15;
    series.ticks.template.disabled = true;
    series.alignLabels = false;
    series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    series.labels.template.radius = am4core.percent(-40);
    series.labels.template.fill = am4core.color("white");
  }, [positive_mark, score, total_question]);
  return <div className="pieChart" id="chartdiv2" />;
};

export const DpacrWrSkPie3 = () => {
  const { time_duration } = crsMock();
  const { mockResult } = mockRep();
  const { time_taken } = mockResult === undefined ? [] : mockResult;

  let totalSec =
    parseFloat(String(time_duration).split(":")[0]) * 3600 +
    parseFloat(String(time_duration).split(":")[1]) * 60 +
    parseFloat(String(time_duration).split(":")[2]);

  let takenSec =
    parseFloat(String(time_taken).split(":")[0]) * 3600 +
    parseFloat(String(time_taken).split(":")[1]) * 60 +
    parseFloat(String(time_taken).split(":")[2]);

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv3", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.legend = new am4charts.Legend();
    chart.data = [
      {
        a: "TIme Consumed",
        b: parseFloat(takenSec),
      },
      {
        a: "Remaining Time",
        b: parseFloat(totalSec),
      },
    ];
    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "b";
    series.dataFields.category = "a";
    series.slices.template.cornerRadius = 5;
    series.colors.step = 40;

    series.ticks.template.disabled = true;
    series.alignLabels = false;
    series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    series.labels.template.radius = am4core.percent(-40);
    series.labels.template.fill = am4core.color("white");
  }, [takenSec, totalSec]);
  return <div className="pieChart" id="chartdiv3" />;
};

export const OaProgChart = () => {
  const { overalMock } = mockRep();

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart3D);
    // Add data
    overalMock.map(({ score, accuracy }, index) =>
      chart.data.push({
        test: "Mock-Test ( " + (overalMock.length - index) + " )",
        score: score < 0 ? 0 : score,
        accuracy,
        color: chart.colors.next(),
      })
    );

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "test";
    categoryAxis.numberFormatter.numberFormat = "#";
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.labels.template.rotation = 90;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Progress (%)";
    valueAxis.title.fontWeight = "bold";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "score";
    series.dataFields.categoryX = "test";
    series.name = "Score";
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.column3D.stroke = am4core.color("#fff");

    let series2 = chart.series.push(new am4charts.ColumnSeries3D());
    series2.dataFields.valueY = "accuracy";
    series2.dataFields.categoryX = "test";
    series2.name = "Accuracy";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.column3D.stroke = am4core.color("#fff");
    chart.cursor = new am4charts.XYCursor();
    // return () => {
    //   chart.dispose();
    // };
  }, [overalMock]);

  return <div className="OaProgChart" id="chartdiv" />;
};

export const SaProgChart = () => {
  const { mockSections } = crsMock();
  let { mockQues } = mockRep();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let secData = [];

  for (let i = 0; i < mockSections.length; i++) {
    const { section_name, section_id } = mockSections[i];
    let d = {},
      secQues = [];
    d.section_name = section_name;
    d.correct = 0;
    d.wrong = 0;
    d.unattempted = 0;

    secQues = mockQues.filter(
      (item) => String(item.section_id) === String(section_id)
    );
    for (let i = 0; i < secQues.length; i++) {
      const { answer, user_answer } = secQues[i];
      if (user_answer === null || user_answer === "") {
        d.unattempted = d.unattempted + 1;
      } else if (answer === user_answer) {
        d.correct = d.correct + 1;
      } else if (user_answer.length > 0 && user_answer !== answer) {
        d.wrong = d.wrong + 1;
      }
    }
    d.accuracy = d.correct / (d.correct + d.wrong);
    d.accuracy = (d.accuracy * 100).toFixed(2);
    secData.push(d);
  }

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart3D);
    // Add data
    secData.map(({ section_name, correct, wrong }, index) =>
      chart.data.push({
        section_name,
        attempt: correct + wrong,
        accuracy: calAccuracy(correct, correct + wrong),
        color: chart.colors.next(),
      })
    );

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "section_name";
    categoryAxis.numberFormatter.numberFormat = "#";
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.labels.template.rotation = 90;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Progress (%)";
    valueAxis.title.fontWeight = "bold";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "attempt";
    series.dataFields.categoryX = "section_name";
    series.name = "Attempt";
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.column3D.stroke = am4core.color("#fff");

    let series2 = chart.series.push(new am4charts.ColumnSeries3D());
    series2.dataFields.valueY = "accuracy";
    series2.dataFields.categoryX = "section_name";
    series2.name = "Accuracy";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.column3D.stroke = am4core.color("#fff");
    chart.cursor = new am4charts.XYCursor();
    return () => {
      chart.dispose();
    };
  }, [secData]);

  return <div className="OaProgChart" id="chartdiv" />;
};

export const TaProgChart = ({ topics }) => {
  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart3D);
    // Add data
    topics.map(({ topic_name, attempt, correct_ans }, index) =>
      chart.data.push({
        topic_name,
        attempt,
        accuracy: calAccuracy(correct_ans, attempt),
        color: chart.colors.next(),
      })
    );

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "topic_name";
    categoryAxis.numberFormatter.numberFormat = "#";
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.labels.template.rotation = 90;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Progress (%)";
    valueAxis.title.fontWeight = "bold";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "attempt";
    series.dataFields.categoryX = "topic_name";
    series.name = "Attempt";
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.column3D.stroke = am4core.color("#fff");

    let series2 = chart.series.push(new am4charts.ColumnSeries3D());
    series2.dataFields.valueY = "accuracy";
    series2.dataFields.categoryX = "topic_name";
    series2.name = "Accuracy";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.column3D.stroke = am4core.color("#fff");
    chart.cursor = new am4charts.XYCursor();
    return () => {
      chart.dispose();
    };
  }, [topics]);

  return <div className="OaProgChart" id="chartdiv" />;
};

export const EaGuageChart = () => {
  useLayoutEffect(() => {
    let { total_question, positive_mark } = crsMock();
    const { mockResult } = mockRep();
    const { score } = mockResult === undefined ? [] : mockResult;
    positive_mark =
      positive_mark === null || positive_mark === "" ? 1 : positive_mark;

    let maxScore = total_question * positive_mark;

    let chart = am4core.create("chartdiv", am4charts.GaugeChart);

    // Create axis
    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = maxScore;
    axis.strictMinMax = true;

    // Set inner radius
    chart.innerRadius = -60;

    // Add ranges
    let range = axis.axisRanges.create();
    range.value = 0;
    range.endValue = maxScore / 3;
    range.axisFill.fillOpacity = 1;
    range.axisFill.fill = am4core.color(color.lightGreen);
    range.axisFill.zIndex = -1;

    let range2 = axis.axisRanges.create();
    range2.value = maxScore / 3;
    range2.endValue = maxScore / 1.5;
    range2.axisFill.fillOpacity = 1;
    range2.axisFill.fill = am4core.color(color.green);
    range2.axisFill.zIndex = -1;

    let range3 = axis.axisRanges.create();
    range3.value = maxScore / 1.5;
    range3.endValue = maxScore;
    range3.axisFill.fillOpacity = 1;
    range3.axisFill.fill = am4core.color(color.darkGreen);
    range3.axisFill.zIndex = -1;

    // Add hand
    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.value = parseFloat(score) < 0 ? 0 : parseFloat(score);
    // hand.pin.disabled = true;
    hand.fill = am4core.color(color.pink);
    hand.stroke = am4core.color(color.pink);
    hand.innerRadius = am4core.percent(10);
    hand.radius = am4core.percent(90);
    hand.startWidth = 30;

    return () => {
      chart.dispose();
    };
  }, []);

  return <div className="EaGuageChart" id="chartdiv" />;
};

export const AnalysisChart = ({ data }) => {
  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;
    // Add data
    let d = data.reverse();
    d.map(({ total_score, created_at }, index) =>
      chart.data.push({
        score: total_score,
        session: "Test Session " + (index + 1) + "\n" + dateFormat(created_at),
      })
    );

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "session";
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.startLocation = 0.5;
    categoryAxis.endLocation = 0.5;
    categoryAxis.renderer.labels.template.rotation = 90;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.baseValue = 0;

    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "score";
    series.dataFields.categoryX = "session";
    series.strokeWidth = 2;
    series.tensionX = 0.77;

    // bullet is added because we add tooltip to a bullet for it to change color
    let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = "{valueY}";

    bullet.adapter.add("fill", function (fill, target) {
      if (target.dataItem.valueY < 0) {
        return am4core.color(color.pink);
      } else {
        am4core.color(color.green);
      }
      return fill;
    });
    let range = valueAxis.createSeriesRange(series);
    range.value = 0;
    range.endValue = -1000;
    range.contents.stroke = am4core.color(color.pink);
    range.contents.fill = range.contents.stroke;

    // Add scrollbar
    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    chart.cursor = new am4charts.XYCursor();
    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div className="OaProgChart" id="chartdiv" />;
};

export const MockAnalysisChart = () => {
  useLayoutEffect(() => {
    const { prevMock } = crsMock();
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;
    // Add data
    prevMock.map(({ created_at, test_name, score }) =>
      chart.data.push({
        score: score,
        session:
          (String(test_name).length > 15
            ? String(test_name).trim().slice(0, 12)
            : String(test_name).trim()) +
          (String(test_name).length > 15 ? "..." : "") +
          "\nScore: " +
          score +
          "\nDate: " +
          dateFormat(created_at),
      })
    );

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "session";
    categoryAxis.renderer.minGridDistance = 55;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.startLocation = 0.5;
    categoryAxis.endLocation = 0.5;
    categoryAxis.renderer.labels.template.rotation = 90;
    categoryAxis.renderer.fontSize = 12;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Score";
    valueAxis.baseValue = 0;

    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "score";
    series.dataFields.categoryX = "session";
    series.strokeWidth = 2;
    series.tensionX = 0.75;

    // bullet is added because we add tooltip to a bullet for it to change color
    let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = "{valueY}";

    bullet.adapter.add("fill", function (fill, target) {
      if (target.dataItem.valueY < 0) {
        return am4core.color(color.pink);
      } else {
        am4core.color(color.green);
      }
      return fill;
    });
    let range = valueAxis.createSeriesRange(series);
    range.value = 0;
    range.endValue = -1000;
    range.contents.stroke = am4core.color(color.pink);
    range.contents.fill = range.contents.stroke;

    // Add scrollbar
    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    chart.cursor = new am4charts.XYCursor();
    return () => {
      chart.dispose();
    };
  }, []);

  return <div className="mockProgChart" id="chartdiv" />;
};

export const PracAnalysisChart = ({ data }) => {
  data = data === undefined ? [] : data;
  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart3D);
    // Add data
    data.map(({ subject_name, attempt, accuracy }, index) =>
      chart.data.push({
        subject_name: String(subject_name).replace(" ", "\n"),
        attempt,
        accuracy: accuracy,
        color: chart.colors.next(),
      })
    );

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "subject_name";
    categoryAxis.numberFormatter.numberFormat = "#";
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.labels.template.rotation = 90;
    categoryAxis.renderer.fontSize = 12;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Progress (%)";
    valueAxis.title.fontWeight = "bold";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "attempt";
    series.dataFields.categoryX = "subject_name";
    series.name = "Attempt";
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.column3D.stroke = am4core.color("#fff");

    let series2 = chart.series.push(new am4charts.ColumnSeries3D());
    series2.dataFields.valueY = "accuracy";
    series2.dataFields.categoryX = "subject_name";
    series2.name = "Accuracy";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.column3D.stroke = am4core.color("#fff");
    chart.cursor = new am4charts.XYCursor();
    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div className="OaProgChart" id="chartdiv" />;
};

export const TpcAnalysisChart = ({ data }) => {
  useLayoutEffect(() => {
    let chart = am4core.create("tpcAnalChart", am4charts.XYChart);
    chart.paddingRight = 20;
    // Add data
    data.map(({ topic_name, accuracy }) =>
      chart.data.push({
        accuracy,
        session: String(topic_name).replaceAll(" ", "\n"),
      })
    );

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "session";
    categoryAxis.renderer.minGridDistance = 55;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.startLocation = 0.5;
    categoryAxis.endLocation = 0.5;
    // categoryAxis.renderer.labels.template.rotation = 90;
    categoryAxis.renderer.fontSize = 0;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Accuracy (%)";
    valueAxis.baseValue = 0;

    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "accuracy";
    series.dataFields.categoryX = "session";
    series.strokeWidth = 2;
    series.tensionX = 0.75;

    // bullet is added because we add tooltip to a bullet for it to change color
    let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = "{valueY}";

    bullet.adapter.add("fill", function (fill, target) {
      if (target.dataItem.valueY < 0) {
        return am4core.color(color.pink);
      } else {
        am4core.color(color.green);
      }
      return fill;
    });
    let range = valueAxis.createSeriesRange(series);
    range.value = 0;
    range.endValue = -1000;
    range.contents.stroke = am4core.color(color.pink);
    range.contents.fill = range.contents.stroke;

    // Add scrollbar
    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    chart.cursor = new am4charts.XYCursor();
    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div className="mockProgChart" id="tpcAnalChart" />;
};

export const DEOvrProgChart = () => {
  useLayoutEffect(() => {
    const { course_prepration } = User();
    console.log(course_prepration);
    let chart = am4core.create("chartdiv2", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.legend = new am4charts.Legend();
    chart.data = [
      {
        a: "Completed",
        b: parseFloat(course_prepration),
        color: am4core.color(color.pink),
      },
      {
        a: "Remaining",
        b: 100 - parseFloat(course_prepration),
        color: am4core.color(color.white),
      },
    ];
    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "b";
    series.dataFields.category = "a";
    series.slices.template.cornerRadius = 5;
    series.slices.template.propertyFields.fill = "color";
    series.ticks.template.disabled = true;
    series.alignLabels = false;
    series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    series.labels.template.radius = am4core.percent(-40);
    series.labels.template.fill = am4core.color(color.white);
    series.labels.template.fontWeight = 600;
    series.labels.template.fontSize = 18;
  }, []);

  return <div className="deOvrProg" id="chartdiv2" />;
};

export const DeProgresBar = ({ progress, type }) => {
  return (
    <div
      className="progCon"
      style={{
        backgroundColor: type === "st" ? color.lightPink : color.lightGreen,
      }}
    >
      <div
        className="progBar"
        style={{
          width: `${progress}%`,
          backgroundColor: type === "st" ? color.pink : color.green,
        }}
      >
        <span className="progTxt">{`${progress}%`}</span>
      </div>
    </div>
  );
};

export const DeProgChart = ({ data }) => {
  useLayoutEffect(() => {
    let chart = am4core.create("deProgDiv", am4charts.XYChart3D);
    // Add data
    data.map(({ topic_name, topic_prepration }) =>
      chart.data.push({
        topic_name: String(topic_name).replaceAll(" ", "\n"),
        topic_prepration,
        color: chart.colors.next(),
      })
    );

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "topic_name";
    categoryAxis.numberFormatter.numberFormat = "#";
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 0.9;
    // categoryAxis.renderer.labels.template.rotation = 90;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Progress (%)";
    valueAxis.title.fontWeight = "bold";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "topic_prepration";
    series.dataFields.categoryX = "topic_name";
    series.name = "Exam Progress";
    series.columns.template.tooltipText = "{valueY}";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.column3D.stroke = am4core.color("#fff");
    series.columns.template.column3D.strokeOpacity = 0.2;

    chart.cursor = new am4charts.XYCursor();

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div className="deProgChart" id="deProgDiv" />;
};
