import React, { useState, useEffect } from "react";
import "../../../Style/mockTest.scss";
import { crsMock, mockRep } from "../../../Functions/LocalSaveData";
import { TopicAnList } from "../../../Components/List";
import { IOSSwitch } from "../../../Components/Switch";
import { TaProgChart } from "../../../Components/Charts";

export default function MockTa() {
  const [tplvl, setTpLvl] = useState(1);
  const [topData, setTopData] = useState([]);
  const [taSwitch, setTaSwitch] = useState(false);
  const { test_name } = crsMock();

  useEffect(() => {
    const { topicAnalysis } = mockRep();
    setTopData(
      topicAnalysis.filter((i) => parseFloat(i.difficulty_level) === tplvl)
    );
  }, [tplvl]);
  return (
    <div className="HomeD moTaMain">
      <p className="RpTxt">
        Topic Analysis <br />({test_name})
      </p>
      <div className="taDiv">
        <div className="tpLvlDiv">
          <p
            onClick={() => setTpLvl(1)}
            className={"tpLvl " + (tplvl === 1 ? "lvlAct" : "")}
          >
            Easy Level
          </p>
          <p
            onClick={() => setTpLvl(2)}
            className={"tpLvl " + (tplvl === 2 ? "lvlAct" : "")}
          >
            Medium Level
          </p>
          <p
            onClick={() => setTpLvl(3)}
            className={"tpLvl " + (tplvl === 3 ? "lvlAct" : "")}
          >
            Hard Level
          </p>
        </div>
        <div className="OaHddiv">
          <p className="OaHdTxt">Table </p>
          <IOSSwitch
            checked={taSwitch}
            onChange={() => setTaSwitch(!taSwitch)}
          />
          <p className="OaHdTxt"> Graph</p>
        </div>
        {taSwitch ? (
          <TaProgChart topics={topData} />
        ) : (
          <TopicAnList topics={topData} />
        )}
      </div>
    </div>
  );
}
