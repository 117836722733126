import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";

export const allPodcast = async () => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.PODCAST, { headers: { authorization: "Bearer " + token } })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const getPodcast = async (pId) =>
  await axios.post(Server.GETPODCAST, { pId }).then((res) => res.data);

export const allPib = async () => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.PIB, { headers: { authorization: "Bearer " + token } })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const allDna = async () => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.DNA, { headers: { authorization: "Bearer " + token } })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const allCa = async () => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.CA, { headers: { authorization: "Bearer " + token } })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const getDesc = async (id) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.GET_DESC,
        { id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => res.data.description)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const caQues = async () => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.CA_QUES, { headers: { authorization: "Bearer " + token } })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const getUsrBookmarks = async () => {
  const { id, token } = User();
  if (token) {
    return await axios
      .post(
        Server.USR_BM,
        { usrId: id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => localStorage.setItem("bmData", JSON.stringify(res.data)))
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const rmBookmark = async (bmId, type) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.REM_BM,
        { bmId, type },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => {
        await getUsrBookmarks();
        return res.data;
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const addBookmark = async (bmData) => {
  const { token, id } = User();
  bmData.usrId = id;
  if (token) {
    return await axios
      .post(Server.ADD_BM, bmData, {
        headers: { authorization: "Bearer " + token },
      })
      .then(async (res) => {
        await getUsrBookmarks();
        return res.data;
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const getNwsImg = async (caId) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.GETNAIMG,
        { caId },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => {
        if (String(res.data).includes("data")) {
          let th = await fetch(res.data);
          return URL.createObjectURL(await th.blob());
        } else {
          return res.data;
        }
      })
      .catch((err) => console.log(err));
  } else {
    return null;
  }
};

export const getInshorts = async (check) =>
  await axios
    .post(Server.GETINSHORTS, { lng: check === true ? "hi" : "en" })
    .then((res) => res.data)
    .catch((err) => console.log(err));
