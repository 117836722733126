import { useEffect, useState } from "react";
import { User, usrBookmarks, usrEbook } from "./LocalSaveData";

export const randomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz";
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const compareDt = (date) => {
  date =
    String(date).length === 0 || date === undefined
      ? null
      : date.split("-").reverse().join("-");
  date = new Date(date);
  let today = new Date();
  if (today >= date) {
    return 1;
  } else {
    return 0;
  }
};

export function useForceUpdate() {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(0); // integer state
  // eslint-disable-next-line no-shadow
  return () => setValue((value) => value + 1); // update state to force render
}

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export function dateFormat(dt) {
  dt = new Date(dt);
  let d = dt.getDate();
  let m = dt.getMonth() + 1;
  let y = dt.getFullYear();

  return `${String(d).length > 1 ? d : "0" + d}-${
    String(m).length > 1 ? m : "0" + m
  }-${y}`;
}

export function secToTime(secs) {
  let hours = Math.floor(secs / (60 * 60));
  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);
  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  hours = !isFinite(hours) || isNaN(hours) ? 0 : hours;
  minutes = !isFinite(minutes) || isNaN(minutes) ? 0 : minutes;
  seconds = !isFinite(seconds) || isNaN(seconds) ? 0 : seconds;

  let timer_string = "";

  if (hours < 10) {
    timer_string += "0" + hours;
  } else {
    timer_string += hours;
  }
  timer_string += ":";

  if (minutes < 10) {
    timer_string += "0" + minutes;
  } else {
    timer_string += minutes;
  }
  timer_string += ":";

  if (seconds < 10) {
    timer_string += "0" + seconds;
  } else {
    timer_string += seconds;
  }
  return timer_string;
}

export function timeToSec(time) {
  let sec = 0;
  sec =
    parseFloat(String(time).split(":")[0]) * 3600 +
    parseFloat(String(time).split(":")[1]) * 60 +
    parseFloat(String(time).split(":")[2]);
  return sec;
}

export function calAccuracy(attempted, total) {
  let acc = attempted / total;
  acc = isNaN(acc) ? 0 : (acc * 100).toFixed(0);
  return acc;
}

export const base64toBlob = (data) => {
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );
  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: "application/pdf" });
};

export const loadImage = (imageUrl) => {
  if (
    String(imageUrl).includes(".jpg") ||
    String(imageUrl).includes(".png") ||
    String(imageUrl).includes("blob")
  ) {
    const img = new Image();
    img.src = imageUrl;
    const { width, height } = img;
    return { height, width };
  } else {
    return { height: 0, width: 0 };
  }
};

export const getPdfPage = (prog, totPage) => {
  let val = 0;
  val = Math.round((parseFloat(prog) * totPage) / 100);
  val = isNaN(val) ? 0 : val;
  return val;
};

export const getPdfProg = (page, totPage) => {
  let val = 0;
  val = (page / totPage) * 100;
  return val;
};

export const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const isUsrEbShelf = (ebId) => {
  const { ebShelf } = usrEbook();
  let res = null;
  if (ebShelf === undefined || ebShelf.length === 0) {
    res = false;
  } else {
    res = ebShelf.filter((i) => String(i.id) === String(ebId)).length > 0;
  }
  return res;
};

export const isUsrEbRd = (ebId) => {
  const { ebConread } = usrEbook();
  let res = null;
  if (ebConread === undefined || ebConread.length === 0) {
    res = false;
  } else {
    res = ebConread.filter((i) => String(i.id) === String(ebId)).length > 0;
  }
  return res;
};

export const getEbPage = (ebId) => {
  const { ebConread } = usrEbook();
  let res = null;
  if (ebConread === undefined || ebConread.length === 0) {
    res = 1;
  } else {
    res = ebConread.filter((i) => String(i.id) === String(ebId));
    if (res.length === 0) {
      res = 1;
    } else {
      res = res.page;
    }
  }
  return res;
};

export const isCaBm = (caId, type) => {
  let { caBm } = usrBookmarks();
  caBm = caBm === undefined ? [] : caBm;
  let isCa = null;
  isCa =
    caBm.filter(
      (i) =>
        String(i.current_affair_id) === String(caId) &&
        String(i.category) === type
    ).length > 0
      ? true
      : false;
  return isCa;
};

export const isTpcBm = (tpcId) => {
  let { tpcBm } = usrBookmarks();
  tpcBm = tpcBm === undefined ? [] : tpcBm;
  const { id } = User();
  let isTpc = null;
  isTpc =
    tpcBm.filter(
      (i) =>
        String(i.topic_id) === String(tpcId) && String(i.user_id) === String(id)
    ).length > 0
      ? true
      : false;
  return isTpc;
};

export const loginInfo = (date) => {
  let nd = new Date();
  nd = nd.toTimeString();
  let usrD = new Date(date);
  let isLg = usrD.getTime();
  usrD = usrD.toTimeString();

  let ld = usrD - nd;
  if (isLg === 0) {
    return "Not Login Yet";
  } else if (ld < 600) {
    return "Just Now";
  } else if (ld <= 3600) {
    return "1 Hour ago";
  } else if (ld <= 86400) {
    return "Today";
  } else if (ld <= 172800) {
    return "2 Days Ago";
  } else {
    return dateFormat(date);
  }
};

export const isFocus = (tabs, videos, fi, id) => {
  let isFocus = false;
  if (tabs === 4) {
    fi = fi === 0 ? 0 : fi - 8;
    isFocus =
      id ===
        videos[fi < 0 ? videos.length + fi : fi >= videos.length ? 3 : fi].id ||
      id ===
        videos[
          fi + 1 === 0
            ? 0
            : fi + 1 < 0
            ? videos.length + fi + 1
            : fi + 1 >= videos.length
            ? 2
            : fi + 1
        ].id ||
      id ===
        videos[
          fi + 2 === 0
            ? 0
            : fi + 2 < 0
            ? videos.length + fi + 2
            : fi + 2 >= videos.length
            ? 1
            : fi + 2
        ].id ||
      id ===
        videos[
          fi + 3 === 0
            ? 0
            : fi + 3 < 0
            ? videos.length + fi + 3
            : fi + 3 >= videos.length
            ? 0
            : fi + 3
        ].id;
    return isFocus;
  } else if (tabs === 3) {
    fi = fi === 0 ? 0 : fi - 6;
    isFocus =
      id ===
        videos[fi < 0 ? videos.length + fi : fi >= videos.length ? 3 : fi].id ||
      id ===
        videos[
          fi + 1 === 0
            ? 0
            : fi + 1 < 0
            ? videos.length + fi + 1
            : fi + 1 >= videos.length
            ? 2
            : fi + 1
        ].id ||
      id ===
        videos[
          fi + 2 === 0
            ? 0
            : fi + 2 < 0
            ? videos.length + fi + 2
            : fi + 2 >= videos.length
            ? 1
            : fi + 2
        ].id;
    return isFocus;
  } else if (tabs === 2) {
    fi = fi === 0 ? 0 : fi - 4;
    isFocus =
      id ===
        videos[fi < 0 ? videos.length + fi : fi >= videos.length ? 3 : fi].id ||
      id ===
        videos[
          fi + 1 === 0
            ? 0
            : fi + 1 < 0
            ? videos.length + fi + 1
            : fi + 1 >= videos.length
            ? 2
            : fi + 1
        ].id;
    return isFocus;
  } else if (tabs === 1) {
    fi = fi === 0 ? 0 : fi - 2;
    isFocus =
      id ===
      videos[fi < 0 ? videos.length + fi : fi >= videos.length ? 3 : fi].id;
    return isFocus;
  } else {
    return isFocus;
  }
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );
      observer.observe(ref?.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref]);

  return isIntersecting;
}
