import React, { useRef, useState } from "react";
import "../../../Style/mockTest.scss";
import { useEffect } from "react";
import { mockReport } from "../../../Functions/mockFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { crsMock, mockRep } from "../../../Functions/LocalSaveData";
import coM from "../../../Assets/ui/co-m.png";
import coC from "../../../Assets/ui/co-p.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClock,
  faCrosshairs,
  faMinusCircle,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import dpaImg from "../../../Assets/ui/r-data.png";
import { MockQuesList } from "../../../Components/List";
import {
  secToTime,
  useForceUpdate,
  useIsVisible,
} from "../../../Functions/utils";
import { DlMdl } from "../../../Components/Modal";

export default function MockReport() {
  const { rpId } = useParams();
  const navigate = useNavigate();
  const force = useForceUpdate();
  const ref = useRef();
  const isVisible = useIsVisible(ref);
  const [dl, setDl] = useState(true);

  useEffect(
    () => {
      const load = async () =>
        await mockReport(rpId).then(() => setDl(false, force()));
      load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rpId]
  );

  const { test_name, total_question, positive_mark, cutoff } = crsMock();
  const { mockResult, mockQues, percentile } = mockRep();
  const {
    score,
    cutoff_cross,
    correct,
    wrong,
    unattempted,
    accuracy,
    time_taken,
    mock_lvl,
    id,
  } = mockResult ? mockResult : [];

  useEffect(() => {
    const int = setInterval(() => {
      if (isVisible) {
        force();
      }
    }, 1000);
    return () => clearInterval(int);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  let secTake =
    parseFloat(String(time_taken).split(":")[0]) * 3600 +
    parseFloat(String(time_taken).split(":")[1]) * 60 +
    parseFloat(String(time_taken).split(":")[2]);

  let avgSec = secTake / (correct + wrong);

  return (
    <div className="HomeD moRpMain">
      <DlMdl v={dl} />
      <p className="RpTxt">
        Mock Test Result <br />({test_name})
      </p>
      <div className="moRpDiv">
        <div className="scRkD">
          <div className="scRk">
            <p className="scRkTxt">
              <h3 style={{ margin: 0 }}>Score</h3>
              <br />
              {score} /{" "}
              {total_question *
                (positive_mark === "" || positive_mark === null
                  ? 1
                  : parseFloat(positive_mark))}
            </p>
            <p className="scRkTxt">
              <h3 style={{ margin: 0 }}>Percentile</h3>
              <br />
              {percentile}%
            </p>
          </div>

          <p className={"co " + (cutoff_cross === "0" ? "nc" : "c")}>
            {cutoff_cross === "0"
              ? `Didn't meet the cut-off`
              : `Congratulations! You did it. You met the cut off`}
          </p>
          <p className="rpCo">Overall Cut off is : {cutoff}</p>
          <p className="rpThnt">Try Harder Next Time!</p>
        </div>
        <div className="rpMrkDiv">
          <img
            className="rpMrkImg"
            alt="CutOff"
            src={cutoff_cross === "0" ? coM : coC}
          />
          <div className="rpMrkLst">
            <p className="rpMrkTxt">
              <FontAwesomeIcon className="rpCrIcn" icon={faCheck} />
              &nbsp; Correct
            </p>
            <p>{correct}</p>
          </div>

          <div className="rpMrkLst">
            <p className="rpMrkTxt">
              <FontAwesomeIcon className="rpWrIcn" icon={faXmarkCircle} />
              &nbsp; Wrong
            </p>
            <p>{wrong}</p>
          </div>
          <div className="rpMrkLst">
            <p className="rpMrkTxt">
              <FontAwesomeIcon className="rpUnIcn" icon={faMinusCircle} />
              &nbsp; Skipped
            </p>
            <p>{unattempted}</p>
          </div>
        </div>

        <div className="rpDpaBtnD">
          <p onClick={() => navigate("/mt/dpa")} className="rpDpaBtn">
            See Detailed Performance Analysis{" "}
          </p>
        </div>
        <div className="rpDpaBtnD"></div>

        <div className="rpDpaImgD">
          <img
            className="rpDpaImg"
            alt="See Detailed Performance Analysis"
            src={dpaImg}
          />
        </div>

        <div className="rpMrkDiv">
          {/* <div className="rpMrkLst alt">
            <p className="rpMrkTxt">
              <FontAwesomeIcon className="rpIcn" icon={faPercent} />
              &nbsp; Percentile
            </p>
            <p>{percentile}%</p>
          </div> */}

          <div className="rpMrkLst alt">
            <p className="rpMrkTxt">
              <FontAwesomeIcon className="rpIcn" icon={faCrosshairs} />
              &nbsp; Accuracy
            </p>
            <p>{accuracy}%</p>
          </div>

          <div className="rpMrkLst alt">
            <p className="rpMrkTxt">
              <FontAwesomeIcon className="rpIcn" icon={faStopwatch} />
              &nbsp; Time Consumed
            </p>
            <p>{time_taken}</p>
          </div>

          <div className="rpMrkLst alt">
            <p className="rpMrkTxt">
              <FontAwesomeIcon className="rpIcn" icon={faClock} />
              &nbsp; Average Time Per Question
            </p>
            <p>{secToTime(avgSec)}</p>
          </div>
        </div>

        <MockQuesList ques={mockQues} />

        <div className="rpAcBtnDiv">
          <p
            onClick={() => navigate(`/mt/me/${mock_lvl}/${id}`)}
            className="rpAcBtn"
          >
            Retake
          </p>
          <p onClick={() => navigate("/mt/emt/0")} className="rpAcBtn">
            Examine Mock test
          </p>
          <p onClick={() => navigate("/mt")} className="rpAcBtn">
            Exit
          </p>
        </div>
      </div>
    </div>
  );
}
