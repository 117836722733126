import { useEffect, useState } from "react";
// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
// End of import
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { CheckUser } from "./Functions/authFunctions";
import { LogType, loggedIn } from "./Functions/LocalSaveData";
import { Sidebar } from "./Components/Sidebar";
import { Navbar } from "./Components/Navbar";
import Footer from "./Components/Footer";
import LandingPage from "./Pages/HomeScreens/LandingPage";
import AboutUs from "./Pages/HomeScreens/AboutUs";
import ContactUs from "./Pages/HomeScreens/ContactUs";
import Courses from "./Pages/Courses/Courses";
import ExamUpdates from "./Pages/HomeScreens/ExamUpdates";
import Ebook from "./Pages/Ebook/Ebook";
import SubMain from "./Pages/Subscription/SubMain";
import Prelims from "./Pages/AI/Prelims";
import PrelimQues from "./Pages/AI/PrelimQues";
import PrelimReport from "./Pages/AI/PrelimReport";
import Home from "./Pages/HomeScreens/Home";
import MockTest from "./Pages/MockTest/MockTest";
import MockTerms from "./Pages/MockTest/MockTerms";
import MockExam from "./Pages/MockTest/MockExam";
import MockReport from "./Pages/MockTest/Reports/MockReport";
import ExaminMock from "./Pages/MockTest/Reports/ExaminMock";
import MockDpa from "./Pages/MockTest/Reports/MockDpa";
import MockSa from "./Pages/MockTest/Reports/MockSa";
import MockTa from "./Pages/MockTest/Reports/MockTa";
import MockEa from "./Pages/MockTest/Reports/MockEa";
import Topics from "./Pages/Topics/Topics";
import TopicRead from "./Pages/Topics/TopicRead";
import TopicPracTest from "./Pages/Topics/TopicPracTest";
import TopicPracRpt from "./Pages/Topics/TopicPracRpt";
import MVP from "./Pages/MVP/MVP";
import Profile from "./Pages/User/Profile";
import MockProg from "./Pages/MockTest/Reports/MockProg";
import TopicProg from "./Pages/Topics/TopicProg";
import DEReport from "./Pages/AI/DEReport";
import EbookGnr from "./Pages/Ebook/EbookGnr";
import EbookSub from "./Pages/Ebook/EbookSub";
import EbookInfoM from "./Pages/Ebook/EbookInfoM";
import Podcast from "./Pages/Podcast/Podcast";
import PodPlayerM from "./Pages/Podcast/PodPlayerM";
import Pib from "./Pages/CurrentAffairs/Pib";
import Dna from "./Pages/CurrentAffairs/Dna";
import Ma from "./Pages/CurrentAffairs/Ma";
import Na from "./Pages/CurrentAffairs/Na";
import Quiz from "./Pages/CurrentAffairs/Quiz";
import RemCourses from "./Pages/Courses/RemCourses";
import TopicBM from "./Pages/Bookmarks/TopicBM";
import CaBM from "./Pages/Bookmarks/CaBM";
import { VidInfoM } from "./Pages/MVP/VidInfoM";
import { mvpDash } from "./Functions/mvpFunctions";
import TnC from "./Pages/HomeScreens/TnC";
import PrivacyPolicy from "./Pages/HomeScreens/PrivacyPolicy";

const NavSide = ({ pathname }) => {
  const [sbVisible, setSbVisible] = useState(false);
  return (
    <>
      <Navbar currentPage={pathname} sideFunc={() => setSbVisible(true)} />
      <Sidebar
        setVisible={() => setSbVisible(!sbVisible)}
        visible={sbVisible}
      />
    </>
  );
};

function WebRoutes() {
  const [isUser, setIsUser] = useState(null);
  const [lt, setLt] = useState("");

  useEffect(() => {
    const load = async () => {
      setIsUser(await loggedIn());
      setLt(LogType());
    };
    load();
    const interval = setInterval(async () => await load(), 1000);
    return () => clearInterval(interval);
  }, [isUser, lt]);

  useEffect(() => {
    const load = async () => {
      await CheckUser();
      await mvpDash();
    };
    if (lt && lt !== "lo") {
      load();
    }
  }, [lt]);

  useEffect(() => {
    const load = async () => {
      await CheckUser();
      await mvpDash();
    };
    const interval = setInterval(() => {
      if (lt && lt !== "lo") {
        load();
      }
    }, 20000);
    return () => clearInterval(interval);
  }, [isUser, lt]);

  const Land = () => {
    if (isUser === null) {
      return null;
    } else if (isUser === false) {
      return <LandingPage />;
    } else {
      return lt === "lo" ? (
        <LandingPage />
      ) : lt === "pl" ? (
        <SubMain />
      ) : lt === "crs" ? (
        <Courses />
      ) : lt === "li" ? (
        <Home />
      ) : null;
    }
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return isUser === null ? null : (
    <>
      <NavSide pathname={pathname} />
      <Routes>
        <Route index path="/" element={<Land />} />
        <Route index path="up" element={<Profile />} />
        <Route index path="/au" element={<AboutUs />} />
        <Route index path="/cu" element={<ContactUs />} />
        <Route index path="/eu" element={<ExamUpdates />} />
        <Route index path="/tnc" element={<TnC />} />
        <Route index path="/pp" element={<PrivacyPolicy />} />
        {/* Courses */}
        <Route path="/crs">
          <Route index element={<Courses />} />
          <Route path="rm" element={<RemCourses />} />
        </Route>

        {/* Current Affairs */}
        <Route path="ca">
          <Route path="pib" element={<Pib />} />
          <Route path="dna" element={<Dna />} />
          <Route path="ma" element={<Ma />} />
          <Route path="na/:nId" element={<Na />} />
          <Route path="q" element={<Quiz />} />
        </Route>

        {/* PodCast */}
        <Route path="/pd">
          <Route index element={<Podcast />} />
          <Route path=":pId" element={<PodPlayerM />} />
        </Route>

        {/* AI */}
        <Route path="ai">
          <Route index path=":crs" element={<Prelims />} />
          <Route path="q/:cId/" element={<PrelimQues />} />
          <Route path="plr/:cId/" element={<PrelimReport />} />
          <Route path="der" element={<DEReport />} />
        </Route>

        {/* Topics */}
        <Route path="/tpc">
          <Route index path=":subId" element={<Topics />} />
          <Route path="rd/:subId/:tpId" element={<TopicRead />} />
          <Route path="pt/:subId/:tpId/:cmd" element={<TopicPracTest />} />
          <Route path="rpt/:tpId/:tstId" element={<TopicPracRpt />} />
          <Route path="tpr" element={<TopicProg />} />
        </Route>

        {/* Ebooks */}
        <Route path="/eb">
          <Route index element={<Ebook />} />
          <Route path="gnr/:gId" element={<EbookGnr />} />
          <Route path="sb/:sId" element={<EbookSub />} />
          {/* Info Modal Type 1 */}
          <Route path="m/:ebId" element={<EbookInfoM />} />
          {/* Info Modal Type 2 */}
          <Route path=":ebId" element={<Ebook />} />
        </Route>

        {/* MVP */}
        <Route path="/mvp">
          <Route index element={<MVP />} />
          <Route path=":vidId/:isFree" element={<VidInfoM />} />
        </Route>

        {/* MockTest */}
        <Route path="mt">
          <Route index element={<MockTest />} />
          <Route path="tnc/:lvl" element={<MockTerms />} />
          <Route path="me/:lvl/:rId" element={<MockExam />} />
          <Route path="mrp/:rpId" element={<MockReport />} />
          <Route path="emt/:qNum" element={<ExaminMock />} />
          <Route path="dpa" element={<MockDpa />} />
          <Route path="sa" element={<MockSa />} />
          <Route path="ta" element={<MockTa />} />
          <Route path="ea" element={<MockEa />} />
          <Route path="mp" element={<MockProg />} />
        </Route>

        {/* Bookmarks */}
        <Route path="bm">
          <Route path="tb" element={<TopicBM />} />
          <Route path="cb" element={<CaBM />} />
        </Route>
      </Routes>
      <Footer currentPage={pathname} />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <WebRoutes />
    </BrowserRouter>
  );
}

export default App;
library.add(fab, fas, far);
