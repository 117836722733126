import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "../../Style/topics.scss";
import { CaQuiz } from "../../Components/Ques";
import { caQues } from "../../Functions/CAFunctions";
import { QuizResult } from "../../Components/Widgets";
import { DlMdl } from "../../Components/Modal";

export default function Quiz() {
  const [data, setData] = useState([]);
  const [qNum, setQNum] = useState(0);
  const [user_answer, setUser_answer] = useState(null);

  const [cr, setCr] = useState(0);
  const [incr, setIncr] = useState(0);
  const [un, setUn] = useState(0);
  const [rpt, setRpt] = useState(false);
  const [dl, setDl] = useState(true);

  useEffect(() => {
    const load = async () => setData(await caQues(), setDl(false));
    load();
  }, []);

  const handNext = () => {
    if (data[qNum].user_answer === undefined) {
      data[qNum].user_answer = user_answer;
      let { answer } = data[qNum];
      let c = user_answer === answer ? 1 : 0;
      let i = user_answer && user_answer !== answer ? 1 : 0;
      let u = user_answer ? 0 : 1;
      setCr(cr + c);
      setIncr(incr + i);
      setUn(un + u);
      setQNum(qNum + 1);
      setUser_answer(null);
    } else {
      setUser_answer(
        data[qNum + 1].user_answer ? data[qNum + 1].user_answer : null
      );
      setQNum(qNum + 1);
    }
  };

  const handPrev = () => {
    if (qNum - 1 >= 0) {
      setQNum(qNum - 1);
      let q = data[qNum - 1];
      setUser_answer(q.user_answer);
    }
  };
  return (
    <div className="HomeD topicMain">
      <DlMdl v={dl} />
      <p className="subName">Current Affairs Quiz</p>
      {rpt ? (
        <QuizResult
          cr={cr}
          inCr={incr}
          unAt={un}
          setRpt={() => setRpt(false)}
        />
      ) : (
        <CaQuiz
          quesData={data}
          quesNum={qNum}
          usrAns={user_answer}
          handleNext={() => handNext()}
          handlePrev={() => handPrev()}
          setUsrAns={(val) => setUser_answer(val)}
          correct={cr}
          wrong={incr}
          unattempt={un}
          SubmitFunc={() => {
            data[qNum].user_answer = user_answer;
            setRpt(true);
          }}
        />
      )}
    </div>
  );
}
