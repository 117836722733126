import React from "react";
import "../../Style/ebook.scss";
import { useState } from "react";
import { useEffect } from "react";
import { getAllEbooks } from "../../Functions/landingFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { EbSdList, EbGridList } from "../../Components/List";
import { DlMdl } from "../../Components/Modal";
import { MobNaHd } from "../../Components/Widgets";

export default function EbookGnr() {
  const { gId } = useParams();
  const [gnrId, setGnrId] = useState(gId);
  const [gnrNm, setGnrNm] = useState("aaaaaaaaaaaaaaa");
  const [data, setData] = useState([]);
  const [dl, setDl] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      let val = await getAllEbooks();
      setData(val);
      const { genre } = val;
      if (genre) {
        setGnrNm(
          genre.filter((i) => String(i.id) === String(gId))[0].name,
          setDl(false)
        );
      }
    };
    load();
  }, [gId]);

  let { genre, ebooks } = data;
  let Gnrebooks = ebooks
    ? ebooks.filter((i) =>
        String(i.genre).toLowerCase().includes(gnrNm.toLowerCase())
      )
    : [];

  return (
    <div className="HomeD ebGnrMain">
      <DlMdl v={dl} />
      <div className="ebGnrDiv">
        <EbSdList
          navigate={(val) => navigate(`/eb/gnr/${val}`, { replace: true })}
          title={"Genres"}
          data={genre}
          gnrId={gnrId}
          setGnrId={(val) => setGnrId(val)}
          gnrNm={gnrNm}
          setGnrNm={(val) => setGnrNm(val)}
        />

        <MobNaHd
          title={"Genres"}
          catId={gnrId}
          setGnrId={(val) => setGnrId(val)}
          navigate={(val) => navigate(`/eb/gnr/${val}`, { replace: true })}
          data={genre}
          catName={
            genre && genre.filter((i) => String(i.id) === String(gnrId))[0].name
          }
        />
        <EbGridList data={Gnrebooks} />
      </div>
    </div>
  );
}
