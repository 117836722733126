import React, { useState, useEffect } from "react";
import "../../Style/currentAffair.scss";
import { allCa } from "../../Functions/CAFunctions";
import { CaList, MaCatLst } from "../../Components/List";
import Server from "../../JsonServer/Server";
import { DlMdl } from "../../Components/Modal";

export default function Ma() {
  const [data, setData] = useState([]);
  const [catId, setCatId] = useState(0);
  const [dl, setDl] = useState(true);

  useEffect(() => {
    const load = async () => {
      let i = await allCa();
      setData(i);
      let { caCat } = i;
      caCat = caCat ? caCat.reverse() : [];
      caCat = caCat ? caCat.filter((i) => i.current_affair_type === 3) : [];
      setCatId(caCat[0].id);
      setDl(false);
    };
    load();
  }, []);

  let { caCat, allMag } = data;
  caCat = caCat ? caCat.filter((i) => i.current_affair_type === 3) : [];
  allMag = allMag ? allMag.filter((i) => i.category_id === catId) : [];

  return (
    <div className="HomeD caMain">
      <DlMdl v={dl} />
      <p className="caName">Magazine Analysis</p>
      <MaCatLst data={caCat} catId={catId} setCatId={(val) => setCatId(val)} />
      <CaList
        type={"ma"}
        data={allMag}
        prefUrl={Server.MAG_SRC}
        caType={"mag"}
      />
    </div>
  );
}
