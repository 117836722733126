import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pracExam, pracSave } from "../../Functions/pracTestFunctions";
import "../../Style/topics.scss";
import { User, subName, tpcDetail } from "../../Functions/LocalSaveData";
import { PracQues } from "../../Components/Ques";
import { usrLimit } from "../../Functions/subsFunctions";
import { DlMdl, SubsPlanModal } from "../../Components/Modal";

export default function TopicPracTest() {
  const { tpId, subId, cmd } = useParams();
  const { payment_status } = User();
  let { topic_name } = tpcDetail(tpId);
  const [qCo, setQCo] = useState(0);
  const [data, setData] = useState([]);
  const [qNum, setQNum] = useState(0);
  const [user_answer, setUser_answer] = useState(null);
  const [subV, setSubV] = useState(false);
  const [timeCon, setTimeCon] = useState(0);
  const [dl, setDl] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const i = setTimeout(() => {
      setTimeCon(timeCon + 1);
    }, 1000);
    return () => clearTimeout(i);
  }, [timeCon]);

  useEffect(() => {
    const load = async () => {
      setData(await pracExam(tpId));
      let val = await usrLimit();
      return setQCo(val.quesCount, setDl(false));
    };
    load();
  }, [tpId]);

  const { prevQues, correct, wrong, unattempt } = data ? data : [];

  const handNext = () => {
    let isQuesFree = qCo >= 35 && payment_status !== 1;
    if (qNum === 0) {
      prevQues[qNum].user_answer = user_answer;
      prevQues[qNum].time_consumed = timeCon;

      if (isQuesFree) {
        setSubV(true);
      } else {
        pracSave(prevQues[qNum], cmd, navigate).then((val) => {
          if (val.status === 1) {
            pracExam(tpId).then((val) => {
              setUser_answer(null);
              setTimeCon(0);
              setData(val ? val : []);
              usrLimit().then((val) => {
                const { quesCount } = val;
                setQCo(quesCount);
              });
            });
          }
        });
      }
    } else {
      setQNum(qNum - 1);
      let q = prevQues[qNum - 1];
      setUser_answer(q.user_answer);
    }
  };

  const handPrev = () => {
    if (qNum + 1 !== prevQues.length) {
      setQNum(qNum + 1);
      let q = prevQues[qNum + 1];
      setUser_answer(q.user_answer);
    }
  };

  return (
    <div className="HomeD topicMain">
      <DlMdl v={dl} />
      <p className="subName">
        Practice Question
        <br />({subName(subId)})
      </p>
      <SubsPlanModal
        visible={subV}
        setVisible={() => setSubV(false)}
        msg={
          "You have reached the limit of free plan .Please upgrade to gain access of unlimited questions for practice."
        }
      />
      <PracQues
        quesData={prevQues}
        topic_name={topic_name}
        quesNum={qNum}
        usrAns={user_answer}
        handleNext={() => handNext()}
        handlePrev={() => handPrev()}
        setUsrAns={(val) => setUser_answer(val)}
        correct={correct}
        wrong={wrong}
        SubmitFunc={() => {
          prevQues[qNum].user_answer = user_answer;
          prevQues[qNum].time_consumed = timeCon;
          pracSave(prevQues[qNum], "end", navigate);
        }}
        unattempt={unattempt}
      />
    </div>
  );
}
