import React from "react";
import "../../Style/currentAffair.scss";
import { useState } from "react";
import { useEffect } from "react";
import { allPib } from "../../Functions/CAFunctions";
import { CaList } from "../../Components/List";
import Server from "../../JsonServer/Server";
import { DlMdl } from "../../Components/Modal";

export default function Pib() {
  const [data, setData] = useState([]);
  const [dl, setDl] = useState(true);

  useEffect(() => {
    const load = async () => setData(await allPib(), setDl(false));
    load();
  }, []);

  return (
    <div className="HomeD caMain">
      <DlMdl v={dl} />
      <p className="caName">Press Information Bureau Reports</p>
      <CaList data={data} prefUrl={Server.PIB_SRC} caType={"pib"} />
    </div>
  );
}
