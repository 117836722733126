import React, { useEffect, useRef, useState } from "react";
import "../../Style/land.scss";
import { EbookSrch } from "../../Components/List";
import { EbookStat } from "../../Components/Box";
import {
  EbBestSellers,
  EbGenres,
  UsrEbCrsl,
  EbTopReads,
} from "../../Components/BoxCarousel";
import { getAllEbooks } from "../../Functions/landingFunctions";
import { getEbook, getUsrEbook } from "../../Functions/ebookFunctions";
import { useParams } from "react-router-dom";
import { DlMdl, EbInfo, EbPdf, LoginModal } from "../../Components/Modal";
import { User } from "../../Functions/LocalSaveData";

export default function Ebook() {
  const { ebId } = useParams();
  const [data, setData] = useState([]);
  const render = useRef(true);
  const [ebData, setEbData] = useState([]);
  const [ebVisible, setEbVisible] = useState(ebId ? true : false);
  const isUSr = User().id;
  const [ebRdV, setEbRdV] = useState(false);
  const [lgMdlVsbl, setLgMdlVsbl] = useState(false);
  const [dl, setDl] = useState(true);

  useEffect(() => {
    const load = async () => {
      setEbData(await getEbook(ebId));
      setData(await getAllEbooks());
      await getUsrEbook();
      setDl(false);
    };

    if (render.current) {
      render.current = false;
      load();
    }
    let inte = setInterval(() => {
      getUsrEbook();
    }, 5000);
    return () => clearInterval(inte);
  }, [ebId]);

  return (
    <div className="homeMain">
      <DlMdl v={dl} />
      <EbInfo
        visible={ebVisible}
        item={ebData}
        setVisible={() => setEbVisible(false)}
        readFunc={() => (isUSr ? setEbRdV(true) : setLgMdlVsbl(true))}
      />
      <EbPdf
        ebId={ebData?.id}
        pdf={ebData?.ebook_pdf}
        visible={ebRdV}
        setVisible={() => setEbRdV(!ebRdV)}
      />
      <LoginModal
        visible={lgMdlVsbl}
        setVisible={() => setLgMdlVsbl(!lgMdlVsbl)}
      />
      <div className="ebookMain">
        <EbookSrch data={data} />
        <div className="ebwlcmDiv">
          <h1 className="ebwlcmTxt">Welcome to the world of E-books</h1>
        </div>
        <EbookStat data={data} />
        <UsrEbCrsl type={"cr"} />
        <EbGenres csrlData={data} />
        <UsrEbCrsl type={"bs"} />
        <EbTopReads csrlData={data} />
        <EbBestSellers csrlData={data} />
      </div>
    </div>
  );
}
