export const offer = [
  {
    src: require("../Assets/month_ca.png"),
  },
  {
    src: require("../Assets/slides4.jpg"),
  },
  {
    src: require("../Assets/ui/banner_1.png"),
  },
  {
    src: require("../Assets/ui/3.jpg"),
  },
];

export const providings = [
  {
    img: require("../Assets/ui/study.png"),
    title: "Study Material",
    desc: "Prepare yourself with organized complete study material developed by our experts and toppers.",
  },
  {
    img: require("../Assets/ui/papers.png"),
    title: "Practice Papers",
    desc: "Unlimited chapter wise practice questions With different difficulty levels and repetitively asked questions from any particular examination",
  },
  {
    img: require("../Assets/ui/Video_tuts.png"),
    title: "Video Tutorials",
    desc: "Concept videos to ease your visualization and memorize each and every topic.",
  },
  {
    img: require("../Assets/ui/panel.png"),
    title: "Discussion Panel",
    desc: "Clear all your queries & doubts with our experts and your peers on Interactive discussion panel.",
  },
  {
    img: require("../Assets/ui/test.png"),
    title: "Test Series",
    desc: "Attempt thousand Of mock test, sectional test designed by our exam experts based on latest.",
  },

  {
    img: require("../Assets/ui/ebook.jpg"),
    title: "E-Books",
    desc: "Enhance your knowledge through e-books Of the best publishers around the globe and also you may refresh your mind by reading stories from the best sellers of your favourite genre.",
  },

  {
    img: require("../Assets/ui/analysis.png"),
    title: "Personalized Analysis",
    desc: "Know your Strength and weakness to improve your score & rank by overall performance analysis. Track your progress for each subject and topic .",
  },
];

export const PopCourses = [
  {
    img: require("../Assets/Courses/cat1.jpg"),
    title: "CAT",
  },
  {
    img: require("../Assets/Courses/clat1.jpg"),
    title: "CLAT",
  },
  {
    img: require("../Assets/Courses/cpt1.jpg"),
    title: "CPT",
  },
  {
    img: require("../Assets/Courses/neet1.jpg"),
    title: "NEET",
  },
  {
    img: require("../Assets/Courses/sbi-clerk1.jpg"),
    title: "SBI-CLERK",
  },
  {
    img: require("../Assets/Courses/ssc1.jpg"),
    title: "SSC",
  },
  {
    img: require("../Assets/Courses/upsc1.jpg"),
    title: "UPSC",
  },
];

export const Reviews = [
  {
    img: require("../Assets/review/AnuradhaMangla.jpg"),
    name: "Anuradha Mangla",
    review:
      "I had attempted both, sectional as well as full length tests of Study Falcon. Overall performance analysis aided me in self-evaluation, which was essential for me as I was preparing on my own.",
  },
  {
    img: require("../Assets/review/AbhishekSharma.jpg"),
    name: "Abhishek Sharma",
    review:
      "The online mock tests and practice questions provided by Studyfalcon helped me to create a suitable strategy for achieving my goal.",
  },
  {
    img: require("../Assets/review/AnmolYadav.jpg"),
    name: "Anmol Yadav",
    review:
      "Studyfalcon really helps in your everyday preparation with never ending quizzes and study material.",
  },
  {
    img: require("../Assets/review/Harshvardhan.jpg"),
    name: "Harshvardhan",
    review:
      "If you are preparing for bank exams I would recommend you to try Study Falcon's content, mock test and you will see the growth in your learning.",
  },
  {
    img: require("../Assets/review/IshaanKhanna.jpg"),
    name: "Ishaan Khanna",
    review:
      "I came to know that one needs to practice online tests in order to increase speed and accuracy for cracking SSC-CCL then I got to know about Study Falcon.",
  },
  {
    img: require("../Assets/review/NaveenSharma.jpg"),
    name: "Naveen Sharma",
    review: "The test series improved my speed to a great extent.",
  },
  {
    img: require("../Assets/review/NayanSharma.jpg"),
    name: "Nayan Sharma",
    review:
      "The tests enhanced my level of accuracy while solving questions. Thanks a lot Study Falcon!",
  },
  {
    img: require("../Assets/review/Priya.jpg"),
    name: "Priya",
    review:
      "Study Falcon is really good for CT ET preparation. They have a huge question bank and the study material is very accurate.",
  },
  {
    img: require("../Assets/review/VictorBhattacharrya.jpg"),
    name: "Victor Bhattacharya",
    review:
      "I must say I have found best study material on Study Falcon. Newspaper and magazine Analysis is really good and is not found anywhere else. Highly recommended for UPSC aspirants.",
  },
  {
    img: require("../Assets/review/ShwetaSharma.jpg"),
    name: "Shweta Sharma",
    review:
      "I think that Study Falcon is the right place for serious candidates. DNA really explains very complicated topics in a simple manner. Very happy.",
  },
];

export const Team = [
  {
    name: "Pallav Anil Jain",
    img: require("../Assets/team/Pallav-Jain.jpg"),
  },
  {
    name: "Viney Goyal",
    img: require("../Assets/team/Vinay-Goyal.jpg"),
  },
  {
    name: "Parul Sharma",
    img: require("../Assets/team/Parul-Sharma.jpg"),
  },
  {
    name: "Parul Choudhary",
    img: require("../Assets/team/Parul-Chaudhary.jpg"),
  },
  {
    name: "Damini",
    img: require("../Assets/team/Damini.jpg"),
  },
  {
    name: "Suman Rawat",
    img: require("../Assets/team/Suman-Rawat.jpg"),
  },
];

export const CrsSlides = [
  {
    img: require("../Assets/ui/ssc_course_slider.jpg"),
    alt: "SSC",
  },
  {
    img: require("../Assets/ui/railway_course_slider.jpg"),
    alt: "Railways",
  },
  {
    img: require("../Assets/ui/upsc_course_slider.jpg"),
    alt: "UPSC",
  },
];

export const subsBanner = [
  {
    src: require("../Assets/ui/anti defection law VIDEO.jpg"),
  },
  {
    src: require("../Assets/ui/tmp_bd5331b0-00c5-4931-a126-90dff46df079.jpg"),
  },
];

export const subsOffer = [
  {
    ofr: "Mock Test",
    free: "1",
    m: "Unlimited",
    y: "Unlimited",
  },
  {
    ofr: "Practice Questions",
    free: "35",
    m: "Unlimited",
    y: "Unlimited",
  },
  {
    ofr: "Topic Notes",
    free: "1 Per Subject",
    m: "Full Access",
    y: "Full Access",
  },
  {
    ofr: "Video Lectures",
    free: "Intro Videos",
    m: "Full Access",
    y: "Full Access",
  },
  {
    ofr: "Magazines",
    free: "1",
    m: "Full Access",
    y: "Full Access",
  },
];

export const quesReportReasons = [
  "Wrong question",
  "Wrong answer",
  "Wrong explanaination",
  "Not relevant to topic",
  "Question not relevant to direction given",
  "Something else",
];

export const exCats = [
  "All Updates",
  "Government",
  "Bank",
  "Railway",
  "Teaching",
  "Police / Defence",
  "Engineering",
  "Education",
];

export const enCats = [
  { id: 0, cat: "", name: "Top News" },
  { id: 1, cat: "national", name: "National" },
  { id: 2, cat: "business", name: "Business" },
  { id: 3, cat: "politics", name: "Politics" },
  { id: 4, cat: "sports", name: "Sports" },
  { id: 5, cat: "technology", name: "Technology" },
  { id: 6, cat: "startup", name: "Startups" },
  { id: 7, cat: "entertainment", name: "Entertainment" },
  { id: 8, cat: "hatke", name: "Hatke" },
  { id: 9, cat: "world", name: "World" },
  { id: 10, cat: "automobile", name: "Automobile" },
  { id: 11, cat: "science", name: "Science" },
  { id: 12, cat: "travel", name: "Travel" },
  { id: 13, cat: "fashion", name: "Fashion" },
  { id: 14, cat: "education", name: "Education" },
  { id: 15, cat: "Health___Fitness", name: "Health and Fitness" },
  { id: 16, cat: "miscellaneous", name: "Miscellaneous" },
];

export const hiCats = [
  { id: 0, cat: "", name: "Top News" },
  { id: 1, cat: "national", name: "भारत" },
  { id: 2, cat: "business", name: "बिज़नेस" },
  { id: 3, cat: "politics", name: "राजनीति" },
  { id: 4, cat: "sports", name: "खेल" },
  { id: 5, cat: "technology", name: "विज्ञान एवं तकनीक" },
  { id: 6, cat: "entertainment", name: "मनोरंजन" },
  { id: 7, cat: "hatke", name: "हटके" },
  { id: 8, cat: "world", name: "दुनिया" },
  { id: 9, cat: "miscellaneous", name: "विविध" },
];
