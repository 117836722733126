import React from "react";
import "../../Style/topics.scss";
import { PodList } from "../../Components/List";

export default function Podcast() {
  return (
    <div className="HomeD topicMain">
      <p className="subName">Podcast</p>
      <PodList />
    </div>
  );
}
