import React, { useEffect, useRef, useState } from "react";
import tsfLogo from "../../Assets/logos.png";
import { crsMock } from "../../Functions/LocalSaveData";
import { MockQues } from "../../Components/Ques";
import { mockExam, mockSubmit } from "../../Functions/mockFunctions";
import { QuesSideBar } from "../../Components/Widgets";
import { Drawer } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { secToTime } from "../../Functions/utils";
import { DlMdl } from "../../Components/Modal";

export default function MockExam() {
  const { test_name, time_duration, mockSections } = crsMock();
  const [mockData, setMockData] = useState([]);
  const [msidVisible, setMsideVisible] = useState(false);
  const [quesNum, setQuesNum] = useState(0);
  const [user_answer, setUser_answer] = useState(null);
  const [unAtQ, setUnatQ] = useState([]);
  const [crQ, setCrQ] = useState([]);
  const [inCrQ, setInCrQ] = useState([]);
  const [tmDr, setTmDr] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();
  const { lvl, rId } = useParams();
  const [dl, setDl] = useState(true);
  const r = useRef(true);

  useEffect(() => {
    const load = async () => {
      if (r.current) {
        r.current = false;
        setMockData(await mockExam(lvl, rId));
        let sec =
          parseFloat(String(time_duration).split(":")[0]) * 3600 +
          parseFloat(String(time_duration).split(":")[1]) * 60 +
          parseFloat(String(time_duration).split(":")[2]);
        setSeconds(sec);
        setTmDr(sec);
        setDl(false);
      }
    };
    load();
  }, [lvl, rId, time_duration]);

  useEffect(() => {
    const interval = setInterval(
      () =>
        seconds === 1
          ? alert("Exam Completed")
          : setSeconds(seconds - 1, setTimer(secToTime(seconds - 1))),
      1000
    );
    return () => clearInterval(interval);
  }, [seconds]);

  let { mocQues } = mockData;

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const handleSectionChange = (section) => {
    let secques = mocQues.filter(
      (item) => String(item.section_id) === String(section.section_id)
    );
    setQuesNum(
      mocQues.findIndex((ques) => String(ques.id) === String(secques[0].id))
    );
  };

  const handleNext = async (cmd) => {
    let ques = mocQues[quesNum];
    ques.user_answer = user_answer;
    if (user_answer === null && cmd === "N") {
      ques.status = "NA";
    } else if (user_answer !== null && cmd === "N") {
      ques.status = "A";
    } else if (cmd === "MRN" && user_answer !== null) {
      ques.status = "AMRN";
    } else if (cmd === "MRN") {
      ques.status = "MRN";
    }
    if (user_answer === null) {
      setUnatQ(unAtQ.concat(ques.id));
    }
    if (user_answer === ques.answer) {
      ques.result = "CR";
      setCrQ(crQ.concat(ques.id));
    } else if (user_answer !== ques.answer && user_answer !== null) {
      ques.result = "INCR";
      setInCrQ(inCrQ.concat(ques.id));
    }
    if (mocQues.length === quesNum + 1) {
      alert("Exam Completed");
      setDl(true);
      await mockSubmit(
        navigate,
        lvl,
        mockSections[0].mockTestId,
        mocQues,
        seconds,
        tmDr,
        rId
      ).then(() => setDl(false));
    } else {
      setUser_answer(null);
      setQuesNum(quesNum + 1);
    }
  };

  return (
    <div className="HomeD mockExam">
      <DlMdl v={dl} />
      <div className="mockNav">
        <img className="navImg" alt="TheStudyFalcon" src={tsfLogo} />
        <p className="navCrs">{test_name}</p>
        <FontAwesomeIcon
          className="moDrawerIcn"
          onClick={() => setMsideVisible(true)}
          icon={faBarsStaggered}
        />

        <p className="navCrs td">
          Time Left: {timer === null ? time_duration : timer}
        </p>
      </div>
      <div className="mockDiv">
        <MockQues
          sections={mockSections === undefined ? [] : mockSections}
          ques={mocQues}
          quesNum={quesNum}
          handleSection={(val) => handleSectionChange(val)}
          handleNext={(val) => handleNext(val)}
          setUsrAns={(val) => setUser_answer(val)}
          usrAns={user_answer}
          SubmitFunc={() => {
            setDl(true);
            mockSubmit(
              navigate,
              lvl,
              mockSections[0].mockTestId,
              mocQues,
              seconds,
              tmDr,
              rId
            ).then(() => setDl(false));
          }}
        />
        <div className="sideDiv">
          <QuesSideBar
            ques={mocQues}
            setQuesNum={(val) => setQuesNum(val)}
            setUsrAns={(val) => setUser_answer(val)}
          />
        </div>

        <Drawer
          anchor={"right"}
          open={msidVisible}
          onClose={() => setMsideVisible()}
        >
          <div
            style={{
              width: 300,
            }}
          >
            <QuesSideBar
              ques={mocQues}
              setQuesNum={(val) => setQuesNum(val)}
              setUser_answer={(val) => setUser_answer(val)}
            />
          </div>
        </Drawer>
      </div>
    </div>
  );
}
