import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";

const getFreeVidThumb = async (id) => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.FREEVIDTHUMB + id, {
        headers: { authorization: "Bearer " + token },
      })
      .then(async (res) => {
        let th = await fetch(res.data);
        return URL.createObjectURL(await th.blob());
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

const getTpcVidThumb = async (id) => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.TPCVIDTHUMB + id, {
        headers: { authorization: "Bearer " + token },
      })
      .then(async (res) => {
        let th = await fetch(res.data);
        return URL.createObjectURL(await th.blob());
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const mvpDash = async () => {
  const { token, last_selected_course, id } = User();
  if (token) {
    return await axios
      .post(
        Server.MVP,
        { crsId: last_selected_course, usrId: id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => {
        let { freeVid, tpcVidSubs, conVids } = res.data;

        if (freeVid) {
          for (let i = 0; i < freeVid.length; i++) {
            const { id, is_thumb } = freeVid[i];
            freeVid[i].thumbnail =
              is_thumb === 1 ? await getFreeVidThumb(id) : "";
          }
        }

        if (conVids) {
          for (let i = 0; i < conVids.length; i++) {
            const { id, freevid, is_thumb } = conVids[i];
            conVids[i].thumbnail =
              freevid === 1 && is_thumb === 1
                ? await getFreeVidThumb(id)
                : await getTpcVidThumb(id);
          }
        }

        if (tpcVidSubs) {
          for (let i = 0; i < tpcVidSubs.length; i++) {
            const { videos } = tpcVidSubs[i];
            for (let j = 0; j < videos.length; j++) {
              const { id, is_thumb } = videos[j];
              videos[j].thumbnail =
                is_thumb === 1 ? await getTpcVidThumb(id) : "";
            }
          }
        }

        localStorage.setItem("mvp", JSON.stringify(res.data));
        return res.data;
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const getVidInfo = async (isFree, vidId) => {
  isFree = String(isFree) === "1";
  vidId = parseFloat(vidId);
  const { token, id } = User();

  if (token) {
    return await axios
      .post(
        Server.GETVIDINFO,
        { isFree, vidId, usrId: id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => {
        let { moreVids } = res.data;
        if (moreVids && isFree) {
          for (let i = 0; i < moreVids.length; i++) {
            const { id } = moreVids[i];
            let thumb = await getFreeVidThumb(id);
            moreVids[i].thumbnail = thumb;
          }
        } else if (moreVids) {
          for (let i = 0; i < moreVids.length; i++) {
            const { id } = moreVids[i];
            let thumb = await getTpcVidThumb(id);
            moreVids[i].thumbnail = thumb;
          }
        }
        return res.data;
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const getEduInfo = async (eduId) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.GETEDUINFO,
        { eduId },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const getTpcVids = async (tpcId) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.GETTPCVIDS,
        { tpcId, usrId: id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const vidRead = async (vidData, ref) => {
  ref = ref ? ref : { current: false };
  const { token } = User();
  if (ref.current && token) {
    ref.current = false;
    await axios
      .post(Server.VIDREAD, vidData, {
        headers: { authorization: "Bearer " + token },
      })
      .then(() => true)
      .catch((err) => console.log(err));
    const i = setTimeout(() => {
      ref.current = true;
    }, 10000);
    return () => clearTimeout(i);
  }
};

export const remVidRead = async (vidData) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(Server.REMVIDREAD, vidData, {
        headers: { authorization: "Bearer " + token },
      })
      .then(async () => await mvpDash())
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};
