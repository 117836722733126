import React from "react";
import "../../Style/topics.scss";
import { useState } from "react";
import { useEffect } from "react";
import { pracAnalysis } from "../../Functions/pracTestFunctions";
import { useRef } from "react";
import { PracAnalysisChart } from "../../Components/Charts";
import { PracAnalysisList } from "../../Components/List";

export default function TopicProg() {
  const [data, setData] = useState([]);
  const render = useRef(true);

  useEffect(() => {
    if (render.current) {
      render.current = false;
      pracAnalysis().then((val) => setData(val === undefined ? [] : val));
    }
  }, []);

  const { subWise, tpcWise } = data;

  return (
    <div className="HomeD tpcProgMain">
      <p className="tpcHead">Practice Test Analysis</p>
      <PracAnalysisChart data={subWise} />
      <PracAnalysisList data={subWise} tpData={tpcWise} />
    </div>
  );
}
