import React, { useRef, useState } from "react";
import "../../Style/mockTest.scss";
import { User, crsMock } from "../../Functions/LocalSaveData";
import mockAtmpt from "../../Assets/ui/sec-illus.png";
import { MockLvlModal, SubsPlanModal } from "../../Components/Modal";
import { useForceUpdate, useIsVisible } from "../../Functions/utils";
import { useEffect } from "react";
import { PrevMockList } from "../../Components/List";
import { usrLimit } from "../../Functions/subsFunctions";

export default function MockTest() {
  const [visible, setVisible] = useState(false);
  let { test_name, prevMock, id, course_id } = crsMock();
  const [mockAv, setMockAv] = useState(true);
  const [subV, setSubV] = useState(false);
  const { course_name } = User();
  const force = useForceUpdate();
  let { payment_status } = User();
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  prevMock =
    prevMock !== undefined
      ? prevMock.filter(
          (i) =>
            String(i.course_id) === String(course_id) &&
            String(i.mocktest_id) === String(id)
        )
      : [];

  useEffect(() => {
    const load = async () => {
      let val = await usrLimit();
      const { mockCount } = val;
      console.log(mockCount, payment_status);
      setMockAv(mockCount > 0 && payment_status !== 1);
      force();
    };
    load();
    const int = setInterval(() => {
      if (isVisible) {
        load();
      }
    }, 2000);
    return () => clearInterval(int);
  }, [force, isVisible, payment_status]);

  return (
    <div className="HomeD mtMain" ref={ref}>
      <SubsPlanModal
        visible={subV}
        setVisible={() => setSubV(false)}
        msg={`You have reached the limit of free plan .Please upgrade to attempt unlimited Mock Test.`}
      />
      <MockLvlModal setVisible={() => setVisible(!visible)} visible={visible} />

      <div className="mockHead">
        <p className="MCrsName">{test_name ? test_name : course_name}</p>
        <div className="mockHdRowDiv">
          <div className="mockAttemptD">
            <p className="mockAtmptTxt">Attempt a new Mock-Test</p>
            <p
              onClick={() =>
                !test_name
                  ? alert(
                      "Currently, Mock-test is not available for this exam. Check back later"
                    )
                  : mockAv
                  ? setSubV(true)
                  : setVisible(true)
              }
              className="attemptBtn"
            >
              Attempt Now
            </p>
          </div>
          <img className="mockAtmptImg" alt="Attempt Mock" src={mockAtmpt} />
        </div>
      </div>
      <div className="prevMockDiv">
        <p className="prevMockTxt">Previously Attempted MockTest</p>
        {prevMock.length === 0 ? (
          <p className="noMockTxt">No Mock-Test Attempted Yet</p>
        ) : (
          <PrevMockList />
        )}
      </div>
    </div>
  );
}
