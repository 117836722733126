import React, { useState } from "react";
import Server from "../JsonServer/Server";
import "../Style/ques.scss";
import { loadImage } from "../Functions/utils";
import { useEffect } from "react";
import { RprtQuesMdl } from "./Modal";

export const Ques = ({ ques, usrAns, setUsrAns, handleNext }) => {
  const {
    id,
    subject_name,
    topic_name,
    difficulty_level,
    direction_type,
    direction,
    question_type,
    is_option_e,
    question,
    option_a,
    option_b,
    option_c,
    option_d,
    option_e,
    isRpt,
  } = ques;

  const [rpV, setRpv] = useState(false);

  return (
    id && (
      <div className="quesMain">
        {rpV && (
          <RprtQuesMdl
            v={rpV}
            setV={() => {
              setRpv(false);
              ques.isRpt = "done";
            }}
            question_id={ques?.question_id}
          />
        )}
        <label className="qSubName">{subject_name}</label>
        <div className="quesDiv">
          <div className="quesTopicDiv">
            <p>{topic_name}</p>
            <p>
              Difficulty Level: &nbsp;
              <span className="quesDif"> {difficulty_level}</span>
            </p>
          </div>
          <div className="quesDiv">
            {direction_type === 1 ? (
              <img
                className="quesImg"
                alt="Question"
                src={Server.QUESTHUMB + direction}
              />
            ) : (
              <label className="quesTxt">{direction}</label>
            )}
            {question_type === 1 ? (
              <img
                className="quesImg"
                alt="Question"
                src={Server.QUESTHUMB + question}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: question }}
                className="quesTxt"
              ></div>
            )}
            <div className="quesOptDiv">
              <p
                onClick={() => setUsrAns("A")}
                className={"quesOpt" + (usrAns === "A" ? "  quesAt" : "")}
              >
                A.&nbsp;{option_a}
              </p>
              <p
                onClick={() => setUsrAns("B")}
                className={"quesOpt" + (usrAns === "B" ? "  quesAt" : "")}
              >
                B.&nbsp;{option_b}
              </p>
              <p
                onClick={() => setUsrAns("C")}
                className={"quesOpt" + (usrAns === "C" ? "  quesAt" : "")}
              >
                C.&nbsp;{option_c}
              </p>
              <p
                onClick={() => setUsrAns("D")}
                className={"quesOpt" + (usrAns === "D" ? "  quesAt" : "")}
              >
                D.&nbsp;{option_d}
              </p>
              {is_option_e === 0 ? null : (
                <p
                  onClick={() => setUsrAns("E")}
                  className={"quesOpt" + (usrAns === "E" ? "  quesAt" : "")}
                >
                  E.&nbsp;{option_e}
                </p>
              )}
            </div>
            <p onClick={() => handleNext()} className="nxtBtn">
              Next
            </p>
          </div>
          <p className="rpBtn" onClick={() => (isRpt ? null : setRpv(true))}>
            {isRpt ? "Reported" : "Report Question"}
          </p>
        </div>
      </div>
    )
  );
};

export const MockQues = ({
  ques,
  quesNum,
  sections,
  handleNext,
  setUsrAns,
  usrAns,
  handleSection,
  is_examine,
  SubmitFunc,
}) => {
  const {
    direction_type,
    direction,
    question_type,
    is_option_e,
    question,
    option_a,
    option_b,
    option_c,
    option_d,
    option_e,
    section_id,
    answer,
    explanation_type,
    explanation,
  } = ques ? ques[quesNum] : [];

  const { innerWidth } = window;

  const [quesImg, setQuesImg] = useState([]);
  const [dirImg, setDirImg] = useState([]);
  const [expImg, setExpImg] = useState([]);

  useEffect(() => {
    let interval = setInterval(() => {
      setQuesImg(loadImage(Server.QUESTHUMB + question));
      setDirImg(loadImage(Server.QUESTHUMB + direction));
      setExpImg(loadImage(Server.QUESTHUMB + explanation));
    }, 100);
    return () => clearInterval(interval);
  }, [direction, explanation, question]);

  return (
    <div className="mquM">
      <div className="mSecDiv">
        {sections.map((item, index) => (
          <p
            onClick={() => handleSection(item)}
            className={
              "mSec " +
              (String(section_id) === String(item.section_id) ? "actSec" : "")
            }
            key={index}
          >
            {item.section_name}
          </p>
        ))}
      </div>
      <div className="quesDiv">
        <div className="quesScrol">
          {direction_type === 1 ? (
            <img
              className="quesImg"
              alt="Question"
              style={
                innerWidth > 600
                  ? { height: dirImg.height, width: dirImg.width }
                  : null
              }
              src={Server.QUESTHUMB + direction}
            />
          ) : (
            <label className="quesTxt">{direction}</label>
          )}
          <div style={{ display: "flex" }}>
            <p className="quesNum">
              <b>Q.{quesNum + 1}</b>
            </p>
            &nbsp;
            {question_type === 1 ? (
              <img
                className="quesImg"
                alt="Question"
                style={
                  innerWidth > 600
                    ? { height: quesImg.height, width: quesImg.width }
                    : null
                }
                src={Server.QUESTHUMB + question}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: question }}
                className="quesTxt"
              ></div>
            )}
          </div>
        </div>
        <div className="quesOptDiv">
          <p
            onClick={() => setUsrAns(is_examine ? usrAns : "A")}
            className={
              is_examine
                ? "exmOpt " +
                  (answer === "A" ? "quesCr" : usrAns === "A" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "A" ? "  quesAt" : "")
            }
          >
            <b>A.</b>&nbsp;{option_a}
          </p>
          <p
            onClick={() => setUsrAns(is_examine ? usrAns : "B")}
            className={
              is_examine
                ? "exmOpt " +
                  (answer === "B" ? "quesCr" : usrAns === "B" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "B" ? "  quesAt" : "")
            }
          >
            <b>B.</b>&nbsp;{option_b}
          </p>
          <p
            onClick={() => setUsrAns(is_examine ? usrAns : "C")}
            className={
              is_examine
                ? "exmOpt " +
                  (answer === "C" ? "quesCr" : usrAns === "C" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "C" ? "  quesAt" : "")
            }
          >
            <b>C.</b>&nbsp;{option_c}
          </p>
          <p
            onClick={() => setUsrAns(is_examine ? usrAns : "D")}
            className={
              is_examine
                ? "exmOpt " +
                  (answer === "D" ? "quesCr" : usrAns === "D" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "D" ? "  quesAt" : "")
            }
          >
            <b>D.</b>&nbsp;{option_d}
          </p>
          {is_option_e === 0 ? null : (
            <p
              onClick={() => setUsrAns(is_examine ? usrAns : "E")}
              className={
                is_examine
                  ? "exmOpt " +
                    (answer === "E" ? "quesCr" : usrAns === "E" ? "quesWr" : "")
                  : "quesOpt" + (usrAns === "E" ? "  quesAt" : "")
              }
            >
              <b>E.</b>&nbsp;{option_e}
            </p>
          )}
        </div>
        {is_examine && explanation !== "" && explanation !== null ? (
          <div>
            <p style={{ textAlign: "center" }}>
              <h2> Explaination </h2>
            </p>
            {explanation_type === 1 ? (
              <img
                className="quesImg"
                alt="Question"
                style={
                  innerWidth > 600
                    ? { height: expImg.height, width: expImg.width }
                    : null
                }
                src={Server.QUESTHUMB + explanation}
              />
            ) : (
              <label className="quesTxt">{explanation}</label>
            )}
          </div>
        ) : null}
        <div className="btnDiv">
          {is_examine ? null : (
            <p onClick={() => setUsrAns("")} className="nxtBtn">
              Clear Response
            </p>
          )}
          <p onClick={() => handleNext("N")} className="nxtBtn">
            {is_examine ? "Next" : "Save & Next"}
          </p>
        </div>
        <div
          className="rvwBtnDiv"
          style={{ justifyContent: is_examine ? "center" : null }}
        >
          {is_examine ? null : (
            <p onClick={() => handleNext("MRN")} className="rvwBtn">
              Mark for Review & Next
            </p>
          )}
          <p
            onClick={() =>
              window.confirm(
                "Are you sure! , You want to end this mocktest now? "
              )
                ? SubmitFunc()
                : null
            }
            className="rvwBtn"
          >
            End Session
          </p>
        </div>
      </div>
    </div>
  );
};

export const PracQues = ({
  quesData,
  topic_name,
  setUsrAns,
  usrAns,
  SubmitFunc,
  handleNext,
  handlePrev,
  quesNum,
  correct,
  wrong,
  unattempt,
}) => {
  let {
    direction_type,
    direction,
    question_type,
    is_option_e,
    question,
    option_a,
    option_b,
    option_c,
    option_d,
    option_e,
    status,
    explanation_type,
    explanation,
    difficulty_level,
    answer,
    isRpt,
  } = quesData ? quesData[quesNum] : [];
  let qNo = quesData ? quesData.length - quesNum : 0;
  const { innerWidth } = window;
  const [quesImg, setQuesImg] = useState([]);
  const [dirImg, setDirImg] = useState([]);
  const [expImg, setExpImg] = useState([]);
  const [showExp, setShowExp] = useState(false);
  const [rpV, setRpv] = useState(false);

  useEffect(() => {
    setShowExp(false);
    let interval = setInterval(() => {
      setQuesImg(loadImage(Server.QUESTHUMB + question));
      setDirImg(loadImage(Server.QUESTHUMB + direction));
      setExpImg(loadImage(Server.QUESTHUMB + explanation));
    }, 100);
    return () => clearInterval(interval);
  }, [direction, explanation, question]);

  const handleAns = (val) => {
    setUsrAns(val);
    quesData[quesNum].status = val === answer ? "correct" : "wrong";
  };

  return (
    <div className="pracQuesMain">
      {rpV && (
        <RprtQuesMdl
          v={rpV}
          setV={() => {
            setRpv(false);
            quesData[quesNum].isRpt = "done";
          }}
          question_id={quesData[quesNum]?.question_id}
        />
      )}
      <div className="quesHead">
        <p className="txt">{topic_name}</p>
        <p className="txt">
          Difficulty Level: &nbsp;{" "}
          <span
            className={
              difficulty_level === 1
                ? "es"
                : difficulty_level === 2
                ? "md"
                : "hd"
            }
          >
            {difficulty_level === 1
              ? "Easy"
              : difficulty_level === 2
              ? "Medium"
              : "Hard"}
          </span>
        </p>
      </div>
      <div className="quesDiv">
        <div className="quesScrol">
          {direction_type === 1 ? (
            <img
              className="quesImg"
              alt="Question"
              style={
                innerWidth > 600
                  ? { height: dirImg.height, width: dirImg.width }
                  : null
              }
              src={Server.QUESTHUMB + direction}
            />
          ) : (
            <label className="quesTxt">{direction}</label>
          )}
          <div style={{ display: "flex" }}>
            <p className="quesNum">
              <b>Q.{qNo}</b>
            </p>
            &nbsp;
            {question_type === 1 ? (
              <img
                className="quesImg"
                alt="Question"
                style={
                  innerWidth > 600
                    ? { height: quesImg.height, width: quesImg.width }
                    : null
                }
                src={Server.QUESTHUMB + question}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: question }}
                className="quesTxt"
              ></div>
            )}
          </div>
        </div>
        <div className="quesOptDiv">
          <p
            onClick={() => (status !== null ? null : handleAns("A"))}
            className={
              status !== null
                ? "exmOpt " +
                  (answer === "A" ? "quesCr" : usrAns === "A" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "A" ? "  quesAt" : "")
            }
          >
            A.&nbsp;{option_a}
          </p>
          <p
            onClick={() => (status !== null ? null : handleAns("B"))}
            className={
              status !== null
                ? "exmOpt " +
                  (answer === "B" ? "quesCr" : usrAns === "B" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "B" ? "  quesAt" : "")
            }
          >
            B.&nbsp;{option_b}
          </p>
          <p
            onClick={() => (status !== null ? null : handleAns("C"))}
            className={
              status !== null
                ? "exmOpt " +
                  (answer === "C" ? "quesCr" : usrAns === "C" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "C" ? "  quesAt" : "")
            }
          >
            C.&nbsp;{option_c}
          </p>
          <p
            onClick={() => (status !== null ? null : handleAns("D"))}
            className={
              status !== null
                ? "exmOpt " +
                  (answer === "D" ? "quesCr" : usrAns === "D" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "D" ? "  quesAt" : "")
            }
          >
            D.&nbsp;{option_d}
          </p>
          {is_option_e === 0 ? null : (
            <p
              onClick={() => (status !== null ? null : handleAns("E"))}
              className={
                status !== null
                  ? "exmOpt " +
                    (answer === "E" ? "quesCr" : usrAns === "E" ? "quesWr" : "")
                  : "quesOpt" + (usrAns === "E" ? "  quesAt" : "")
              }
            >
              E.&nbsp;{option_e}
            </p>
          )}
        </div>

        <div className="btnDiv">
          <p onClick={() => handlePrev()} className="nxtBtn">
            Previous
          </p>
          {explanation ? (
            explanation.length > 0 && status !== null ? (
              <p onClick={() => setShowExp(!showExp)} className="nxtBtn">
                {showExp ? "Hide Explaination" : "Show Explaination"}
              </p>
            ) : null
          ) : null}

          <p onClick={() => handleNext()} className="nxtBtn">
            {"Next"}
          </p>
        </div>
        {showExp ? (
          <div>
            <p style={{ textAlign: "center" }}>
              <h2> Explaination </h2>
            </p>
            {explanation_type === 1 ? (
              <img
                className="quesImg"
                alt="Question"
                style={
                  innerWidth > 600
                    ? { height: expImg.height, width: expImg.width }
                    : null
                }
                src={Server.QUESTHUMB + explanation}
              />
            ) : (
              <label className="quesTxt">{explanation}</label>
            )}
          </div>
        ) : null}
        <div className="mob">
          <p className="cr">Correct: {correct}</p>
          <p className="wr">Wrong: {wrong}</p>
          <p className="sk">Skipped: {unattempt}</p>
        </div>

        <div className="rvwBtnDiv">
          <p onClick={() => (isRpt ? null : setRpv(true))} className="rvwBtn">
            {isRpt ? "Reported" : "Report Question"}
          </p>
          <div className="scoreDiv">
            <p className="cr">Correct: {correct}</p>
            <p className="wr">Wrong: {wrong}</p>
            <p className="sk">Skipped: {unattempt}</p>
          </div>
          <p
            onClick={() =>
              window.confirm(
                "Are you sure! , You want to end this practice session now? "
              )
                ? SubmitFunc()
                : null
            }
            className="rvwBtn"
          >
            End Session
          </p>
        </div>
      </div>
    </div>
  );
};

export const CaQuiz = ({
  quesData,
  setUsrAns,
  usrAns,
  SubmitFunc,
  handleNext,
  handlePrev,
  quesNum,
  correct,
  wrong,
  unattempt,
}) => {
  let {
    direction_type,
    direction,
    question_type,
    question,
    option_a,
    option_b,
    option_c,
    option_d,
    status,
    explanation_type,
    explanation,
    answer,
  } = quesData[quesNum] === undefined ? [] : quesData[quesNum];

  let qNo = quesNum + 1;

  const { innerWidth } = window;
  const [quesImg, setQuesImg] = useState([]);
  const [dirImg, setDirImg] = useState([]);
  const [expImg, setExpImg] = useState([]);
  const [showExp, setShowExp] = useState(false);

  useEffect(() => {
    setShowExp(false);
    let interval = setInterval(() => {
      setQuesImg(loadImage(Server.QUESTHUMB + question));
      setDirImg(loadImage(Server.QUESTHUMB + direction));
      setExpImg(loadImage(Server.QUESTHUMB + explanation));
    }, 100);
    return () => clearInterval(interval);
  }, [direction, explanation, question]);

  const handleAns = (val) => {
    setUsrAns(val);
    quesData[quesNum].status = val === answer ? "correct" : "wrong";
  };

  return (
    <div className="pracQuesMain">
      <div className="quesDiv">
        <div className="quesScrol">
          {direction_type === 1 ? (
            <img
              className="quesImg"
              alt="Question"
              style={
                innerWidth > 600
                  ? { height: dirImg.height, width: dirImg.width }
                  : null
              }
              src={Server.QUESTHUMB + direction}
            />
          ) : (
            <label className="quesTxt">{direction}</label>
          )}
          <div style={{ display: "flex" }}>
            <p className="quesNum">
              <b>Q.{qNo}</b>
            </p>
            &nbsp;
            {question_type === 1 ? (
              <img
                className="quesImg"
                alt="Question"
                style={
                  innerWidth > 600
                    ? { height: quesImg.height, width: quesImg.width }
                    : null
                }
                src={Server.QUESTHUMB + question}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: question }}
                className="quesTxt"
              ></div>
            )}
          </div>
        </div>
        <div className="quesOptDiv">
          <p
            onClick={() => (status !== null ? null : handleAns("A"))}
            className={
              status !== null
                ? "exmOpt " +
                  (answer === "A" ? "quesCr" : usrAns === "A" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "A" ? "  quesAt" : "")
            }
          >
            A.&nbsp;{option_a}
          </p>
          <p
            onClick={() => (status !== null ? null : handleAns("B"))}
            className={
              status !== null
                ? "exmOpt " +
                  (answer === "B" ? "quesCr" : usrAns === "B" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "B" ? "  quesAt" : "")
            }
          >
            B.&nbsp;{option_b}
          </p>
          <p
            onClick={() => (status !== null ? null : handleAns("C"))}
            className={
              status !== null
                ? "exmOpt " +
                  (answer === "C" ? "quesCr" : usrAns === "C" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "C" ? "  quesAt" : "")
            }
          >
            C.&nbsp;{option_c}
          </p>
          <p
            onClick={() => (status !== null ? null : handleAns("D"))}
            className={
              status !== null
                ? "exmOpt " +
                  (answer === "D" ? "quesCr" : usrAns === "D" ? "quesWr" : "")
                : "quesOpt" + (usrAns === "D" ? "  quesAt" : "")
            }
          >
            D.&nbsp;{option_d}
          </p>
        </div>

        <div className="btnDiv">
          <p onClick={() => handlePrev()} className="nxtBtn">
            Previous
          </p>
          {explanation === undefined ? null : explanation.length > 0 &&
            status !== null ? (
            <p onClick={() => setShowExp(!showExp)} className="nxtBtn">
              {showExp ? "Hide Explaination" : "Show Explaination"}
            </p>
          ) : null}

          <p onClick={() => handleNext()} className="nxtBtn">
            {"Next"}
          </p>
        </div>
        {showExp ? (
          <div>
            <p style={{ textAlign: "center" }}>
              <h2> Explaination </h2>
            </p>
            {explanation_type === 1 ? (
              <img
                className="quesImg"
                alt="Question"
                style={
                  innerWidth > 600
                    ? { height: expImg.height, width: expImg.width }
                    : null
                }
                src={Server.QUESTHUMB + explanation}
              />
            ) : (
              <label className="quesTxt">{explanation}</label>
            )}
          </div>
        ) : null}
        <div className="mob">
          <p className="cr">Correct: {correct}</p>
          <p className="wr">Wrong: {wrong}</p>
          <p className="sk">Skipped: {unattempt}</p>
        </div>

        <div className="rvwBtnDiv">
          <p onClick={() => {}} className="rvwBtn">
            Report Question
          </p>
          <div className="scoreDiv">
            <p className="cr">Correct: {correct}</p>
            <p className="wr">Wrong: {wrong}</p>
            <p className="sk">Skipped: {unattempt}</p>
          </div>
          <p
            onClick={() =>
              window.confirm("Are you sure!,\nYou want to end this Quiz now?")
                ? SubmitFunc()
                : null
            }
            className="rvwBtn"
          >
            End Session
          </p>
        </div>
      </div>
    </div>
  );
};
