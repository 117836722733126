import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";
import color from "../Style/colorPalette";
import { loadScript } from "./utils";

export const getEduDp = async (id) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.EDUDP,
        { id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(async (res) => {
        let th = await fetch(res.data.eduDp);
        return URL.createObjectURL(await th.blob());
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const subContent = async () => {
  const { token } = User();
  if (token) {
    return await axios
      .get(Server.SUBCONTENT, {
        headers: { authorization: "Bearer " + token },
      })
      .then(async (res) => {
        let { popEdu } = res.data;
        if (popEdu) {
          for (let i = 0; i < popEdu.length; i++) {
            const { id } = popEdu[i];
            popEdu[i].profile_pic = await getEduDp(id);
          }
        }
        return res.data;
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const usrPurchase = async (amt) => {
  const { name, mobile_no, email_id, token } = User();
  const res = await loadScript(Server.RAZOR_SDK);
  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  if (token) {
    return await axios
      .post(
        Server.CRORDER,
        { amt },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        const { amount, id: order_id, currency } = res.data;
        const options = {
          // key: "rzp_test_1DP5mmOlF5G5ag", // TEST KEY
          key: "rzp_live_q5Uj8aQSX73Qbf", //LIVE KEY
          order_id: order_id,
          amount: amount,
          currency: currency,

          name: "Damaso Digital Solutions Pvt. Ltd.",
          description: "Test Transaction",
          prefill: {
            name: name,
            email: email_id,
            contact: mobile_no,
          },
          notes: {
            address: "F25/99, SECTOR 7, ROHINI, DELHI-110085",
          },
          theme: {
            color: color.darkBlue,
          },
          handler: async (r) => {
            const data = {
              paymentId: r.razorpay_payment_id,
              razorpayOrderId: r.razorpay_order_id,
              razorpaySignature: r.razorpay_signature,
            };
            return await userSub(amt, data.paymentId);
            // payCapture(data.paymentId, amount);
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", (err) => console.log(err));
        rzp1.open();
      })
      .catch((err) => {
        console.log(err);
        alert("Server error. Are you online?");
      });
  } else {
    return [];
  }
};

const userSub = async (amt, txnId) => {
  const { city, id, token } = User();
  let data = {};
  data.gst = (parseFloat(amt) * 15.25) / 100;
  data.gst = Math.round(data.gst);
  data.subAmt = amt - data.gst;
  data.netAmt = amt;
  data.subType = amt === 6000 ? 2 : 1;
  data.txnId = txnId;
  data.city = city;
  data.id = id;
  if (token) {
    return await axios
      .post(Server.USUB, data, {
        headers: { authorization: "Bearer " + token },
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const usrLimit = async () => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.USRLIMIT,
        { usrId: id },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => res.data);
  } else {
    return [];
  }
};
