import React from "react";
import "../../../Style/mockTest.scss";
import { crsMock, mockRep } from "../../../Functions/LocalSaveData";
import { EaGuageChart } from "../../../Components/Charts";
import { useNavigate } from "react-router-dom";
// import { TopicAnList } from "../../../Components/List";
// import { IOSSwitch } from "../../../Components/Switch";
// import { TaProgChart } from "../../../Components/Charts";

export default function MockEa() {
  const { topicAnalysis } = mockRep();
  const { test_name } = crsMock();
  const naviagte = useNavigate();

  return (
    <div className="HomeD mockEaMain">
      <p className="RpTxt">Mocktest Expert Analysis</p>
      <EaGuageChart />
      <div className="mockEaDiv">
        <p className="eaCongTxt">
          Congratulations on successfully completing the Mock test for{" "}
          <b>{test_name}</b>
        </p>
        <p className="eahdTxt">Your Valuable Performance</p>
        <p className="eaParaTxt">
          Our Experts at The study falcon, after reviewing your performance in
          this mock test, say that there are certain topics upon which you lack
          conceptual clarity while in other topics, it is felt that there is a
          definite need for rigorous practice. We advise you to carefully go
          through your detailed topic wise performance review mentioned below
          and sincerely act upon the given directions.
          <br />
          <br />
          We believe that you have great potential and with right guidance, you
          will surely be able to reach your goal.
        </p>
        <p className="eahdTxt">Detailed Topic Wise Performance Review </p>
        <p className="eaParaTxt">
          As per your Performance, you need some conceptual clarity on the
          following topics:
        </p>
        <div className="topItmDiv">
          {topicAnalysis.map(({ topic_name }, index) => (
            <p className="topItm" key={index}>
              {topic_name}
            </p>
          ))}
        </div>
        <p className="eaParaTxt">
          For the remaining topics, we recommend that you keep practicing high
          level questions to attain perfection and leave no chance of losing
          even a single mark. Also, we would advise you to attempt more and more
          such mock tests and evaluate your performance at different difficulty
          levels of questions. You can find such specially designed mock tests.
        </p>
        <p
          onClick={() => naviagte("/mt", { replace: true })}
          className="eaAcBtn mbt'"
        >
          Start Mocktest
        </p>
        <p className="eaParaTxt">
          We believe that if you work whole-heartedly according to the given
          directions, your performance graph will surely witness a rise and in
          the next mock test, your performance meter will move towards green
          bar. Remember, It always seems impossible until its done !
        </p>
        <p onClick={() => naviagte("/", { replace: true })} className="eaAcBtn">
          Exit
        </p>
      </div>
    </div>
  );
}
