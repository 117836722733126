import React from "react";
import "../../Style/land.scss";
import about from "../../Assets/ui/sfabout.jpg";
import elearn from "../../Assets/ui/abt.png";
import tmImg from "../../Assets/ui/team-msg1.png";
import { TeamCrsl } from "../../Components/BoxCarousel";

export default function AboutUs() {
  return (
    <div className="homeMain">
      <div className="aboutMain">
        <img alt="The Study Falcon" src={about} className="abtImg" />
        <div className="abtDiv2">
          <label className="lrnTxt">
            <h1 className="lrnHgh">
              LEARN ANYTIME, ANYWHERE
              <br />
            </h1>
            Because The Study Falcon Believes In Universal Education.
          </label>
          <img alt="The Study Falcon" src={elearn} className="lrnImg" />
        </div>
        <div className="abtComp">
          <p className="abtTitle">About The Company</p>
          <p
            style={{
              textAlign: "justify",
            }}
          >
            The Study Falcon is one of the most ambitious products of Damaso
            Digital Solutions Pvt. Ltd. which aims to establish a platform where
            a user can find quality and meaningful study material. It is founded
            and managed by a small team of students who has prepared for enough
            of such exams to know the needs, problems and solutions of
            aspirants. Driven by zeal to deliver technology based solutions at
            affordable prices which cater to all the demands of any student, we
            aim to provide a One-Stop Solution to all the needs of exam
            aspirants.
          </p>
        </div>
        <div className="teamDiv">
          <p className="teamTitle">The Team</p>
          <TeamCrsl />
        </div>
        <div className="msgTeamDiv">
          <div className="msgDiv">
            <p className="msgTitle">Message From The Team</p>
            <p>
              The Study Falcon is one of the most ambitious products of Damaso
              Digital Solutions Pvt. Ltd. which aims to establish a platform
              where a user can find quality and meaningful study material. It is
              founded and managed by a small team of students who has prepared
              for enough of such exams to know the needs, problems and solutions
              of aspirants. Driven by zeal to deliver technology based solutions
              at affordable prices which cater to all the demands of any
              student, we aim to provide a One-Stop Solution to all the needs of
              exam aspirants.
            </p>
          </div>
          <img alt="Team Message" src={tmImg} className="tmImg" />
        </div>
      </div>
    </div>
  );
}
