import React from "react";
import { useParams } from "react-router-dom";
import "../../Style/topics.scss";
import { TopicList } from "../../Components/List";
import { usrSubs } from "../../Functions/LocalSaveData";

export default function Topics() {
  const { subId } = useParams();
  let subs = usrSubs();
  subs = subs.filter((i) => String(i.subject_id) === String(subId))[0];
  const { subject_name } = subs ? subs : [];

  return (
    <div className="HomeD topicMain">
      <p className="subName">{subject_name}</p>
      <TopicList subId={subId} />
    </div>
  );
}
