import React from "react";
import "../../Style/land.scss";
import crsImg from "../../Assets/ui/crs.png";
import { CourseCrsl } from "../../Components/BoxCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { SideCatList } from "../../Components/List";

export default function Courses() {
  const [srchKey, setSrchKey] = useState("");
  return (
    <div className="homeMain">
      <div className="courseMain">
        <div className="xplorDiv">
          <h1 className="xplorTxt">EXPLORE COURSES</h1>
          <div className="xplor">
            <img className="xplorImg" alt="Explore Courses" src={crsImg} />
          </div>
        </div>
        <div className="prevDiv">
          <div className="prevCatDiv">
            <SideCatList keyword={srchKey} />
          </div>
          <div className="prevCrslDiv">
            <div className="Search">
              <input
                type="text"
                placeholder="Search"
                className="searchInput"
                value={srchKey}
                onChange={(val) => setSrchKey(val.target.value)}
              />
              {srchKey.trim().length === 0 ? null : (
                <FontAwesomeIcon
                  onClick={() => setSrchKey("")}
                  icon={faClose}
                  className="searchIcn"
                />
              )}
            </div>
            <CourseCrsl />
          </div>
        </div>
      </div>
    </div>
  );
}
