import React from "react";
import "../../Style/AI.scss";
import deIllus from "../../Assets/new/mr_illus.png";
import { User, usrSubs } from "../../Functions/LocalSaveData";
import { dateFormat, useForceUpdate } from "../../Functions/utils";
import {
  DEOvrProgChart,
  DeProgChart,
  PracAnalysisChart,
} from "../../Components/Charts";
import { DeTpcWidget } from "../../Components/Widgets";
import { useState } from "react";
import { useEffect } from "react";
import { detailedReport } from "../../Functions/aiFunctions";
import sws from "../../Assets/ui/ws.png";
import sss from "../../Assets/ui/ss.png";
import { DeSubBox } from "../../Components/Box";
import { IOSSwitch } from "../../Components/Switch";
import { DeAnalysisList, PracAnalysisList } from "../../Components/List";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import color from "../../Style/colorPalette";
import { useRef } from "react";
import { pracAnalysis } from "../../Functions/pracTestFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { DlMdl } from "../../Components/Modal";

export default function DEReport() {
  const { name, course_name, exam_date } = User();
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [uDt, setUdt] = useState(null);
  const [subId, setSubId] = useState(usrSubs()[0].subject_id);
  const [pracData, setPracData] = useState([]);
  const render = useRef(true);
  const { prelim_date } = User();
  const navigate = useNavigate();
  const [dl, setDl] = useState(true);
  const force = useForceUpdate();

  useEffect(() => {
    const load = async () => {
      setData(await detailedReport());
      setPracData(await pracAnalysis());
      setDl(false);
      force();
    };
    if (render.current) {
      render.current = false;
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { AlTopics, timeSpent } = data;
  const { subWise, tpcWise } = pracData;

  let pracTask = AlTopics
    ? AlTopics.filter((i) => i.test_type === "practice")
    : [];

  return (
    <div className="AiMain">
      <DlMdl v={dl} />
      <p className="plrTxt">Detailed Evaluation Analysis</p>
      <div className="deAnalysisMain">
        <div className="usrInfoD">
          <div className="infD">
            <p className="usrInfo">
              User: &nbsp;<span className="infoA">{name}</span>
            </p>
            <p className="usrInfo">
              Exam Name: &nbsp;<span className="infoA">{course_name}</span>
            </p>
            <p className="usrInfo">
              Examination Date: &nbsp;
              <span className="infoA">
                {exam_date === null ? "" : dateFormat(exam_date)}
              </span>
            </p>
          </div>
        </div>
        <div className="deImgDiv">
          <img className="deImg" src={deIllus} alt="Detail Evaluation Report" />
        </div>
        <DeSubBox title={"Strong Subjects"} type={"st"} img={sss} />
        <DeSubBox title={"Weak Subjects"} type={"wt"} img={sws} />
        <div className="deProgDiv">
          <DEOvrProgChart />
          <h3>
            <b>Course Progress</b>
          </h3>
        </div>
        <DeTpcWidget tpcData={AlTopics} />

        <div className="MonRepDiv">
          <p
            className="plrTxt"
            style={{ boxShadow: "0 0 10px " + color.pink2 }}
          >
            Monthly Analysis
          </p>
          <div className="DeDtInputDiv">
            <p className="deInptLbl">For "Month-Year"</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                minDate={dayjs(new Date(prelim_date))}
                maxDate={dayjs(new Date())}
                className="date"
                views={["month", "year"]}
                value={uDt === null ? uDt : dayjs(uDt)}
                onChange={(val) => {
                  let { $M, $y } = val;
                  $M =
                    String($M + 1).length !== 2
                      ? String($M + 1).padStart(2, "0")
                      : $M + 1;
                  setUdt(`${$y}-${$M}`);
                  detailedReport(`${$y}-${$M}`).then((val) =>
                    setData(val === undefined ? [] : val)
                  );
                }}
              />
            </LocalizationProvider>
          </div>
          <p className="monTiSp">
            Total Time Practiced: &nbsp;
            <b>{timeSpent === null ? "00:00:00" : timeSpent}</b>
          </p>
          <div className="gradDiv">
            <p className="grad">
              Task Given <br />
              {uDt
                ? AlTopics.filter((i) =>
                    String(i.given_at).includes(String(uDt))
                  ).length
                : AlTopics
                ? AlTopics.length
                : 0}
            </p>
            <p className="grad">
              Task Skipped <br />{" "}
              {uDt
                ? AlTopics.filter(
                    (i) =>
                      String(i.given_at).includes(String(uDt)) &&
                      String(i.skip).length > 1
                  ).length
                : AlTopics
                ? AlTopics.filter((i) => String(i.skip).length > 1).length
                : 0}
            </p>
            <p className="grad">
              Task Completed <br />{" "}
              {uDt
                ? AlTopics.filter(
                    (i) =>
                      String(i.given_at).includes(String(uDt)) &&
                      String(i.skip).length === 0 &&
                      String(i.status).length > 1
                  ).length
                : AlTopics
                ? AlTopics.filter(
                    (i) =>
                      String(i.skip).length === 0 && String(i.status).length > 1
                  ).length
                : 0}
            </p>
          </div>
        </div>

        <div className="HomeD tpcProgMain" style={{ marginTop: 20 }}>
          <p
            className="plrTxt"
            style={{ boxShadow: "0 0 10px " + color.pink2 }}
          >
            Practice Test Analysis
          </p>
          <PracAnalysisChart data={subWise} />
          <PracAnalysisList data={subWise} tpData={tpcWise} />
        </div>

        <div className="deTbl">
          <p
            className="plrTxt"
            style={{
              boxShadow: "0 0 10px " + color.pink2,
              marginBlock: "0px 20px",
            }}
          >
            Topic Analysis
          </p>
          <div className="tblSubDiv">
            {usrSubs().map(({ subject_name, subject_id }, index) => (
              <p
                className={
                  "tblSubName " + (subject_id === subId ? "altSub" : "")
                }
                key={index}
                onClick={() => setSubId(subject_id)}
              >
                {subject_name}
              </p>
            ))}
          </div>
          <div className="OaHddiv">
            <p className="OaHdTxt">Table </p>
            <IOSSwitch
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <p className="OaHdTxt"> Graph</p>
          </div>
          {checked ? (
            <DeProgChart
              data={
                AlTopics
                  ? pracTask.filter(
                      (i) => String(i.subject_id) === String(subId)
                    )
                  : []
              }
            />
          ) : (
            <DeAnalysisList
              data={
                AlTopics
                  ? pracTask.filter(
                      (i) => String(i.subject_id) === String(subId)
                    )
                  : []
              }
            />
          )}
        </div>
        <p className="mockAn" onClick={() => navigate(`/mt/mp`)}>
          <FontAwesomeIcon icon={faHandPointRight} />
          &nbsp; Get MockTest Analysis
        </p>
      </div>
    </div>
  );
}
