const color = {
  darkGreen: "#096069",

  darkGreen2: "#108c98",

  green: "#17a2b7",

  lightGreen2: "#65bfbf",

  lightGreen: "#d9edf6",

  pink: "#f66b8a",

  pink2: "#f389aa",

  lightPink: "#fcedf0",

  palWhite: "#f4f1ea",

  white: "#ffffff",

  black: "#000",

  darkBlue: "#00577c",
};

export default color;
