import React, { useState } from "react";
import tsfLogo from "../../../Assets/logos.png";
import { crsMock } from "../../../Functions/LocalSaveData";
import { MockQues } from "../../../Components/Ques";
import { QuesSideBar } from "../../../Components/Widgets";
import { Drawer } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { mockRep } from "../../../Functions/LocalSaveData";
import { useNavigate, useParams } from "react-router-dom";
import "../../../Style/mockTest.scss";

export default function ExaminMock() {
  const { qNum } = useParams();
  const { test_name, mockSections } = crsMock();
  let { mockResult, mockQues } = mockRep();
  const { time_taken } = mockResult;
  const [msidVisible, setMsideVisible] = useState(false);
  const [quesNum, setQuesNum] = useState(parseFloat(qNum));
  const [user_answer, setUser_answer] = useState(mockQues[0].user_answer);
  const navigate = useNavigate();

  mockQues.map(
    (i) =>
      (i.status =
        i.user_answer === null
          ? "MRN"
          : i.answer === i.user_answer
          ? "A"
          : "NA")
  );

  const handleSectionChange = (section) => {
    let secques = mockQues.filter(
      (item) => String(item.section_id) === String(section.section_id)
    );
    setQuesNum(
      mockQues.findIndex((ques) => String(ques.id) === String(secques[0].id))
    );
  };

  const handleNext = () => {
    if (mockQues.length === quesNum + 1) {
      alert("Examine Completed");
    } else {
      setQuesNum(quesNum + 1);
      setUser_answer(mockQues[quesNum + 1].user_answer);
    }
  };

  return (
    <div className="HomeD mockExam">
      <div className="mockNav">
        <img className="navImg" alt="TheStudyFalcon" src={tsfLogo} />
        <p className="navCrs">{test_name}</p>
        <FontAwesomeIcon
          className="moDrawerIcn"
          onClick={() => setMsideVisible(true)}
          icon={faBarsStaggered}
        />
        <p className="navCrs td">Time Taken: {time_taken}</p>
      </div>
      <div className="mockDiv">
        <MockQues
          SubmitFunc={() => navigate("/mt")}
          is_examine={true}
          sections={mockSections === undefined ? [] : mockSections}
          ques={mockQues}
          quesNum={quesNum}
          handleSection={(val) => handleSectionChange(val)}
          handleNext={(val) => handleNext(val)}
          setUsrAns={(val) => setUser_answer(val)}
          usrAns={user_answer}
        />
        <div className="sideDiv">
          <QuesSideBar
            is_examine={true}
            ques={mockQues}
            setQuesNum={(val) => setQuesNum(val)}
            setUsrAns={(val) => setUser_answer(val)}
          />
        </div>
        <Drawer
          anchor={"right"}
          open={msidVisible}
          onClose={() => setMsideVisible()}
        >
          <div
            style={{
              width: 300,
            }}
          >
            <QuesSideBar
              is_examine={true}
              ques={mockQues}
              setQuesNum={(val) => setQuesNum(val)}
              setUsrAns={(val) => setUser_answer(val)}
            />
          </div>
        </Drawer>
      </div>
    </div>
  );
}
