import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";

export const pracExam = async (tpcId) => {
  const { token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.PRACEXAM,
        { tpcId, usrId: id },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        const { status } = res.data;
        if (status === 1) {
          return res.data;
        } else {
          return [];
        }
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const pracSave = async (quesData, cmd, navigate) => {
  let data = [];
  const { token } = User();
  const { topic_id } = quesData;
  if (token) {
    await axios
      .post(
        Server.PRACSAVE,
        { quesData, cmd },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        console.log(res.data);
        const { status } = res.data;
        if (status === 1) {
          data = res.data;
        } else {
          const { test_id } = res.data;
          navigate(`/tpc/rpt/${topic_id}/${test_id}`, { replace: true });
        }
      });
    return data;
  } else {
    return [];
  }
};

export const pracReport = async (topic_id, test_id) => {
  let data = [];
  const { token, id } = User();
  if (token) {
    await axios
      .post(
        Server.PRACREPORT,
        { user_id: id, topic_id, test_id },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        console.log(res.data);
        data = res.data;
      });
    return data;
  } else {
    return [];
  }
};

export const pracAnalysis = async () => {
  let data = [];
  const { token, id, last_selected_course } = User();
  if (token) {
    await axios
      .post(
        Server.PRTSTPROG,
        { usrId: id, crsId: last_selected_course },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        console.log(res.data);
        data = res.data;
      });
    return data;
  } else {
    return [];
  }
};

export const quesReport = async (data) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(Server.QUESRPT, data, {
        headers: { authorization: "Bearer " + token },
      })
      .then((res) => {
        return res.data;
      });
  } else {
    return [];
  }
};
