import axios from "axios";
import Server from "../JsonServer/Server";

export const getAllCat = async () =>
  await axios
    .get(Server.LAND_ALL_CAT)
    .then((r) => r.data)
    .catch((err) => console.log(err));

export const getAllExams = async () =>
  await axios
    .get(Server.LAND_ALL_EXAMS)
    .then((r) => localStorage.setItem("exmUpdts", JSON.stringify(r.data.exams)))
    .catch((err) => console.log(err));

export const getAllEbooks = async () =>
  await axios
    .get(Server.LAND_ALL_EBOOK)
    .then((r) => r.data)
    .catch((err) => console.log(err));

export const getNewExams = async () =>
  await axios
    .get(Server.NEW_EU)
    .then((r) => r.data)
    .catch((err) => console.log(err));
