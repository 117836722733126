import React, { useEffect, useState } from "react";
import { cmdEbook, getEbook } from "../../Functions/ebookFunctions";
import color from "../../Style/colorPalette";
import { EbPdf, ShareModal, LoginModal, DlMdl } from "../../Components/Modal";
import Server from "../../JsonServer/Server";
import { Backdrop, Modal } from "@mui/material";
import { isUsrEbRd, isUsrEbShelf } from "../../Functions/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { User } from "../../Functions/LocalSaveData";
import { useNavigate, useParams } from "react-router-dom";
import "../../Style/Modal.scss";
import WOC from "../../Assets/ui/WOC.jpg";

export default function EbookInfoM() {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(true);
  const [v, setV] = useState(false);
  const isUSr = User().id;
  const [lgMdlVsbl, setLgMdlVsbl] = useState(false);
  const [shV, setShV] = useState(false);
  const { ebId } = useParams();
  const navigate = useNavigate();
  const [dl, setDl] = useState(true);

  const handleCmd = () => cmdEbook(id, "bs", isUsrEbShelf(id) ? "rem" : "add");

  useEffect(() => {
    const load = async () => setData(await getEbook(ebId), setDl(false));
    load();
  }, [ebId]);

  const {
    id,
    title,
    image_path,
    author,
    year_of_publication,
    publisher,
    subject,
    genre,
    top_read,
    keywords,
    ebook_pdf,
  } = data;

  return (
    <Modal
      open={visible}
      onClose={() => {
        setVisible();
        navigate(`/eb`, { replace: true });
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <DlMdl v={dl} />
        <LoginModal
          visible={lgMdlVsbl}
          setVisible={() => setLgMdlVsbl(!lgMdlVsbl)}
        />
        <ShareModal
          v={shV}
          setV={() => setShV(false)}
          ebId={id}
          shareUrl={`${Server.WEB_URL}eb/m/${ebId}`}
          title={"Share this Ebook with your friends"}
        />
        <EbPdf
          ebId={id}
          pdf={ebook_pdf}
          visible={v}
          setVisible={() => setV(!v)}
        />
        <div className="ebInfoModal">
          <div className="ebImgDiv">
            <img
              src={
                String(image_path).includes("WOC")
                  ? WOC
                  : Server.EbTHUMB + image_path
              }
              alt={title}
              className="ebImg"
            />
          </div>
          <div className="ebInfoDiv">
            <p className="ebName">{title}</p>
            <p className="ebAuthor">By {author}</p>
            {top_read === 0 ? null : (
              <p className="ebViews">{top_read} people read this book </p>
            )}
            <p className="ebPub">
              <span style={{ color: color.lightPink }}>Publisher:</span> &nbsp;
              {publisher}
            </p>
            <p className="ebPubYear">
              <span style={{ color: color.lightPink }}>Publish Year:</span>
              &nbsp;{year_of_publication}
            </p>
            <p className="ebSub">
              <span style={{ color: color.lightPink }}>Subject:</span> &nbsp;
              {subject}
            </p>
            <p className="ebGnr">
              <span style={{ color: color.lightPink }}>Genre:</span> &nbsp;
              {genre}
            </p>
            <p className="ebKeywrd">
              <span style={{ color: color.lightPink }}>Keywords:</span> &nbsp;
              {keywords}
            </p>
            <div className="ebBtnDiv">
              <p
                onClick={() => (isUSr ? setV(true) : setLgMdlVsbl(true))}
                className="ebRead"
              >
                {isUsrEbRd(id) ? "Resume" : "Read"}
              </p>
              <p
                className="ebAtb"
                onClick={() => (isUSr ? handleCmd() : setLgMdlVsbl(true))}
              >
                {isUsrEbShelf(id) ? "Remove from Shelf" : "Add to Shelf"}
              </p>
              <FontAwesomeIcon
                onClick={() => setShV(true)}
                className="ebShare"
                icon={faShareAlt}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
