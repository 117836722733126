import React, { useEffect, useState } from "react";
import contactImg from "../../Assets/ui/contact-us-hero.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMailBulk,
  faMapPin,
  faPhone,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { randomString } from "../../Functions/utils";
import { User } from "../../Functions/LocalSaveData";
import { usrSprtMail } from "../../Functions/homeFunctions";

export default function ContactUs() {
  const [captcha, setCaptcha] = useState(randomString(6));
  const { name, email_id, mobile_no } = User();
  const [un, setUn] = useState(name);
  const [ue, setUe] = useState(email_id);
  const [um, setUm] = useState(mobile_no);
  const [sub, setSub] = useState("");
  const [mes, setMes] = useState("");
  const [cap, setCap] = useState("");
  const [sec, setSec] = useState(0);

  const clearAll = () => {
    const { name, email_id, mobile_no } = User();
    setUn(name);
    setUe(email_id);
    setUm(mobile_no);
    setSub("");
    setMes("");
    setCaptcha(randomString(6));
    setCap("");
  };

  const handleSubmit = () => {
    const usrData = {
      un,
      ue,
      um,
      sub,
      mes,
    };
    String(un === undefined ? "" : un).length === 0 ||
    String(ue === undefined ? "" : ue).length === 0 ||
    String(um === undefined ? "" : um).length === 0 ||
    String(sub === undefined ? "" : sub).length === 0 ||
    String(mes === undefined ? "" : mes).length === 0
      ? alert("Kindly fill all the fields")
      : captcha.trim() !== cap.trim()
      ? setCaptcha(randomString(6), setCap("", alert("Invalid Captcha")))
      : usrSprtMail(usrData).then(() => clearAll());
  };

  useEffect(() => {
    const i = setInterval(() => {
      setCaptcha(randomString(6));
      setSec(20);
    }, sec * 1000);
    return () => clearInterval(i);
  }, [sec]);

  useEffect(() => {
    const i = setInterval(() => {
      setSec(sec - 1 <= 0 ? 0 : sec - 1);
    }, 1000);
    return () => clearInterval(i);
  }, [sec]);

  return (
    <div className="homeMain">
      <div className="contactMain">
        <div className="contextDiv">
          <div className="contxt">
            <p className="contxt1">
              YOUR BRAIN IS ALREADY FIGHTING WITH SO MANY QUESTIONS;
            </p>
            <p className="contxt2">WHY TO ADD ONE MORE!</p>
            <br /> <label className="contxt1">HAVE A DOUBT? </label>
            <br />
            <label className="contxt3">Ask It Below...</label>
          </div>
          <img className="contxtImg" alt="Contact Us" src={contactImg} />
        </div>
        <div className="cntctDiv">
          <div className="formDiv">
            <input
              placeholder="Name"
              type="text"
              className="formInput"
              value={un}
              onChange={(val) => setUn(val.target.value)}
            />
            <input
              placeholder="Email"
              type="text"
              className="formInput"
              value={ue}
              onChange={(val) => setUe(val.target.value)}
            />
            <input
              placeholder="10 Digits mobile no."
              type="text"
              className="formInput"
              value={um}
              onChange={(val) => setUm(val.target.value)}
            />
            <input
              placeholder="Subject"
              type="text"
              className="formInput"
              value={sub}
              onChange={(val) => setSub(val.target.value)}
            />
            <textarea
              rows={6}
              placeholder="Your Message"
              className="formInput"
              value={mes}
              onChange={(val) => setMes(val.target.value)}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label className="captcha">{captcha}</label>
              <input
                placeholder="Enter Captcha"
                type="text"
                className="formCaptcha"
                value={cap}
                onChange={(val) => setCap(val.target.value)}
              />
              <FontAwesomeIcon
                className="rfCaptcha"
                icon={faRefresh}
                onClick={() => setSec(20, setCaptcha(randomString(6)))}
              />
              <label className="captcha sec">
                {(String(sec).length === 1 ? "0" : "") + sec}
              </label>
            </div>
            <div className="submit">
              <p className="subTxt">
                LET'S GET THE DOUBT OUT OF YOUR SYSTEM!
              </p>
              <input
                className="submitBtn"
                type="button"
                value={"Submit"}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
          <div className="reachDiv">
            <label className="reachTxt">Reach Us At</label>
            <br />
            <label className="reach">
              <FontAwesomeIcon className="reachIcn" icon={faMapPin} /> &nbsp;
              F-25/99, Sector - 7, Rohini, New Delhi - 110085
            </label>
            <p className="reach">
              <FontAwesomeIcon className="reachIcn" icon={faPhone} /> &nbsp; +91
              011 2704 0783
            </p>
            <label className="reach">
              <FontAwesomeIcon className="reachIcn" icon={faMailBulk} /> &nbsp;
              support@thestudyfalcon.com
            </label>
          </div>
        </div>
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              title="The Study Falcon"
              className="gmap_iframe"
              width="100%"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d367.83077730263796!2d77.11008450077327!3d28.707116701991612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d018fcdd31c27%3A0x313c002b3335c7bc!2sDamaso%20Digital%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1710403361237!5m2!1sen!2sin"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
