import React from "react";

export default function TnC() {
  return (
    <div style={{ marginTop: "8vh", padding: "2%" }}>
      <h2>
        <center>
          <strong>TERMS OF USE</strong>
        </center>
      </h2>
      <p>
        The present Terms of Use document is being published in accordance with
        the provisions of the Information Technology Act, 2000 and other
        applicable Rules there under, including but not limited to the
        Information Technology (Intermediary Guidelines) Rules, 2011.
      </p>
      <p>
        The following terms and conditions govern your use of the thestudyfalcon
        and its content as published on thestudyfalcon Website. By using the
        Site and/or any information, content or materials made available on the
        Website, you irrevocably agree that such use is subject to these Terms
        of Use. If you do not agree to these Terms of Use, you may not use the
        Site. The Website and the App are owned and provided to you by{" "}
        <strong>Damaso Digital Solutions Pvt. Ltd</strong>., having its office
        at F-25/99, 1st Floor, Sector-7, Rohini, New Delhi, India-110085. The
        Website and the App shall hereinafter be collectively referred to as{" "}
        <strong>&quot;the Platform&quot;</strong>. For the purposes of this
        Terms of Use, accessing of the Platform together with any study material
        made available or uploaded therein or downloaded, embedded there from
        shall hereinafter be collectively be referred to as the{" "}
        <strong>&quot;Services&quot;</strong>.
      </p>
      <p>
        <strong>INTRODUCTION</strong>
      </p>
      <p>
        By using or accessing the Platform in any way, including registering on
        the Platform, using, viewing, sharing, embedding and/or downloading any
        Services, you agree to be bound by the Terms of Use set forth herein. By
        accepting these Terms of Use, you also accept and agree to be bound by
        thestudyfalcon Policies, including but not limited to the thestudyfalcon
        Privacy Policy and the thestudyfalcon Copyright Policy.
      </p>
      <p>
        If you do not understand the present Terms of Use or do not agree
        with/accept any part thereof, you should immediately discontinue using
        or accessing the Platform. Your use and/or continued use of the
        Platform, as the case may be, amount to express consent by you to the
        terms of this Terms of Use as well as other thestudyfalcon Policies.
      </p>
      <p>
        <strong>ELIGIBILITY</strong>
      </p>
      <p>
        By agreeing to these Terms of Use, you represent that you are legally
        competent to enter into an agreement and provide consent to these Terms
        of Use. You further represent that
      </p>
      <ol>
        <li>You are of sound mind.</li>
        <li>
          By agreeing to these Terms of Use, you represent that you are legally
          competent to enter into an agreement and provide consent to these
          Terms of Use. You further represent that
        </li>
        <li>
          Are not prohibited from entering into a legally binding contract as
          per applicable laws.
        </li>
      </ol>
      <p>
        In case the Platform is being accessed, and the Services are being
        availed for the benefit of a minor, you expressly confirm that you are
        legally competent to provide consent on behalf of such a minor and that
        the minor&rsquo;s use of the Platform and/or the Services shall be
        subject to the Terms of Use.
      </p>
      <p>
        <strong>YOUR ACCOUNT</strong>
      </p>
      <p>
        You can become a registered user of the Platform by setting up a
        password-protected account with us. In order to set up such an account,
        you will be required to enter the details of your registered email id
        and password or you may login using the account details of any partner
        website. By setting up an account, you agree to accept any and all
        responsibility for any and all activities that occur under your account.
        By setting up the account, you further agree to the contents of the
        Privacy Policy.
      </p>
      <p>
        thestudyfalcon reserves the right and sole discretion to refuse access
        to the Platform, terminate any account, remove or restrict any content,
        at any time, with or without notice to you in case we notice any illegal
        activity on or from account or if we have reason to believe that any
        information provided by you is untrue, inaccurate, outdated or
        incomplete.
      </p>
      <p>
        <strong>USER CONTENT</strong>
      </p>
      <p>
        thestudyfalcon provides its users with the option of creating posts
        and/or upload content on the Platform{" "}
        <strong>(&quot;User Content&quot;)</strong>. When you submit or upload
        any content on the Platform you represent and warrant that:
      </p>
      <ul>
        <li>
          You own all copyright in the content, or if you are not the owner,
          that you have permission to use the content, and that you have all the
          rights and permissions necessary to permit uploading, displaying,
          sharing, reproduction, downloading, the transmission of such content.
        </li>
        <li>
          The content you upload will not infringe the intellectual property
          rights or other rights of any person or entity, including but not
          limited to copyright, moral rights, trademark, patent or rights of
          privacy or publicity.
        </li>
        <li>
          Your use of the Platform will comply with all applicable laws, rules
          and regulations.
        </li>
        <li>
          The content does not contain material that defames or vilifies any
          person, people, races, religion or religious group and is not obscene,
          pornographic, indecent, harassing, threatening, harmful, and invasive
          of privacy or publicity rights, abusive, inflammatory or otherwise
          objectionable.
        </li>
        <li>
          The content is not misleading and deceptive and does not offer or
          disseminating fraudulent goods, services, schemes, or promotions.
        </li>
      </ul>
      <p>
        thestudyfalcon reserves the right to review such content uploaded by the
        user and in case any content is found to be a violation of any of the
        Terms of Use and/or any applicable laws, thestudyfalcon may remove any
        content from the Platform and/or terminate or suspend the user account
        in its sole discretion.
      </p>
      <p>
        <strong>RESTRICTION AND TERMINATION OF USE</strong>
      </p>
      <p>
        thestudyfalcon may block, restrict, disable, suspend or terminate your
        access to all or part of the free Services offered on the Platform, at
        any time in thestudyfalcon&#39;s sole discretion, without prior notice
        to you upon thestudyfalcon. Specifically, in case of prolonged
        inactivity, thestudyfalcon reserves the right to disable, deactivate or
        terminate a user&#39;s account. If an account has been disabled or
        deactivated for inactivity, the user name associated with that account
        may be given to another user without notice to you or such other party.
        If you violate the Terms of Use, thestudyfalcon may at its sole
        discretion, block, restrict, disable, suspend or terminate the paid
        Services offered on the Platform.
      </p>
      <p>
        <strong>INTELLECTUAL PROPERTY</strong>
      </p>
      <p>
        The Services, including but not limited to digital content on the
        website i.e. text, graphics, user interface, images, video interface and
        software as well as their selection and arrangement, may belong to
        thestudyfalcon or to its partners who have granted thestudyfalcon the
        right to use their content and are protected to the fullest extent
        possible by applicable laws related to copyrights, trademarks, trade
        secrets and all other intellectual property and proprietary rights
        (collectively, <strong>&quot;Intellectual Property Rights&quot;</strong>{" "}
        ). Any unauthorized use of the Services may violate such laws and the
        Terms of Use. thestudyfalcon reserves all its legal rights to prohibit,
        stop or contain any such violations.
      </p>
      <p>
        You agree not to copy, republish, frame, download, transmit, modify,
        adapt, create derivative works based on, rent, lease, loan, sell,
        assign, distribute, display, perform, license, sublicense or reverse
        engineer the Platform and Services or any parts thereof or the selection
        and arrangement of the Platform and Services, except as expressly
        authorized herein. thestudyfalcon grants you a non-exclusive,
        non-transferable, limited permission to access and display the web pages
        within this Platform, solely on your computer or any other electronic
        viewing device for your personal, non-commercial use of this Platform.
        This permission is conditional on the basis that you shall not modify,
        alter or illegally use the content displayed on this Platform and shall
        keep intact and comply with all copyright, trademark, and other
        proprietary notices of the Platform, if any. The rights granted to you
        constitute a license and not a transfer of title in any manner.
      </p>
      <p>
        Use of the Platform or Services for any other purpose other than
        expressly granted hereunder is expressly prohibited and may result in
        severe civil and criminal penalties.
      </p>
      <p>
        In relation to any content submitted, uploaded or generated by you on
        the Platform, you hereby grant an unconditional, unlimited, perpetual,
        royalty-free, worldwide license and permission to thestudyfalcon and its
        affiliates, content sharing partners to display, store, host, publish,
        digitally reproduce or copy, transmit, communicate, modify, translate,
        distribute or otherwise make such content available on the Platform or
        in relation to the Services provided by thestudyfalcon. You agree that
        such content may continue to be made available on thestudyfalcon despite
        any termination or suspension of your account, if such content has been
        shared, stored or embedded by any other user(s). You agree not to hold
        thestudyfalcon responsible for the aforesaid activities in relation to
        your content.
      </p>
      <p>
        <strong>ADVERTISEMENTS AND PROMOTIONS</strong>
      </p>
      <p>
        This Platform may run advertisements and promotions from third parties,
        products and/or services in any manner and to any extent. Your
        communications, activities, relationships and business dealings with any
        third parties advertising or promoting via the Platform, including
        payment and delivery of related goods or services, and any other terms,
        conditions, warranties or representations associated with such dealings,
        shall be solely matters between you and such third parties.
      </p>
      <p>
        You acknowledge and agree that thestudyfalcon is not responsible or
        liable for any loss or damage of any kind incurred as a result of any
        such dealings or as the result of the presence of such
        non-thestudyfalcon advertisers on the Platform.
      </p>
      <p>
        Our links with advertisers should not be construed as an endorsement,
        approval or recommendation by us of the owners or operators of those
        linked websites, or of any information, graphics, materials, products or
        services referred to or contained on those linked websites, unless and
        to the extent stipulated to the contrary.
      </p>
      <p>
        <strong>LINKS TO THIRD PARTY WEBSITES</strong>
      </p>
      <p>
        The Platform may include links that redirect you to other websites. The
        Terms of Use do not cover these third party websites. You agree that
        once you leave our servers, any third party websites that you go to or
        interact with are at your own risk. thestudyfalcon shall not be held
        liable for any default, loss of function or any risk or consequence that
        you may be exposed to as a result of the same.
      </p>
      <p>
        <strong>SPAM E-MAIL AND POSTINGS</strong>
      </p>
      <p>
        In the event your access or use of the Platform and Services is found to
        be spammy, misleading, malicious, annoying or containing/promoting
        unsolicited e-mails or network postings, thestudyfalcon reserves its
        right to obtain immediate injunctive relief against you or against such
        use by you, in addition to all other remedies available at law or in
        equity. thestudyfalcon may also opt to block, filter or delete
        unsolicited emails, messages or postings as per its sole discretion.
      </p>
      <p>
        <strong>INDEMNITY</strong>
      </p>
      <p>
        You agree to indemnify, defend and hold thestudyfalcon and its officers,
        directors, partners, employees, agents and representatives harmless from
        and against any and all claims, damages, losses, liabilities, costs
        (including reasonable legal fees) or other expenses that arise directly
        or indirectly out of or from
      </p>
      <ul>
        <li>
          Your user content and any other content (e.g. computer viruses) that
          you may submit, post to or transmit through the Platform (including a
          third party&#39;s use of such user content (e.g. reliance on the
          accuracy, completeness or usefulness of your user content).
        </li>
        <li>
          Your access to or use of the Platform and Services (including any use
          by your employees, contractors or agents and all uses of your account
          numbers, usernames and passwords, whether or not actually or expressly
          authorized by you, in connection with the Platform and Services).
        </li>
        <li>Your breach of any clause of the &lsquo;Terms of Use&rsquo;.</li>
        <li>
          Any allegation that while using any of the software made available on
          the Platform you infringe or otherwise violate the copyright,
          trademark, trade secret or other intellectual property or other rights
          of any third party and/or any dealings between you and any third
          parties advertising or promoting or linked via the Platform.
        </li>
        <li>Your activities in connection with the Platform.</li>
      </ul>
      <p>
        This indemnity will be applicable without regard to the negligence of
        any party, including any indemnified person.
      </p>
      <p>
        <strong>DISCLAIMER</strong>
      </p>
      <p>
        We do not represent or guarantee that this Platform will be free from
        errors or viruses. You acknowledge that this Website may be affected by
        outages, faults or delays. Such outages, faults or delays may be caused
        by factors, including technical difficulties with the performance or
        operation of our or another person&rsquo;s software, equipment or
        systems, traffic or technical problems with the Internet or
        infrastructure failures.
      </p>
      <p>
        We do not accept responsibility for any loss or damage, however caused
        (including through negligence), that you may directly or indirectly
        suffer in connection with your use of this Platform, nor do we accept
        any responsibility for any such loss arising out of your use of or
        reliance on information contained on or accessed through this Platform.
      </p>
      <p>
        <strong>PRIVACY POLICY</strong>
      </p>
      <p>
        Your privacy is important to us. Users of our Platform should refer to
        our Privacy Policy, which is incorporated into this Terms of Use by
        reference, with respect to the disclosure, collection, storage, usage
        and protection of your information during the course of your interaction
        with the Platform.
      </p>
      <p>
        <strong>COPYRIGHT POLICY</strong>
      </p>
      <p>
        thestudyfalcon respects and recognize the intellectual property rights
        of others and expects users of thestudyfalcon to do the same. Users of
        our Platform should refer to our Copyright Policy, which is incorporated
        into this Terms of Use by reference. If you believe that your content
        has been used on thestudyfalcon in a way that constitutes an
        infringement of your copyright, please notify thestudyfalcon&rsquo;s
        designated agent for complaints or approach us through the procedure set
        out under the applicable provisions of the Information Technology Act.
        If you have a good faith belief that any of our users has infringed your
        copyright, you may follow the process as specified in our Copyright
        Policy.
      </p>
      <p>
        <strong>CANCELLATIONS AND REFUND POLICY</strong>
      </p>
      <p>
        Cancellations: As a general rule, all sales made on thestudyfalcon are
        final and you shall not be entitled to cancel your order once you have
        received confirmation of the same. thestudyfalcon reserves the sole
        right to cancel any order as per our discretion in case (i) we are
        unable to deliver the order in a satisfactory manner and/ or (ii) the
        user tries to take advantage of the system which violates the Terms of
        Use. thestudyfalcon will ensure that any communication of cancellation
        of an order or any applicable refund will be made within a reasonable
        period of time.
      </p>
      <p>
        Refunds: You shall be entitled to a refund only if thestudyfalcon is
        unable to deliver your order. For refund requests please email to
        refund@thestudyfalcon.com for test series and
        helpdesk@thestudyfalcon.com for live classes within 1 week of the date
        of purchase. All refunds will be processed on a prorated basis,
        depending on the service already delivered by thestudyfalcon. Refunds
        will be done directly to the original payment mode within seven working
        days of finalization of the claim.
      </p>
      <p>
        <strong>AMENDMENTS TO THE TERMS AND CONDITIONS</strong>
      </p>
      <p>
        thestudyfalcon may amend the Terms of Use and other thestudyfalcon
        Policies published on the Platform from time to time at its sole
        discretion. Your continued access or use of the Platform and the
        Services constitutes your acceptance of the amendments. Your access and
        use of the Platform and Services will be subject to the most current
        version of the Terms of Use, Privacy Policy and Copyright Policy posted
        on the Platform at the time of such use. Please regularly check the
        Platform to view the latest version of thestudyfalcon Policies.
      </p>
      <p>
        <strong>APPLICABLE LAW AND JURISDICTION</strong>
      </p>
      <p>
        Terms of Use shall be governed by and construed in accordance with the
        Laws of India. In case of any dispute, the courts in Delhi will have
        exclusive jurisdiction to try any such disputes to the exclusion of all
        other courts.
      </p>
      <p>&nbsp;</p>
    </div>
  );
}
