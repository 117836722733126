import React, { useRef, useState } from "react";
import {
  faEdit,
  faFile,
  faForward,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgresBar } from "./Charts";
import "../Style/widgets.scss";
import subProgImg from "../Assets/ui/s-icon.png";
import { CircularProgress } from "@mui/joy";
import color from "../Style/colorPalette";
import { useEffect } from "react";
import { usrSubs } from "../Functions/LocalSaveData";
import {
  calAccuracy,
  dateFormat,
  useForceUpdate,
  useIsVisible,
} from "../Functions/utils";
import { User, todoList } from "../Functions/LocalSaveData";
import { useNavigate } from "react-router-dom";
import { givenTodo, todoSkip } from "../Functions/aiFunctions";
import noImage from "../Assets/ui/student2.png";
import { usrCrs } from "../Functions/courseFunction";
import { EditProfileModal } from "./Modal";
import { getUsrImg } from "../Functions/authFunctions";
import { IOSSwitch } from "./Switch";
import { NaCatSide } from "./Sidebar";

export const TodoListWidget = () => {
  const navigate = useNavigate();
  const force = useForceUpdate();
  let listData = todoList();
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    let listData = todoList();
    let todoId = [];
    if (listData.length > 0) {
      listData.map((i) => todoId.push(i.id));
    }
    givenTodo(todoId);
  }, []);

  const handleAction = (item, type) => {
    const { subject_id, topic_id } = item;
    type === "read"
      ? navigate(`/tpc/rd/${subject_id}/${topic_id}`)
      : navigate(`/tpc/pt/${subject_id}/${topic_id}/ai_prac`);
  };

  useEffect(() => {
    const int = setInterval(() => {
      if (isVisible) {
        force();
      }
    }, 1000);
    return () => clearInterval(int);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <div className="toDoMain" ref={ref}>
      <div className="todoHeadCon">
        <p className="todoHead">
          <FontAwesomeIcon className="todoIcn" icon={faListCheck} />
          &nbsp; &nbsp; To Do List
        </p>
      </div>
      <div className="lstDiv">
        {listData.map(({ topic_name, test_type, task_progress, id }, index) => (
          <div key={index} className="lstItm">
            <div className="taskD">
              <p className="taskTxt">
                {topic_name} (
                {test_type === "practice"
                  ? "Practice 35 Questions"
                  : "Read Content"}
                )
              </p>

              <ProgresBar progress={task_progress} height={25} />
            </div>
            <div className="todoBtnDiv">
              <p
                onClick={() => handleAction(listData[index], test_type)}
                className="todoBtn rd"
              >
                <FontAwesomeIcon icon={faFile} /> &nbsp;
                {test_type}
              </p>
              <p
                onClick={() =>
                  window.confirm("Are you sure!\nYou want to skip this task")
                    ? todoSkip(id)
                    : null
                }
                className="todoBtn"
              >
                <FontAwesomeIcon icon={faForward} /> &nbsp; Skip
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const SubProgWidget = () => {
  let subs = usrSubs();
  const navigate = useNavigate();
  const force = useForceUpdate();
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    const int = setInterval(() => {
      if (isVisible) {
        force();
      }
    }, 1000);
    return () => clearInterval(int);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <div className="subProgWidMain" ref={ref}>
      <div className="progHeadDiv">
        <p className="headTxt">
          Subjects
          <br /> <span className="headTxt-Alt">Your Progress</span>
        </p>
        <img
          style={{ pointerEvents: "none" }}
          alt="Subjects Progress"
          src={subProgImg}
        />
      </div>
      <div className="subListDiv">
        {subs.map(({ subject_name, subject_prepration, subject_id }, index) => (
          <div
            key={index}
            className="subItm"
            onClick={() => navigate(`/tpc/${subject_id}`)}
          >
            <p className="subTxt">{subject_name}</p>
            <CircularProgress
              sx={{
                "--CircularProgress-size": "55px",
                "--CircularProgress-progressColor": color.pink,
              }}
              determinate
              value={parseFloat(subject_prepration)}
            >
              {subject_prepration === null ? 0 : subject_prepration}%
            </CircularProgress>
          </div>
        ))}
      </div>
    </div>
  );
};

export const QuesSideBar = ({ ques, setQuesNum, setUsrAns, is_examine }) => {
  ques = ques ? ques : [];
  const [image, setImage] = useState("");

  useEffect(() => {
    const getImage = async () => {
      setImage(await getUsrImg());
    };
    getImage();
  }, []);

  const { name } = User();
  return (
    <div className="QSideM">
      <div className="usrDiv">
        <img
          className="usrImg"
          alt={name}
          src={image}
          onError={() => setImage(noImage)}
        />
        <p className="usrName">{name}</p>
      </div>
      <div className="MoptDiv">
        {is_examine ? null : (
          <>
            <span className="Mopt nv">
              {ques.filter((i) => !i.status).length}
            </span>
            &nbsp; Not Visited
            <br />
            <br />
          </>
        )}
        <span className="Mopt ans">
          {ques.filter((i) => i.status === "A").length}
        </span>
        &nbsp; {is_examine ? "Correct" : "Answered"}
        <br />
        <br />
        <span className="Mopt nAns">
          {ques.filter((i) => i.status === "NA").length}
        </span>
        &nbsp; {is_examine ? "Wrong" : "Not Answered"}
        <br />
        <br />
        <span className="Mopt nar">
          {ques.filter((i) => i.status === "MRN").length}
        </span>
        &nbsp; {is_examine ? "Unattempted" : "Marked for Review"}
        <br />
        <br />
        {is_examine ? null : (
          <>
            <span className="Mopt ar">
              {ques.filter((i) => i.status === "AMRN").length}
            </span>
            &nbsp; Answered and Marked for Review
          </>
        )}
        <div className="quesIndexDiv">
          <p className="quesStTxt">You are Viewing and Status</p>
          <div className="quesIndexLst">
            {ques.map((i, index) => (
              <span
                onClick={() =>
                  setUsrAns(
                    i.user_answer === undefined ? "" : i.user_answer,
                    setQuesNum(index)
                  )
                }
                className={
                  "Mopt2 " +
                  (i.status === "A"
                    ? "ans"
                    : i.status === "NA"
                    ? "nAns"
                    : i.status === "MRN"
                    ? "nar"
                    : i.status === "AMRN"
                    ? "ar"
                    : "nv")
                }
              >
                {index + 1}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserInfo = () => {
  const {
    id,
    name,
    mobile_no,
    dob,
    city,
    email_id,
    education,
    subscription_name,
    validity_dt,
    payment_status,
  } = User();

  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState("");

  useEffect(() => {
    const getImage = async () => {
      setImage(await getUsrImg());
    };
    getImage();
  }, []);

  return (
    <div className="prWidMain">
      <EditProfileModal
        visible={visible}
        setVisible={() => setVisible(!visible)}
      />
      <img
        className="prImg"
        src={image}
        alt={name}
        onError={() => setImage(noImage)}
      />
      <p className="uName">{name}</p>
      <p className="uId">User ID:&nbsp;{id}</p>

      <div className="prRow">
        <p className="prHd">Mobile: </p>
        <p className="prD">{mobile_no} </p>
      </div>

      <div className="prRow">
        <p className="prHd">Email: </p>
        <p className="prD">{email_id} </p>
      </div>

      <div className="prRow">
        <p className="prHd">Date of Birth: </p>
        <p className="prD">{dateFormat(dob)} </p>
      </div>

      <div className="prRow">
        <p className="prHd">State: </p>
        <p className="prD">{city} </p>
      </div>

      <div className="prRow">
        <p className="prHd">Education: </p>
        <p className="prD">{education} </p>
      </div>

      <div className="prRow">
        <p className="prHd">Subscription: </p>
        <p className="prD">
          {payment_status === 2
            ? `Expired on ${dateFormat(validity_dt)}`
            : subscription_name}
        </p>
      </div>

      <div
        className="prRow"
        style={{ display: payment_status === 1 ? "flex" : "none" }}
      >
        <p className="prHd">Validity: </p>
        <p className="prD">{dateFormat(validity_dt)} </p>
      </div>

      <p onClick={() => setVisible(true)} className="editBtn">
        Edit Details
      </p>
    </div>
  );
};

export const UserAct = () => {
  const { question_attempt, question_correct, video_read, ebook_read } = User();
  return (
    <div className="uActMain">
      <p className="usActHd">My Activity</p>

      <div className="actRow">
        <p className="actHd">Questions Attempted: </p>
        <p className="actD">{question_attempt} </p>
      </div>

      <div className="actRow">
        <p className="actHd">Accuracy: </p>
        <p className="actD">
          {calAccuracy(question_correct, question_attempt)}%
        </p>
      </div>

      <div className="actRow">
        <p className="actHd">Lectures Taken: </p>
        <p className="actD"> {video_read} </p>
      </div>

      {/* <div className="actRow">
        <p className="actHd">Notes Read: </p>
        <p className="actD">{"40"} </p>
      </div> */}

      <div className="actRow">
        <p className="actHd">Ebooks Read: </p>
        <p className="actD">{ebook_read} </p>
      </div>
    </div>
  );
};

export const UserCrsProg = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const render = useRef(true);
  useEffect(() => {
    if (render.current) {
      render.current = false;
      usrCrs().then((val) =>
        setData(
          val === undefined
            ? []
            : val.sort(function (a, b) {
                var aProg =
                  a.progress === null || a.progress === "" ? "0" : a.progress;
                var bProg =
                  b.progress === null || b.progress === "" ? "0" : b.progress;
                if (parseFloat(aProg) > parseFloat(bProg)) return -1;
                else return 0;
              })
        )
      );
    }
  }, []);
  return (
    <div className="uActMain">
      <div className="usActHd" style={{ paddingBlock: 10 }}>
        <p className="mCrs">My Courses</p>
        <FontAwesomeIcon
          onClick={() => navigate(`/crs/rm`)}
          className="crsIcn"
          icon={faEdit}
        />
      </div>
      <div className="actItm">
        {data.map(({ course_name, progress }, index) => (
          <div className="actRow prProgItm" key={index}>
            <p className="actHd">{course_name} </p>
            <p className="actD">
              {progress === null || progress === "" ? "0" : progress}%
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export const DeTpcWidget = ({ tpcData }) => {
  tpcData = tpcData ? tpcData : [];
  const subsShow = useRef([]);
  let subs = usrSubs();

  const tpcFilter = (index) => {
    let SS = subsShow.current;
    let subId = subs[index].subject_id;
    let fArr = tpcData.filter(
      (i) =>
        String(i.subject_id) === String(subId) &&
        String(i.sub_status) === "weak" &&
        i.topic_prepration < 50 &&
        i.test_type === "practice"
    );
    SS[index] = fArr.length === 0 ? false : true;
    return fArr;
  };

  return (
    tpcData.length > 0 && (
      <div className="atntnTpcDiv">
        <p className="tpcHead">Topics Need High Attention</p>
        <div className="tpcMain">
          {subs.map(({ subject_name }, index) => (
            <div
              className="tpItm"
              key={index}
              style={{ display: subsShow.current[index] ? "flex" : "none" }}
            >
              <p className="subName">{subject_name}</p>
              <div className="tpcDiv">
                {tpcFilter(index).map(({ topic_name }, tpInd) => (
                  <p className="tpc" key={tpInd}>
                    {topic_name}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export const QuizResult = ({ cr, inCr, unAt, setRpt }) => {
  const navigate = useNavigate();
  return (
    <div className="QzRsltDiv">
      <p className="qzHead">Result</p>
      <div className="qzPtDiv">
        <p className="qzHd">Question Attempted :</p>
        <p className="qzBd">{cr + inCr}</p>
      </div>
      <div className="qzPtDiv">
        <p className="qzHd">Question Skipped :</p>
        <p className="qzBd">{unAt}</p>
      </div>
      <div className="qzPtDiv">
        <p className="qzHd">Correct :</p>
        <p className="qzBd">{cr}</p>
      </div>
      <div className="qzPtDiv">
        <p className="qzHd">Wrong :</p>
        <p className="qzBd">{inCr}</p>
      </div>
      <div className="qzPtDiv">
        <p className="qzHd">Accuracy :</p>
        <p className="qzBd">{calAccuracy(cr, cr + inCr)}%</p>
      </div>
      <p onClick={() => setRpt()} className="rsmBtn">
        Resume Test
      </p>
      <p
        onClick={() => navigate("/ca/na/0", { replace: true })}
        className="exBtn"
      >
        Exit
      </p>
    </div>
  );
};

export const DeskNaHd = ({ check, setCheck }) => {
  return (
    <div className="caHdDiv">
      <div className="caTrnslt">
        English &nbsp;
        <IOSSwitch
          className="iosSwch"
          checked={check}
          onChange={() => setCheck()}
        />
        &nbsp; Hindi
      </div>
      <div className="caNmDiv">
        <p className="caName">Newspaper Analysis</p>
      </div>
    </div>
  );
};

export const MobNaHd = ({
  data,
  check,
  setCheck,
  catName,
  catId,
  setGnrId,
  navigate,
  title,
}) => {
  const [v, setV] = useState(false);
  return (
    <div className="mobNaHd">
      {check !== undefined && (
        <div className="mobLngD">
          English &nbsp;
          <IOSSwitch
            className="iosSwch"
            checked={check}
            onChange={() => setCheck()}
          />
          &nbsp; Hindi
        </div>
      )}
      <p className={"mobCatName " + (check === undefined ? "moEbNm" : "")}>
        {catName}
      </p>
      <FontAwesomeIcon
        icon={"ellipsis"}
        fontSize={22}
        color={color.pink}
        onClick={() => setV(true)}
      />
      <NaCatSide
        v={v}
        setV={() => setV(false)}
        data={data ? data : []}
        title={title}
        gnrId={catId}
        setGnrId={(val) => setGnrId(val)}
        navigate={(val) => navigate(val)}
      />
    </div>
  );
};
