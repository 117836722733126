import React from "react";
import "../../Style/topics.scss";
import { TpcBmLst } from "../../Components/List";

export default function TopicBM() {
  return (
    <div className="HomeD topicMain">
      <p className="subName">Bookmarked Topics </p>
      <TpcBmLst />
    </div>
  );
}
