import React from "react";
import "../../Style/home.scss";
import { RemCrsLst } from "../../Components/List";

export default function RemCourses() {
  return (
    <div className="HomeD remCrsMain">
      <p className="remHead">Remove Courses</p>
      <RemCrsLst />
    </div>
  );
}
