import React, { useState, useEffect } from "react";
import "../../Style/currentAffair.scss";
import { getInshorts } from "../../Functions/CAFunctions";
import { EbSdList } from "../../Components/List";
import { NaHeadsCsrl } from "../../Components/BoxCarousel";
import { useNavigate, useParams } from "react-router-dom";
import { DlMdl } from "../../Components/Modal";
import { enCats, hiCats } from "../../JsonServer/localServer";
import { DeskNaHd, MobNaHd } from "../../Components/Widgets";

export default function Na() {
  const [data, setData] = useState([]);
  const { nId } = useParams();
  const [catId, setCatId] = useState(nId);
  const navigate = useNavigate();
  const [dl, setDl] = useState(true);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const load = async () => setData(await getInshorts(check), setDl(false));
    load();
  }, [check]);

  return (
    <div className="HomeD caMain">
      <DlMdl v={dl} />
      <DeskNaHd check={check} setCheck={() => setCheck(!check, setDl(true))} />
      <MobNaHd
        title={"Categories"}
        data={check ? hiCats : enCats}
        check={check}
        catId={catId}
        setGnrId={(val) => setCatId(val)}
        navigate={(val) => navigate(`/ca/na/${val}`, { replace: true })}
        setCheck={() => setCheck(!check, setDl(true))}
        catName={
          (check ? hiCats : enCats).filter(
            (i) => String(i.id) === String(catId)
          )[0].name
        }
      />
      <div className="caDiv">
        <EbSdList
          type={"ca"}
          data={check ? hiCats : enCats}
          title={"Categories"}
          gnrId={catId}
          setGnrId={(val) => setCatId(val)}
          navigate={(val) => navigate(`/ca/na/${val}`, { replace: true })}
        />
        <NaHeadsCsrl
          data={data}
          catId={catId}
          nwsCats={check ? hiCats : enCats}
        />
      </div>
    </div>
  );
}
