import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { pracReport } from "../../Functions/pracTestFunctions";
import "../../Style/topics.scss";
import { tpcDetail } from "../../Functions/LocalSaveData";
import { AnalysisTable } from "../../Components/List";
import { AnalysisChart } from "../../Components/Charts";

export default function TopicPracRpt() {
  const { tpId, tstId } = useParams();
  let { topic_name } = tpcDetail(tpId);
  const [data, setData] = useState([]);
  const [rptMod, setRptMod] = useState(0);
  const render = useRef(true);

  useEffect(() => {
    if (render.current) {
      render.current = false;
      pracReport(tpId, tstId).then((val) => {
        setData(val === undefined ? [] : val);
      });
    }
  }, [tpId, tstId]);

  const { curAnalysis, ovralAnalysis, alTstSsn } = data;

  return (
    <div className="HomeD topicMain">
      <p className="subName">
        Practice Test Report
        <br /> ( {topic_name} )
      </p>
      <div className="rptModMain">
        <p
          onClick={() => setRptMod(0)}
          className={"rptMod" + (rptMod === 0 ? " alt" : "")}
        >
          Current Analysis
        </p>
        <p
          onClick={() => setRptMod(1)}
          className={"rptMod" + (rptMod === 1 ? " alt" : "")}
        >
          Overall Analysis
        </p>
      </div>
      {rptMod === 0 ? (
        <AnalysisTable data={curAnalysis} is_overAll={rptMod} />
      ) : (
        <>
          <AnalysisTable data={ovralAnalysis} is_overAll={rptMod} />
          <AnalysisChart data={alTstSsn} />
        </>
      )}
    </div>
  );
}
