import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Style/footer.scss";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";

const Footer = ({ currentPage }) => {
  const navigate = useNavigate();

  const hideFooter =
    String(currentPage).includes("me") || String(currentPage).includes("emt");
  return hideFooter ? null : (
    <div>
      <div className="footer">
        <div className="footerCon">
          <div className="footerDiv-a">
            <p className="tsf">The Study Falcon</p>
            <p className="mbD">
              A product of
              <span className="dms"> Damaso Digital Solutions Pvt. Ltd.</span>
            </p>
            <p className="mbD">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              &nbsp; F-25/99, Sector-7, Rohini, New Delhi-110085
            </p>
            <p className="mbD">
              <FontAwesomeIcon icon={faEnvelope} />
              &nbsp;support@thestudyfalcon.com
            </p>
            <p className="mbD">
              <FontAwesomeIcon icon={faPhone} />
              &nbsp; +91-11 27040783
            </p>
            <p className="tncD">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/pp")}
              >
                Privacy Policy
              </span>{" "}
              |
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/tnc")}
              >
                {" "}
                Terms & Conditions
              </span>
            </p>
          </div>

          <div className="footerDiv-b">
            <p className="tsf">Important Links</p>
            <p
              onClick={() => navigate("/eb", { replace: true })}
              className="footerItem"
            >
              E-Books
            </p>
            <p
              onClick={() => navigate("/au", { replace: true })}
              className="footerItem"
            >
              {" "}
              About Us{" "}
            </p>
            <p
              onClick={() => navigate("/cu", { replace: true })}
              className="footerItem"
            >
              {" "}
              Contact Us{" "}
            </p>
          </div>
          <div className="footerDiv-a2">
            <p>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/pp")}
              >
                Privacy Policy
              </span>{" "}
              |
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/tnc")}
              >
                {" "}
                Terms & Conditions
              </span>
            </p>
          </div>
          <div className="footerDiv-c" style={{ alignSelf: "center" }}>
            <div className="newsletter">
              <label className="nwsTxt">Subscribe to our Newsletter</label>
              <div className="nwsDiv">
                <input
                  type="email"
                  placeholder="Your Email Address"
                  className="nwsEmail"
                />
                <div className="nwsSend">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </div>
              </div>
            </div>
            <p className="footerAbout">
              The Study Falcon team is committed to support you in your
              preparation journey. We leave no stone unturned to make your
              learning path pleasant and successful.
            </p>
            <div className="icnDiv">
              <a
                href="https://www.facebook.com/TheStudyFalcon"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} className="soIcn" />
              </a>
              <a
                href="https://www.instagram.com/thestudyfalcon/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="soIcn" />
              </a>
              <a
                href="https://www.linkedin.com/company/study-falcon/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} className="soIcn" />
              </a>
              <a
                href="https://twitter.com/thestudyfalcon?s=20"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} className="soIcn" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        Copyright {new Date().getFullYear()} @ Damaso Digital Solutions Pvt.
        Ltd.
      </div>
    </div>
  );
};

export default Footer;
