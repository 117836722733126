import React from "react";
import "../../Style/profile.scss";
import { UserAct, UserCrsProg, UserInfo } from "../../Components/Widgets";

export default function Profile() {
  return (
    <div className="profileMain">
      <UserInfo />
      <div className="usrActDiv">
        <UserAct />
        <UserCrsProg />
      </div>
    </div>
  );
}
