import React from "react";
import { MockAnalysisChart } from "../../../Components/Charts";
import { MockAnalysisList } from "../../../Components/List";
import "../../../Style/mockTest.scss";

export default function MockProg() {
  return (
    <div className="HomeD mockProgMain ">
      <p className="progHead">MockTest Analysis</p>
      <MockAnalysisChart />
      <MockAnalysisList />
    </div>
  );
}
