import React, { useEffect, useState } from "react";
import "../../Style/AI.scss";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { usrCrs } from "../../Functions/courseFunction";
import prBg from "../../Assets/ui/prelim1.gif";
import { User } from "../../Functions/LocalSaveData";
import Typewriter from "typewriter-effect";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import color from "../../Style/colorPalette";
import { useForceUpdate } from "../../Functions/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import cgBg from "../../Assets/ui/1.png";
import { aiUsrAttempt, usrAiSubs } from "../../Functions/aiFunctions";
import dayjs from "dayjs";

export default function Prelims() {
  let { crs } = useParams();
  crs = parseFloat(crs);
  const force = useForceUpdate();
  const navigate = useNavigate();
  const { name, id } = User();
  const [crsData, setCrsData] = useState([]);
  const [prPage, setPrPage] = useState(crs === 0 ? "pe" : "wu");
  const [subs, setSubs] = useState([]);
  const [suLth, setSuLth] = useState(0);
  // user prelim input states
  const [uCrs, setUCrs] = useState(crs === 0 ? 0 : crs);
  const [uDt, setUdt] = useState(null);
  const [uEp, setUep] = useState("");
  const [uPt, setUPt] = useState("");
  const [attempt, setAttempt] = useState("attempt");

  useEffect(() => {
    usrCrs().then((res) =>
      setCrsData(
        res.filter((i) => i.ai_attempt === "" || i.ai_attempt === null)
      )
    );
  }, []);

  const handleCheck = (val, type) => {
    let v = val.target.value;
    var i = subs.findIndex((p) => String(p.id) === v);
    let s = subs;
    if (type === "strong") {
      if (val.target.checked) {
        s[i].type = "strong";
        s[i].checked = 1;
      } else {
        s[i].type = "";
        s[i].checked = 0;
      }
    } else {
      if (val.target.checked) {
        s[i].type = "weak";
        s[i].checked = 1;
      } else {
        s[i].type = "";
        s[i].checked = 0;
      }
    }
    setSubs(s);
    setSuLth(s.filter((i) => i.checked === 1).length);
    force();
  };

  const handleProceed = () => {
    let data = {};
    data.attempt = attempt;
    data.course_id = uCrs;
    data.user_id = id;
    data.exam_pattern = uEp;
    data.exam_prepare = uPt;
    data.subs = subs;
    data.exam_date = uDt;
    aiUsrAttempt(data, navigate);
  };

  return (
    <div className="AiMain">
      {prPage === "pe" ? (
        /* User Select Primary Exam  */
        <div className="uPrExmCon">
          <div className="uPrExm">
            <p className="prTxt">Your primary exam for preparation is</p>
            <FormControl className="ExmSelect" variant="outlined" size="small">
              <Select
                style={{ paddingTop: "10px" }}
                className="ebslct"
                value={uCrs}
                onChange={(val) => setUCrs(val.target.value)}
              >
                <MenuItem value={0}>Select Course</MenuItem>
                {crsData.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.course_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p
              onClick={() =>
                uCrs === 0
                  ? alert("Select your primary exam to continue")
                  : setPrPage("wu")
              }
              className="nxtBtn"
            >
              Next
            </p>
          </div>
          <img className="prBg" alt="The Study Falcon" src={prBg} />
        </div>
      ) : prPage === "wu" ? (
        /* Welcome Screen */
        <div className="wuCon">
          <div className="wu">
            <p className="wuTxt">
              Hi, {String(name).split(" ")[0]}! <br />
              Welcome to The Study Falcon
            </p>
            <Typewriter
              options={{
                strings: [
                  "We are here to help you prepare better. Let us know more about you Answer few Questions.",
                ],
                deleteSpeed: 100,
                autoStart: true,
                loop: true,
                wrapperClassName: "typeTxt",
              }}
            />
            <div className="wuBtnD">
              <p
                onClick={() => navigate("/", { replace: true })}
                replace
                className="nxtBtn"
              >
                Skip
              </p>
              <p onClick={() => setPrPage("ed")} className="nxtBtn">
                Next
              </p>
            </div>
          </div>
          <img className="wuBg" alt="The Study Falcon" src={prBg} />
        </div>
      ) : prPage === "ed" ? (
        /* Exam Date */
        <div className="edCon">
          <div className="ed">
            <p className="edTxt">
              Any idea about the date in which you are going to attempt the exam
              you are preparing for
            </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  className="date"
                  label="Exam Date"
                  value={uDt === null ? uDt : dayjs(uDt)}
                  onChange={(val) => setUdt(val.$d.toISOString().split("T")[0])}
                />
              </DemoContainer>
            </LocalizationProvider>
            <p onClick={() => setPrPage("ep")} className="nxtBtn">
              Next
            </p>
          </div>
          <img className="edBg" alt="The Study Falcon" src={prBg} />
        </div>
      ) : prPage === "ep" ? (
        /* Exam Pattern */
        <div className="edCon">
          <div className="ed">
            <p className="edTxt">
              Do you have full information about the examination pattern and how
              this exam is conducted, cut-off, syllabus etc ?
            </p>
            <div className="epBtnD">
              <p
                onClick={() => setUep("Yes")}
                className={uEp === "Yes" ? "epBtn epBtn-act" : "epBtn"}
              >
                Yes
              </p>
              <p
                onClick={() => setUep("No")}
                className={uEp === "No" ? "epBtn epBtn-act" : "epBtn"}
              >
                No
              </p>
            </div>
            <p onClick={() => setPrPage("pt")} className="nxtBtn">
              Next
            </p>
          </div>
          <img className="edBg" alt="The Study Falcon" src={prBg} />
        </div>
      ) : prPage === "pt" ? (
        /* Preparation type */
        <div className="edCon">
          <div className="ed">
            <p className="edTxt">
              Have you prepared for this examination through any coaching center
              or self preparation ?
            </p>
            <div className="epBtnD">
              <p
                onClick={() => setUPt("Coaching center")}
                className={
                  uPt === "Coaching center" ? "epBtn epBtn-act" : "epBtn"
                }
              >
                Coaching center
              </p>
              <p
                onClick={() => setUPt("Self preparation")}
                className={
                  uPt === "Self preparation" ? "epBtn epBtn-act" : "epBtn"
                }
              >
                Self preparation
              </p>
            </div>
            <p
              onClick={() => {
                usrAiSubs(uCrs).then((val) => {
                  if (val && val.length === 0) {
                    handleProceed();
                  } else {
                    setSubs(val ? val : []);
                    setAttempt("");
                    setPrPage("stwk");
                  }
                });
              }}
              className="nxtBtn"
            >
              Next
            </p>
          </div>
          <img className="edBg" alt="The Study Falcon" src={prBg} />
        </div>
      ) : prPage === "stwk" ? (
        /* Strong and Weak Subjects */
        <div className="edCon">
          <div className="ed">
            <p className="edTxt">
              Which of the following subjects are your strong and weak?
            </p>
            <div className="stwkDiv">
              <div className="stCon">
                <label className="st">Strong Subjects</label>
                <div className="subList">
                  {subs
                    .filter((i) => i.type === "" || i.type === "strong")
                    .map((item, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            className="subCheck"
                            sx={{
                              color: color.green,
                              "&.Mui-checked": {
                                color: color.green,
                              },
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                            }}
                            checked={item.checked === 1}
                            onChange={(val) => handleCheck(val, "strong")}
                            value={parseInt(item.id)}
                          />
                        }
                        label={item.subject_name}
                      />
                    ))}
                </div>
              </div>
              <div className="wkCon">
                <label className="wk">Weak Subjects</label>
                <div className="subList">
                  {subs
                    .filter((i) => i.type === "" || i.type === "weak")
                    .map((item, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            className="subCheck"
                            sx={{
                              color: color.pink,
                              "&.Mui-checked": {
                                color: color.pink,
                              },
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                            }}
                            checked={item.checked === 1}
                            onChange={(val) => handleCheck(val, "weak")}
                            value={parseInt(item.id)}
                          />
                        }
                        label={item.subject_name}
                      />
                    ))}
                </div>
              </div>
            </div>
            <p
              onClick={() => {
                subs.length !== suLth
                  ? alert(
                      "Kindly select all the subjects from the following category"
                    )
                  : setPrPage("cg", handleProceed());
              }}
              className="nxtBtn"
            >
              Next
            </p>
          </div>
        </div>
      ) : (
        /* Congratulation */
        <div className="cgCon">
          <p className="cgCong">Congratulations!</p>
          <div className="cgBody">
            <p className="body">
              You have cleared your first step of assessing your strengths and
              weaknesses. You seem to be a determined aspirant who is clear
              about your weak and strong subjects.
              <br />
              <br />
              Now the next step is to solve certain questions to assess your
              strong and weak areas because you never know what you think to be
              weak might be your strength for the intended exams. To err is
              human and self underestimation is err to a student.
              <br />
              <br />
              Let's now track your real strength and weakness in view of your
              intended exam!
            </p>
            <div className="cgTxtD">
              <p className="cgTxt">
                HOLD YOUR SEAT TIGHT YOUR JOURNEY TO QUALIFYING YOUR EXAM STARTS
              </p>
            </div>
            <div
              className="cgBtnD"
              onClick={() => navigate("/ai/q/" + uCrs, { replace: true })}
            >
              <div className="cgIcnD">
                <FontAwesomeIcon icon={faForward} className="cgIcn" />
              </div>
              <p className="cgBtnTxt">Proceed to questions</p>
            </div>
          </div>
          <img className="cgBg" alt="The Study Falcon" src={cgBg} />
        </div>
      )}
    </div>
  );
}
