import React, { useEffect, useState } from "react";
import "../../Style/topics.scss";
import { useParams } from "react-router-dom";
import { subName, subTopics } from "../../Functions/LocalSaveData";
import { ReadTopicList } from "../../Components/List";
import { PDFBook } from "../../Components/PDFBook";
import { upReadProg } from "../../Functions/aiFunctions";
import { getPdfProg } from "../../Functions/utils";
import Server from "../../JsonServer/Server";

export default function TopicRead() {
  let { subId, tpId } = useParams();
  tpId = parseInt(tpId);
  let topics = subTopics(subId, tpId);
  const [tpcId, setTpcId] = useState(tpId === 0 ? topics[0].id : tpId);
  const [pdf, setPdf] = useState("");

  const [page, setPage] = useState(0);
  const [totPages, setTotPages] = useState(0);

  useEffect(() => {
    let tp = [];
    tp = topics.filter((i) => String(i.id) === String(tpcId))[0];
    setPdf(`${Server.TPCPDF}${tp ? tp.pdf : ""}`);
  }, [topics, tpcId]);

  useEffect(() => {
    if (totPages > 0 && page !== 0) {
      upReadProg(tpcId, getPdfProg(page, totPages));
    }
  }, [page, totPages, tpcId]);

  return (
    <div className="HomeD topicMain">
      <p className="subName">{subName(subId)}</p>
      <div className="tpReadDiv">
        <ReadTopicList
          subId={subId}
          setTpId={(val) => setTpcId(val)}
          tpId={tpcId}
        />
        <PDFBook
          pdf={pdf}
          page={page}
          setPage={(val) => setPage(val)}
          totPages={totPages}
          setTotPages={(val) => setTotPages(val)}
          tpcId={tpcId}
          topics={topics}
        />
      </div>
    </div>
  );
}
