import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";
import { secToTime } from "./utils";

export const mockExam = async (level, resultId) => {
  const { last_selected_course, token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.MOCKTEST,
        { usrId: id, crsId: last_selected_course, level, resultId },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const mockSubmit = async (
  naviagte,
  lvl,
  mock_id,
  quesList,
  remSec,
  timeDuration,
  resId
) => {
  quesList = JSON.stringify(quesList);
  let timeCons = secToTime(timeDuration - remSec);

  const { last_selected_course, token, id } = User();
  if (token) {
    return await axios
      .post(
        Server.MOCKSUBMIT,
        {
          usrId: id,
          crsId: last_selected_course,
          lvl,
          mock_id,
          quesList,
          timeCons,
          resId,
        },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then(
        async (r) =>
          // await mockReport(r.data?.resultId).then(() =>
          naviagte("/mt/mrp/" + r.data?.resultId, { replace: true })
        // )
      )
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const mockReport = async (resultId) => {
  const { token } = User();
  if (token) {
    return await axios
      .post(
        Server.MOCKRPRT,
        { resultId },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        localStorage.setItem("MockReport", JSON.stringify(res.data));
        return res.data;
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};
