import React, { useState } from "react";
import "../../Style/land.scss";
import euImg from "../../Assets/ui/exams-update.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ExamList } from "../../Components/List";
import { exCats } from "../../JsonServer/localServer";

export default function ExamUpdates() {
  const [search, setSearch] = useState("");
  const [exCat, setExCat] = useState(exCats[0]);

  return (
    <div className="homeMain">
      <div className="exmUpdtsMain">
        <div className="xmUpDiv">
          <h1 className="xmUpTxt">EXAM / JOB UPDATES</h1>
          <div className="xmUp">
            <img className="xmUpImg" alt="Explore Courses" src={euImg} />
          </div>
        </div>
        <div className="xmListCom">
          <label className="xmlatest">Latest Exams</label>
          <div className="Search">
            <input
              type="text"
              placeholder="Search Exams"
              className="searchInput modSrch"
              value={search}
              onChange={(val) => setSearch(val.target.value)}
            />
            {String(search).length === 0 ? null : (
              <FontAwesomeIcon
                icon={faClose}
                className="searchIcn"
                onClick={() => setSearch("")}
              />
            )}
          </div>
        </div>
        <div className="xmCatLstD">
          {exCats.map((i) => (
            <h4
              onClick={() => setExCat(i)}
              className={"xmCat" + (exCat === i ? " xmCatAct" : "")}
            >
              {i}
            </h4>
          ))}
        </div>
        <ExamList search={String(search).toLowerCase()} cat={exCat} />
      </div>
    </div>
  );
}
