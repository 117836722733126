import "../Style/navbar.scss";
import logo from "../Assets/logos.png";
import { useNavigate } from "react-router-dom";
import { LoginModal, SubsPlanModal, XmModal } from "./Modal";
import { useEffect, useRef, useState } from "react";
import { LogType, User, notify } from "../Functions/LocalSaveData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faInfo } from "@fortawesome/free-solid-svg-icons";
import { usrCrs, usrCrsUpdate } from "../Functions/courseFunction";
import Select from "react-select";
import color from "../Style/colorPalette";
import Server from "../JsonServer/Server";
import { loginInfo, useForceUpdate } from "../Functions/utils";
import { notRead } from "../Functions/authFunctions";

export const Navbar = ({ currentPage, sideFunc }) => {
  const lt = LogType();
  const { payment_status, last_selected_course, id } = User();
  const [visible, setVisible] = useState(false);
  const [subVisible, setSubVisible] = useState(false);
  const [reg, SetReg] = useState(null);
  const [crs, setCrs] = useState([]);
  const [uc, setUc] = useState([]);
  const [height, setHeight] = useState(0);
  const [notV, setNotV] = useState(false);
  const [euV, setEuV] = useState(false);
  const [euItm, setEuItm] = useState(null);
  const [notData, setNotData] = useState([]);
  const ref = useRef(null);
  const navigate = useNavigate();
  const force = useForceUpdate();

  useEffect(() => {
    setNotData(notify());
    setHeight(ref.current.clientHeight);
    if (id !== undefined) {
      usrCrs().then((val) => {
        if (val.length === 0) {
          setCrs([]);
        } else {
          setCrs(val);
          let exInfo = val.filter(
            (i) => String(i.id) === String(last_selected_course)
          );
          setUc(exInfo.length > 0 ? exInfo[0] : []);
        }
      });
    }
  }, [id, last_selected_course]);

  const handleCrsChange = (val) => {
    let usr = User();
    usr.last_selected_course = val.id;
    usr.ai_attempt = val.ai_attempt;
    localStorage.setItem("user", JSON.stringify(usr));
    setUc(val);
    usrCrsUpdate(val.id);
  };

  const hidCrs =
    lt !== "li" ||
    String(currentPage).includes("ai") ||
    String(currentPage).includes("tnc") ||
    String(currentPage).includes("me") ||
    String(currentPage).includes("mrp") ||
    String(currentPage).includes("emt") ||
    String(currentPage).includes("dpa") ||
    String(currentPage).includes("sa") ||
    String(currentPage).includes("ta") ||
    String(currentPage).includes("ea") ||
    String(currentPage).includes("tpc") ||
    String(currentPage).includes("crs") ||
    String(currentPage).includes("eu") ||
    String(currentPage).includes("eb") ||
    String(currentPage).includes("cu") ||
    String(currentPage).includes("au") ||
    String(currentPage).includes("mt") ||
    String(currentPage).includes("ca") ||
    String(currentPage).includes("mvp") ||
    String(currentPage).includes("pd");

  const hideNav =
    String(currentPage).includes("/mt/emt/") ||
    String(currentPage).includes("/mt/me");

  const showExInfo = uc.length === 0 || uc.exam_info === null;

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? color.white : color.black,
      backgroundColor: state.isSelected ? color.pink : "#fff",
      fontSize: "14px",
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      boxShadow: "none",
      fontSize: "14px",
    }),
  };

  const handleOpen = (item) => {
    let { exam_update_id, news_id, video_id, magazine_id } = item;
    console.log(item);
    if (exam_update_id) {
      setEuItm(item);
      setEuV(true);
    } else if (news_id) {
      navigate(`ca/na/0`);
    } else if (video_id) {
      navigate(`/mvp`);
    } else if (magazine_id) {
      navigate(`ca/ma`);
    }
    setNotV(false);
  };

  return (
    <>
      <div className="notPanel" style={{ top: !notV ? "-100vh" : height }}>
        {notData.length > 0 ? (
          notData.map((item, index) => (
            <div
              key={index}
              className={item.status === 0 ? "notUr" : "notItm"}
              onClick={() => {
                notRead(item.notify_id);
                item.status = 1;
                force();
                handleOpen(item);
              }}
            >
              <span
                className="not"
                style={{ display: item.status === 0 ? "" : "none" }}
              />
              <p className="notMsg">{item.message}</p>
              <p className="notDt"> {loginInfo(item.notify_date)}</p>
            </div>
          ))
        ) : (
          <div className="notItm">
            <p className="notMsg" style={{ textAlign: "center" }}>
              NO NOTIFICATIONS
            </p>
          </div>
        )}
      </div>
      {euV && (
        <XmModal
          item={euItm}
          visible={euV}
          setVisible={() => {
            setEuV(false);
            setEuItm(null);
          }}
        />
      )}
      <LoginModal
        visible={visible}
        setVisible={() => setVisible(!visible)}
        isReg={reg}
      />
      <SubsPlanModal
        visible={subVisible}
        setVisible={() => setSubVisible(false)}
      />
      <div
        ref={ref}
        className="navbar"
        style={{
          justifyContent: lt === "pl" ? "center" : "unset",
          display: hideNav ? "none" : "",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* {Server.dev_mode && <div className="devHead">Dev Mode</div>} */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {lt !== "li" ? null : (
              <FontAwesomeIcon
                className="sIcn mRes"
                icon={faBars}
                onClick={() => sideFunc()}
              />
            )}
            <div className={"imgDiv" + (hidCrs ? " imgRes" : "")}>
              <img
                alt="The Study Falcon"
                src={logo}
                className="brLogo"
                onClick={() => navigate("/", { replace: true })}
              />
              {payment_status === 1 || !lt ? null : (
                <span onClick={() => setSubVisible(true)} className="subTxt">
                  Subscribe
                </span>
              )}
              {lt !== "li" ? null : (
                <FontAwesomeIcon
                  className="sIcn dRes"
                  icon={faBars}
                  onClick={() => sideFunc()}
                />
              )}
            </div>

            <div
              className={"navCon" + (hidCrs ? " crsRes" : "")}
              style={{ display: lt === "pl" ? "none" : "" }}
            >
              <div className="crsDiv" style={{ display: hidCrs ? "none" : "" }}>
                <a
                  href={Server.EXAMINFO + uc.exam_info}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: showExInfo ? "none" : "" }}
                >
                  <FontAwesomeIcon
                    className="crsIcn"
                    style={{ backgroundColor: color.pink, padding: 5 }}
                    icon={faInfo}
                  />
                </a>
                <Select
                  classNames={{
                    control: () => "navCrsCon",
                    option: () => "navCrsOpt",
                  }}
                  styles={customStyles}
                  getOptionLabel={(val) => val.course_name}
                  getOptionValue={(val) => val.id}
                  options={crs}
                  value={uc}
                  onChange={(val) => handleCrsChange(val)}
                  placeholder="Select Course"
                />
              </div>
              <p
                onClick={() => navigate("/", { replace: true })}
                className={
                  (currentPage === "/" ? "navItem-active" : "navItem") +
                  " resNv"
                }
              >
                Home
              </p>
              <p
                onClick={() => navigate("/au", { replace: true })}
                className={
                  (String(currentPage).includes("au")
                    ? "navItem-active"
                    : "navItem") + " resNv"
                }
              >
                About Us
              </p>
              <p
                onClick={() => navigate("/crs", { replace: true })}
                className={
                  (String(currentPage).includes("crs")
                    ? "navItem-active"
                    : "navItem") + " resNv"
                }
              >
                Courses
              </p>
              <p
                onClick={() => navigate("/eu", { replace: true })}
                className={
                  (String(currentPage).includes("eu")
                    ? "navItem-active"
                    : "navItem") + " resNv"
                }
              >
                Exam Updates
              </p>
              <p
                onClick={() => navigate("/eb", { replace: true })}
                className={
                  (String(currentPage).includes("eb")
                    ? "navItem-active"
                    : "navItem") + " resNv"
                }
              >
                E-Books
              </p>
              <p
                onClick={() => navigate("/mvp", { replace: true })}
                className={
                  (String(currentPage).includes("mvp")
                    ? "navItem-active"
                    : "navItem") + " resNv"
                }
              >
                Video Lectures
              </p>

              <p
                onClick={() => navigate("/cu", { replace: true })}
                className={
                  (String(currentPage).includes("cu")
                    ? "navItem-active"
                    : "navItem") + " resNv"
                }
              >
                Contact Us
              </p>
              <div className="mobileMaiGayab" style={{ display: lt ? "none" : "" }}>
                <input
                  className={"login"}
                  type="button"
                  value={"Login"}
                  onClick={() => SetReg(false, setVisible(true))}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  onClick={() => SetReg(true, setVisible(true))}
                  className={"register"}
                >
                  Register
                </button>
              </div>
              {lt !== "li" ? null : (
                <div className="notIcn notDRes">
                  <FontAwesomeIcon
                    onClick={() => setNotV(!notV)}
                    icon={faBell}
                    className="navItem"
                    style={{ fontSize: 24 }}
                  />
                  <span
                    className="notBadge"
                    style={{
                      display:
                        notData.filter((i) => i.status === 0).length > 0
                          ? ""
                          : "none",
                    }}
                  >
                    {notData.filter((i) => i.status === 0).length}
                  </span>
                </div>
              )}
            </div>
            {lt !== "li" ? null : (
              <div className="notIcn notMRes">
                <FontAwesomeIcon
                  onClick={() => setNotV(!notV)}
                  icon={faBell}
                  className="navItem"
                  style={{ fontSize: 24 }}
                />
                <span
                  className="notBadge"
                  style={{
                    display:
                      notData.filter((i) => i.status === 0).length > 0
                        ? ""
                        : "none",
                  }}
                >
                  {notData.filter((i) => i.status === 0).length}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
