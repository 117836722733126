import React, { useEffect, useRef, useState } from "react";
import { LoginModal, ShareModal } from "../../Components/Modal";
import { Backdrop, Modal } from "@mui/material";
import Server from "../../JsonServer/Server";
import podImg from "../../Assets/ui/pod.png";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import {
  dateFormat,
  useForceUpdate,
  useIsVisible,
} from "../../Functions/utils";
import { getPodcast } from "../../Functions/CAFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../Functions/LocalSaveData";

export default function PodPlayerM() {
  const { pId } = useParams();
  const [shV, setShV] = useState(false);
  const [data, setData] = useState([]);
  const [v, setV] = useState(true);
  const r = useRef(true);
  const navigate = useNavigate();
  const isUSr = User().id;
  const [logV, setLogV] = useState(false);
  const force = useForceUpdate();
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (r.current) {
      r.current = false;
      getPodcast(pId).then((i) => setData(i));
    }
    const int = setInterval(() => {
      if (isVisible) {
        force();
      }
    }, 1000);
    return () => clearInterval(int);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, pId]);

  const { title, podcast_date, audio_file, id } = data;
  return (
    <Modal
      open={v}
      onClose={() => {
        setV(false);
        navigate(`/`, { replace: true });
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1000,
        },
      }}
    >
      <div className="mainModal">
        <LoginModal visible={logV} setVisible={() => setLogV(false)} />
        <ShareModal
          v={shV}
          setV={() => setShV(false)}
          title={"Share this Podcast via"}
          shareUrl={Server.WEB_URL + "/" + id}
        />
        <div className="audPlayerDiv">
          <div className="metDiv">
            <img alt={title} className="metImg" src={podImg} />
            <div className="metInfoDiv">
              <p className="metTitle">{title}</p>
              <p className="metDate">{dateFormat(podcast_date)}</p>
              <FontAwesomeIcon
                onClick={() => setShV(true)}
                icon={faShareAlt}
                className="pdSh"
              />
            </div>
          </div>
          {isUSr ? (
            <AudioPlayer
              showSkipControls={false}
              autoPlay
              src={`${Server.POD_SRC}${audio_file}`}
              onPlay={(e) => console.log(e)}
              // other props here
            />
          ) : (
            <div className="logReqDiv">
              <p className="logTxt">
                Login / Register now to listen this podcast
              </p>
              <p onClick={() => setLogV(true)} className="logBtn">
                Login / Register
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
