import React from "react";
import "../../Style/topics.scss";
import { CaBmLst } from "../../Components/List";

export default function CaBM() {
  return (
    <div className="HomeD topicMain">
      <p className="subName">Bookmarked Current Affairs </p>
      <CaBmLst />
    </div>
  );
}
