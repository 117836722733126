import React from "react";
import "../../Style/ebook.scss";
import { useState } from "react";

import { useEffect } from "react";
import { getAllEbooks } from "../../Functions/landingFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { EbGridList, EbSdList } from "../../Components/List";
import { DlMdl } from "../../Components/Modal";
import { MobNaHd } from "../../Components/Widgets";

export default function EbookSub() {
  const { sId } = useParams();
  const [subId, setSubId] = useState(sId);
  const [subNm, setSubNm] = useState("");
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const [dl, setDDl] = useState(true);

  useEffect(() => {
    const load = async () => {
      let val = await getAllEbooks();
      setData(val);
      const { subjects } = val;
      if (subjects) {
        setSubNm(
          subjects.filter((i) => String(i.id) === String(sId))[0].name,
          setDDl(false)
        );
      }
    };
    load();
  }, [sId]);

  let { subjects, ebooks } = data;
  let Gnrebooks = ebooks
    ? ebooks
        .filter((i) =>
          String(i.subject).toLowerCase().includes(subNm.toLowerCase())
        )
        .slice(0, 1000)
    : [];

  return (
    <div className="HomeD ebGnrMain">
      <DlMdl v={dl} />
      <div className="ebGnrDiv">
        <EbSdList
          navigate={(val) => navigate(`/eb/sb/${val}`, { replace: true })}
          title={"Subjects"}
          data={subjects}
          gnrId={subId}
          setGnrId={(val) => setSubId(val)}
          gnrNm={subNm}
          setGnrNm={(val) => setSubNm(val)}
        />

        <MobNaHd
          title={"Subjects"}
          catId={subId}
          setGnrId={(val) => setSubId(val)}
          navigate={(val) => navigate(`/eb/sb/${val}`, { replace: true })}
          data={subjects}
          catName={subNm}
        />
        <EbGridList data={Gnrebooks} />
      </div>
    </div>
  );
}
