import React from "react";
import { useState } from "react";
import moTrmsImg from "../../Assets/ui/Instruction manual-bro.png";
import moTrmsImg2 from "../../Assets/ui/Telecommuting-rafiki.png";
import { Checkbox, FormControlLabel } from "@mui/material";
import color from "../../Style/colorPalette";
import { MockStartModal } from "../../Components/Modal";
import { crsMock } from "../../Functions/LocalSaveData";
import { useParams } from "react-router-dom";

export default function MockTerms() {
  const { lvl } = useParams();
  const [tnPage, setTnPage] = useState("1");
  const [checked, setChecked] = useState(false);
  const [visible, setVisible] = useState(false);
  const { time_duration, positive_mark, negative_mark, total_question } =
    crsMock();

  const handleClick = () => {
    if (!checked) {
      if (
        window.confirm(
          "Are you agree with th terms and conditions and follow all the rules and regulations"
        )
      ) {
        setChecked(true, setVisible(true));
      }
    } else {
      setChecked(true, setVisible(true));
    }
  };

  return (
    <div className="HomeD">
      <MockStartModal
        setVisible={() => setVisible(!visible)}
        visible={visible}
        lvl={lvl}
      />
      {tnPage === "1" ? (
        <div className="MoTrmsD">
          <h1 className="moHdTxt"> Terms & Conditions</h1>
          <img
            className="moTrmsImg"
            alt="Terms and Conditions"
            src={moTrmsImg}
          />
          <p className="moTrmsTxt">
            Please read the following instructions carefully
          </p>
          <div className="trmsTxt">
            <ol>
              <li>
                Make sure your device (mobile/tab/laptop/desktop) have a good
                and stable internet connection throughout the test.
              </li>
              <br />
              <li>
                The examinee can move to First, Last, Previous, Next and
                unanswered questions by clicking on the buttons with respective
                labels displayed on screen throughout the test.
              </li>
              <br />
              <li>
                The Time remaining is shown in the Right Top Corner of the
                screen. The time of the Test begins only when the ‘Start Test’
                button is pressed.
              </li>
              <br />
              <li>
                The system automatically shuts down when the time limit is over
                OR if the examinee finishes the exam before the time he can quit
                by pressing the ‘End Session’ button.
              </li>
              <br />
              <li>
                The question palette at the right of the screen shows one of the
                following statuses of each of the questions numbered:
              </li>
              <br />
              <span className="Mopt nv">1</span>&nbsp; You have not visited the
              question yet.
              <br />
              <br />
              <span className="Mopt nAns">2</span>&nbsp; You have not answered
              the question.
              <br />
              <br />
              <span className="Mopt ans">3</span>&nbsp; You have answered the
              question.
              <br />
              <br />
              <span className="Mopt nar">4</span>&nbsp; You have NOT answered
              the question but have marked the question for review.
              <br />
              <br />
              <span className="Mopt ar">5</span>&nbsp; You have answered the
              question but marked it for review.
              <br />
              <br />
              <br />
            </ol>
            The Marked for Review status will act as a reminder for you that you
            have set to look at the question again.
            <span className="trmRed">
              <i>
                If an answer is selected for a question that is marked for
                Review, the answer will be considered in the final evaluation.
              </i>
            </span>
          </div>
          <p onClick={() => setTnPage("2")} className="MoNxtBtn">
            Next
          </p>
        </div>
      ) : (
        <div className="MoTrmsD">
          <h1 className="moHdTxt"> Terms & Conditions</h1>
          <img
            className="moTrmsImg"
            alt="Terms and Conditions"
            src={moTrmsImg2}
          />
          <p className="moTrmsTxt">*Navigating to a question:</p>
          <div className="trmsTxt">
            <ol>
              <li>
                To select a question to answer, you can do one of the following:
              </li>
              <br />
              <ol type="a">
                <li>
                  Click on the question number on the question palette at the
                  right of your screen to go that numbered question directly.
                  Note that using this option does NOT save your answer to the
                  current question.
                </li>
                <br />
                <li>
                  After selecting the answer, click on Save and Next to save
                  answer to current question and to go to the next question in
                  sequence.
                </li>
                <br />
                <li>
                  Click on Mark for Review and Next to save answer to current
                  question, mark it for review, and to go to the next question
                  in sequence.
                </li>
                <br />
              </ol>
            </ol>
          </div>
          <p className="moTrmsTxt">*Answering questions:</p>
          <div className="trmsTxt">
            <ol>
              <li> For multiple choice type question:</li>
              <br />
              <ol type="a">
                <li>
                  To select your answer, click on one of the option buttons.
                </li>
                <br />
                <li>
                  To change your answer, click the another desired option
                  button.
                </li>
                <br />
                <li>
                  To save your answer, you MUST click on <b>Save & Next.</b>
                </li>
                <br />
                <li>
                  To deselect a chosen answer, click on the chosen option again
                  or click on the <b>Clear Response </b> button.
                </li>
                <br />
                <li>
                  To mark a question for review click on{" "}
                  <b>Mark for Review & Next.</b>&nbsp;
                  <span className="trmRed">
                    <i>
                      If an answer is selected for a question that is Marked for
                      Review, the answer will be considered in the final
                      evaluation.
                    </i>
                  </span>
                </li>
                <br />
              </ol>
              <li>
                To change an answer to a question, first select the question and
                then click on the new answer option followed by a click on the
                Save & Next button.
              </li>
              <br />
              <li>
                Questions that are saved or marked for review after answering
                will ONLY be considered for evaluation.
              </li>
            </ol>
          </div>
          <br />
          <p className="moTrmsTxt">*Navigating through sections:</p>
          <div className="trmsTxt">
            <ol>
              <li>
                Sections in this question paper are displayed on the top bar of
                the screen. Questions in a section can be viewed by clicking on
                the section name. The section you are currently viewing is
                highlighted.
              </li>
              <br />
              <li>
                After clicking the <b>Save & Next</b> button on the last
                question for a section, you will automatically be taken to the
                first question of the next section.
              </li>
              <br />
              <li>
                You can move the mouse cursor over the section names to view the
                status of the questions for that section.
              </li>
              <br />
              <li>
                You can shuffle between sections and questions anytime during
                the examination as per your convenience.
              </li>
            </ol>
          </div>
          <p className="moTrmsTxt">*Questions and Score</p>
          <div className="trmsTxt">
            <ol>
              <li>
                <span className="trmRed">
                  Total duration of Mock Test is{" "}
                  {parseFloat(String(time_duration).split(":")[0]) * 60 +
                    parseFloat(String(time_duration).split(":")[1])}{" "}
                  minutes and there would be {total_question} Multiple Choice
                  Questions.
                </span>
              </li>
              <br />
              <li>
                <span className="trmRed">
                  For each question answered right, {positive_mark} mark will be
                  awarded. For each wrong question,{" "}
                  {negative_mark === "" ? 0 : negative_mark} marks will be
                  deducted. There shall be no deduction or award for
                  un-attempted questions.
                </span>
              </li>
              <br />
              <li>
                <span className="trmRed">
                  The clock will be set at the server. The countdown timer in
                  the top right corner of screen will display the remaining time
                  available for you to complete the Test. When the timer reaches
                  zero, the Test will end by itself. Please click on the submit
                  button before the time out.
                </span>
              </li>
            </ol>
          </div>
          <FormControlLabel
            style={{ alignSelf: "center", textAlign: "center" }}
            label={
              "I agree with terms and conditions. I will follow all rules & regulations."
            }
            control={
              <Checkbox
                className="crsCheck"
                sx={{
                  color: color.pink,
                  "&.Mui-checked": {
                    color: color.pink,
                  },
                  "& .MuiSvgIcon-root": { fontSize: 28 },
                }}
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            }
          />
          <div className="MoBtnDiv">
            <p onClick={() => setTnPage("1")} className="MoNxtBtn">
              Previous
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <p onClick={() => handleClick()} className="MoNxtBtn">
              Next
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
