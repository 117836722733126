import React from "react";
import "../../Style/currentAffair.scss";
import { useState } from "react";
import { useEffect } from "react";
import { allDna } from "../../Functions/CAFunctions";
import { CaList } from "../../Components/List";
import Server from "../../JsonServer/Server";
import { DlMdl } from "../../Components/Modal";

export default function Dna() {
  const [data, setData] = useState([]);
  const [dl, setDl] = useState(true);

  useEffect(() => {
    const load = async () => setData(await allDna(), setDl(false));
    load();
  }, []);

  return (
    <div className="HomeD caMain">
      <DlMdl v={dl} />
      <p className="caName">Daily News Analysis</p>
      <CaList data={data} prefUrl={Server.DNA_SRC} caType={"dna"} />
    </div>
  );
}
