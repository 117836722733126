import axios from "axios";
import Server from "../JsonServer/Server";
import { User } from "./LocalSaveData";

export const usrDash = async () => {
  const { token, id, last_selected_course } = User();
  if (token) {
    return await axios
      .post(
        Server.USRDASHBOARD,
        { usrId: id, crsId: last_selected_course },
        {
          headers: { authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        const { todoList } = res.data;
        localStorage.setItem("dashData", JSON.stringify(res.data));
        localStorage.setItem("todoList", JSON.stringify(todoList));
        return res.data;
      })
      .catch((err) => console.log(err));
  } else {
    return [];
  }
};

export const usrSprtMail = async (usrData) =>
  await axios
    .post(Server.SUPPORT_MAIL, usrData)
    .then((res) => {
      alert("Your Query has been posted");
      return res.data;
    })
    .catch((err) => console.log(err));
