import React from "react";
import { useState } from "react";
import "../../../Style/mockTest.scss";
import { crsMock, mockRep } from "../../../Functions/LocalSaveData";
import {
  DpacrWrSkPie,
  DpacrWrSkPie2,
  DpacrWrSkPie3,
  OaProgChart,
} from "../../../Components/Charts";
import { IOSSwitch } from "../../../Components/Switch";
import { OveralMockList } from "../../../Components/List";
import { useNavigate } from "react-router-dom";

export default function MockDpa() {
  const {
    total_question,
    positive_mark,
    time_duration,
    negative_mark,
    test_name,
  } = crsMock();
  const { mockResult, percentile } = mockRep();
  const { score, correct, wrong, unattempted, time_taken } =
    mockResult === undefined ? [] : mockResult;
  const [curMod, setCurMod] = useState(0);
  const [oaSwitch, setOaSwitch] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="HomeD moDpaMain">
      <p className="RpTxt">
        Mocktest Detailed Performance Analysis <br />({test_name})
      </p>
      <div className="dpaModeSwitchD">
        <p
          onClick={() => setCurMod(0)}
          className={"dpaModBtn " + (curMod === 0 ? "dmBtnAct" : "")}
        >
          Current Analysis
        </p>
        <p
          onClick={() => setCurMod(1)}
          className={"dpaModBtn " + (curMod === 1 ? "dmBtnAct" : "")}
        >
          Overall Analysis
        </p>
      </div>
      {curMod === 0 ? (
        <>
          <div className="dpaScChDiv">
            <div className="dpaScDiv">
              <div className="dpaSc">
                <p className="scTxt">Correct</p>
                <p className="scTxt">{correct}</p>
              </div>
              <div className="dpaSc">
                <p className="scTxt">Wrong</p>
                <p className="scTxt">{wrong}</p>
              </div>
              <div className="dpaSc">
                <p className="scTxt">Skipped</p>
                <p className="scTxt">{unattempted}</p>
              </div>
              <div className="dpaSc">
                <p className="scTxt">Score</p>
                <p className="scTxt">
                  {score} /{" "}
                  {total_question *
                    (positive_mark === "" || positive_mark === null
                      ? 1
                      : parseFloat(positive_mark))}
                </p>
              </div>
              <div className="dpaSc">
                <p className="scTxt">Percentile</p>
                <p className="scTxt">{percentile}%</p>
              </div>
              <div className="dpaSc">
                <p className="scTxt">Time Taken</p>
                <p className="scTxt">
                  {time_taken} / {time_duration}
                </p>
              </div>
            </div>
            <div className="dpaChDiv">
              <div className="pieDiv">
                <DpacrWrSkPie />
                <p className="chTxt">Total Questions: {total_question}</p>
              </div>
              <div className="pieDiv">
                <DpacrWrSkPie2 />
                <p className="chTxt">
                  Total Score Achieved : {score} /
                  {total_question *
                    (positive_mark === "" || positive_mark === null
                      ? 1
                      : parseFloat(positive_mark))}
                </p>
              </div>
              <div className="pieDiv">
                <DpacrWrSkPie3 />
                <p className="chTxt">
                  Total Time Spent : {time_taken} / {time_duration}
                </p>
              </div>
            </div>
          </div>
          <p className="dpaMsTxt">Marks Summary</p>
          <div className="dpaTbl">
            <div className="tblHd">
              <p className="tblHdTxt">Possitive Mark</p>
              <p className="tblHdTxt">Negetive Mark</p>
            </div>
            <div className="tblItm">
              <p className="tblItmTxt" style={{ borderLeft: 0 }}>
                {positive_mark === null ? 1 : positive_mark}
              </p>
              <p className="tblItmTxt">
                {negative_mark === null ? 1 : negative_mark}
              </p>
            </div>
          </div>
          <p onClick={() => navigate("/mt/sa")} className="saBtn">
            Sectional Analysis
          </p>
        </>
      ) : (
        <>
          <div className="OaHddiv">
            <p className="OaHdTxt">Table </p>
            <IOSSwitch
              checked={oaSwitch}
              onChange={() => setOaSwitch(!oaSwitch)}
            />
            <p className="OaHdTxt"> Graph</p>
          </div>
          {oaSwitch ? <OaProgChart /> : <OveralMockList />}
        </>
      )}
    </div>
  );
}
