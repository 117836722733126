import React, { useEffect, useState } from "react";
import "../../Style/AI.scss";
import { Ques } from "../../Components/Ques";
import { useNavigate, useParams } from "react-router-dom";
import { prelimQSave, prelimQues } from "../../Functions/aiFunctions";
import { User } from "../../Functions/LocalSaveData";
import { DlMdl } from "../../Components/Modal";

export default function PrelimQues() {
  const usr = User();
  const { cId } = useParams();
  const [ques, setQues] = useState([]);
  const [usrAns, setUsrAns] = useState("");
  const naviagte = useNavigate();
  const [dl, setDl] = useState(true);

  useEffect(() => {
    const load = async () =>
      setQues(await prelimQues(cId, naviagte), setDl(false));
    load();
  }, [cId, naviagte]);

  const handleNext = () => {
    if (usrAns === "") {
      if (window.confirm("Are you sure, You Want to skip this question")) {
        prelimQSave(data).then((val) => {
          if (val) {
            if (val.status === 1) {
              prelimQues(cId, naviagte).then((val) => {
                setUsrAns("");
                setQues(val ? val : []);
              });
            }
          }
        });
      }
    } else {
      prelimQSave(data).then((val) => {
        if (val) {
          if (val.status === 1) {
            prelimQues(cId, naviagte).then((val) => {
              setUsrAns("");
              setQues(val ? val : []);
            });
          }
        }
      });
    }
  };

  const { id, subject_id, answer } = ques;

  let data = {
    usrId: usr.id,
    crsId: cId,
    quesId: id,
    subId: subject_id,
    usrAns: usrAns,
    answer: answer,
  };

  return (
    <div className="AiMain">
      <DlMdl v={dl} />
      <Ques
        crsId={cId}
        ques={ques}
        handleNext={() => handleNext()}
        setUsrAns={(val) => setUsrAns(val)}
        usrAns={usrAns}
      />
    </div>
  );
}
