import React, { useEffect } from "react";
import { useState } from "react";
import "../../../Style/mockTest.scss";
import { crsMock, mockRep } from "../../../Functions/LocalSaveData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCrosshairs,
  faMinusCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { MockQuesList } from "../../../Components/List";
import { useNavigate } from "react-router-dom";
import { IOSSwitch } from "../../../Components/Switch";
import { SaProgChart } from "../../../Components/Charts";
import { calAccuracy } from "../../../Functions/utils";

export default function MockSa() {
  const { test_name, mockSections } = crsMock();
  const [secIndex, setSecIndex] = useState(0);
  const [secData, setSecData] = useState([]);
  const [taSwitch, setTaSwitch] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let d = {},
      secQues = [];
    d.correct = 0;
    d.wrong = 0;
    d.unattempted = 0;

    const { mockSections } = crsMock();
    let { mockQues } = mockRep();

    for (let i = 0; i < mockQues.length; i++) {
      mockQues[i].sno = i;
    }

    secQues = mockQues.filter(
      (item) =>
        String(item.section_id) === String(mockSections[secIndex].section_id)
    );
    d.secQues = secQues;
    for (let i = 0; i < secQues.length; i++) {
      const { answer, user_answer } = secQues[i];
      if (user_answer === null || user_answer === "") {
        d.unattempted = d.unattempted + 1;
      } else if (answer === user_answer) {
        d.correct = d.correct + 1;
      } else if (user_answer.length > 0 && user_answer !== answer) {
        d.wrong = d.wrong + 1;
      }
    }
    d.accuracy = calAccuracy(d.correct, d.correct + d.wrong);

    setSecData(d);
  }, [secIndex]);

  const { correct, wrong, unattempted, accuracy, secQues } = secData;

  return (
    <div className="HomeD mockSaMain">
      <p className="RpTxt">
        Sectional Analysis <br />({test_name})
      </p>
      <div className="OaHddiv">
        <p className="OaHdTxt">Table </p>
        <IOSSwitch checked={taSwitch} onChange={() => setTaSwitch(!taSwitch)} />
        <p className="OaHdTxt"> Graph</p>
      </div>
      {taSwitch ? (
        <SaProgChart />
      ) : (
        <div className="saDiv">
          <div className="saSecDiv">
            {mockSections.map(({ section_name }, index) => (
              <p
                onClick={() => setSecIndex(index)}
                className={"saSecTxt " + (secIndex === index ? "secAt" : "")}
                key={index}
              >
                {section_name}
              </p>
            ))}
          </div>
          <div className="saScDiv">
            <div className="rpMrkLst">
              <p className="rpMrkTxt">
                <FontAwesomeIcon className="rpCrIcn" icon={faCheck} />
                &nbsp; Correct
              </p>
              <p>{correct}</p>
            </div>

            <div className="rpMrkLst">
              <p className="rpMrkTxt">
                <FontAwesomeIcon className="rpWrIcn" icon={faXmarkCircle} />
                &nbsp; Wrong
              </p>
              <p>{wrong}</p>
            </div>
            <div className="rpMrkLst">
              <p className="rpMrkTxt">
                <FontAwesomeIcon className="rpUnIcn" icon={faMinusCircle} />
                &nbsp; Skipped
              </p>
              <p>{unattempted}</p>
            </div>
            <div className="rpMrkLst">
              <p className="rpMrkTxt">
                <FontAwesomeIcon className="rpAcIcn" icon={faCrosshairs} />
                &nbsp; Accuracy
              </p>
              <p>{accuracy}%</p>
            </div>
          </div>
          <MockQuesList ques={secQues} />
          <div className="mabtnDiv">
            <p onClick={() => navigate("/mt/ta")} className="maBtn">
              Topic Analysis
            </p>
            <p onClick={() => navigate("/mt/ea")} className="maBtn">
              Expert Analysis
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
