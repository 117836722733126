import React from "react";
import "../../Style/Subs.scss";
import {
  PopCaCsrl,
  PopCatCrsll,
  PopCrsCrsll,
  PopEduCrsll,
  SubsBanner,
} from "../../Components/BoxCarousel";
import { useEffect } from "react";
import { subContent } from "../../Functions/subsFunctions";
import { useState } from "react";
import { SubsPlanModal } from "../../Components/Modal";

export default function SubMain() {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(true);
  const [ftVisible, setFtVisible] = useState(true);
  useEffect(() => {
    subContent().then((val) => {
      setData(val);
    });
  }, []);

  return (
    <div className="subMain">
      <SubsPlanModal
        visible={visible}
        setVisible={() => setVisible(false, setFtVisible(false))}
        ftVisible={ftVisible}
      />
      <SubsBanner />
      <div className="subTtl">Most Liked By Student Community</div>
      <PopCrsCrsll />
      <div className="subTtl">Popular Categories</div>
      <PopCatCrsll data={data} />
      <div className="subTtl">Popular Educators</div>
      <PopEduCrsll data={data} />
      <div className="subTtl">Current Affairs</div>
      <PopCaCsrl data={data} />
      <p onClick={() => setVisible(true)} className="proceedBtn">
        Proceed
      </p>
    </div>
  );
}
